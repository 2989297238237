import React from "react";
import SkipLink from "./SkipLink.js";

/**
 * Renders the skip links menu component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const SkipLinkMenu = () => (
    <div role="menu">
        <SkipLink type="main" />
        <SkipLink type="navigation" />
    </div>
);

export default SkipLinkMenu;
