import React from 'react';
import PropTypes from 'prop-types';
import appState from "state/App";

class CardsFrame extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    /**
     * Renders the page menu and header
     *
     * @return {ReactElement} The page container
     */
    render () {
        return (
            <div className={this.props.className}>
                {this.props.children}
            </div>
        );
    }

}

export default appState.attachState(CardsFrame);
