import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink, withRouter } from "react-router-dom";
import appState from "state/App";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';

import { AnimateTextUnderline } from "components/";

import styles from "./SideMenuItem.module.scss";

class SideMenuItem extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        label: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        conditional: PropTypes.bool,
        params: PropTypes.object,
        backgroundColor: PropTypes.string,
        newCount: PropTypes.number,
    };

    static defaultProps = {
        conditional: true,
        params: {},
        backgroundColor: "#eee",
        newCount: null,
    };

    /**
     * Called when one of the links is clicked
     *
     * @return {void}
     */
    handleLinkClick = () => {
        appState.setMenuOpen(false);
    };

    /**
     * Gets additional styles that need to be applied to the link element
     *
     * @return {object} CSS properties to be set
     */
    getThemeStyles () {
        if (!this.props.appState.colouredNav) {
            return null;
        }

        const { brandColour } = this.props.appState;
        const linkColour = getMostContrastingColour(brandColour, contrastingColourList);

        return {
            color: linkColour,
        };
    }

    /**
     * Get a list of class names to be used in styling the link and underline components
     *
     * @return {object} An object of class names
     */
    getClassLists () {
        const { colouredNav } = this.props.appState;

        const linkClass = classNames({
            [styles.menuLink]: true,
            [styles.branded]: colouredNav,
            [styles.unbranded]: !colouredNav,
        });
        const activeLinkClass = classNames({
            [styles.active]: true,
            [styles.brandedActive]: colouredNav,
            [styles.unbrandedActive]: !colouredNav,
        });
        const underlineComponentClass = {
            underline: styles.underline,
            underlineFill: styles.underlineFill,
        };

        return {
            linkClass,
            activeLinkClass,
            underlineComponentClass,
        };
    }

    /**
     * Renders the menu item
     *
     * @return {element} HTML list element
     */
    render () {
        const { conditional, params, to, newCount } = this.props;
        const { accentColour } = this.props.appState;

        if (!conditional) {
            return null;
        }

        const classList = this.getClassLists();

        return (
            <li className={styles.menuItem}>
                <NavLink
                    to={to}
                    className={classList.linkClass}
                    activeClassName={classList.activeLinkClass}
                    onClick={this.handleLinkClick}
                    style={this.getThemeStyles()}
                    {...params}
                >
                    <AnimateTextUnderline classList={classList.underlineComponentClass}>
                        <div className={styles.underlineText}>{this.props.label}</div>
                    </AnimateTextUnderline>
                </NavLink>
                {newCount ? (
                    <div
                        className={styles.newIcon}
                        style={{
                            backgroundColor: accentColour,
                            color: getMostContrastingColour(accentColour, contrastingColourList),
                        }}
                    >
                        {newCount}
                    </div>
                ) : null}
            </li>
        );
    }

}

export default withRouter(appState.attachState(SideMenuItem));
