import React from "react";
import PropTypes from "prop-types";
import appState from "state/App";
import { getAppName } from "lib/";

import {
    PageHeader,
    PageFrame,
    DocumentsFrame,
} from "components/";

class Pensions extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.pageName = "Pensions";
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Renders the pensions page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { pension } = this.props.appState.documents;

        return (
            <PageFrame>
                <PageHeader title={this.pageName} />
                <DocumentsFrame documents={pension} subHeading="Pension Notice" />
            </PageFrame>
        );
    }

}

export default appState.attachState(Pensions);
