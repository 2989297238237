import PropTypes from 'prop-types';

const announcement = {
    id: PropTypes.number.isRequired,
    payrollid: PropTypes.number.isRequired,
    deptno: PropTypes.number.isRequired,
    dateadded: PropTypes.string.isRequired,
    addedby: PropTypes.shape({
        id: PropTypes.number.isRequired,
        forename: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
    }),
    subject: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    hideonread: PropTypes.bool.isRequired,
    // eslint-disable-next-line camelcase
    notice_read: PropTypes.bool.isRequired,
};

const announcementsPropTypes = PropTypes.arrayOf(PropTypes.shape(announcement));

const announcementsDefaultProps = [];

export { announcementsPropTypes, announcementsDefaultProps };
