import React from "react";
import { AnimationContainer } from "@dataplan/react-components/dist/components";
import poweredByGraphic from "components/assets/icon/powered-by-epayslips.png";

import styles from "./PoweredByGraphic.module.scss";

/**
 * Renders the powered by epayslips graphic
 *
 * @return {ReactElement} The component
 */
const PoweredByGraphic = () => (
    <AnimationContainer
        animationStyle="animationContainer"
        appearTimeout={2000}
        className={styles.container}
        enterTimeout={1000}
        exitTimeout={100}
    >
        <div role="contentinfo">
            <img
                alt="Powered by epayslips"
                className={styles.poweredByGraphic}
                src={poweredByGraphic}
            />
        </div>
    </AnimationContainer>
);

export default PoweredByGraphic;
