import api from "lib/api";

export default class P60s {

    /**
     * @param {StateAccessor} appState The appState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the P60s data
     *
     * @return {void}
     */
    load () {
        api.get("/p60s?generate_access_token=true&include_legacy=true&per_page=1000")
            .then(({ data }) => {
                const p60s = data.data.map((p60Doc) => {
                    const taxYear = Number(p60Doc.tax_year);
                    const endTaxYear = (taxYear + 1).toString().slice(2);

                    return {
                        type: 'eP60',
                        filename: `eP60 end of year ${taxYear}-${endTaxYear} certificate`,
                        datetime_added: p60Doc.date_created, // eslint-disable-line camelcase
                        description: `eP60 end of year ${taxYear}-${endTaxYear} certificate`,
                        ...p60Doc,
                    };
                });

                this.appState.setP60s(p60s);
            });
    }

}
