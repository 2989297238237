import React from "react";
import PropTypes from "prop-types";
import appState from "state/App";
import { AuthenticatorForm, QRcode } from "./setup/index";
import { LinkDropdown, StepHeading } from "@dataplan/react-components/dist/components/ui/MFAsetup";

import styles from "./TOTPSetupForm.module.scss";

class TOTPSetupForm extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        enableQR: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        enableQR: true,
    };

    /**
     * Render step one of the authentication process
     *
     * @return {ReactElement} The step one elements
     */
    renderStepOne () {
        const { accentColour: color } = this.props.appState;

        return (
            <>
                <StepHeading
                    stepNumber="Step 1"
                    stepText="If you don't have an authenticator app on your device"
                />
                <br />
                <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.externalLink}
                    style={{ color }}
                >
                    download google app for android
                </a>
                <a
                    href="https://apps.apple.com/us/app/google-authenticator/id388497605?mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.externalLink}
                    style={{ color }}
                >
                    download google app for ios
                </a>
                <br />
                <LinkDropdown accentColour={color} />
            </>
        );
    }

    /**
     * Renders step 2 of the authentication process
     *
     * @return {ReactElement} The step two elements
     */
    renderStepTwo () {
        const { enableQR } = this.props;

        return (
            <>
                <StepHeading
                    stepNumber="Step 2"
                    stepText="Open your authenticator app and register your device by scanning the QR code below"
                    linebreak
                />
                <QRcode visible={enableQR} />
                <br />
            </>
        );
    }

    /**
     * Renders step 3 of the authentication process
     *
     * @return {ReactElement} The step three elements
     */
    renderStepThree () {
        const { onSubmit } = this.props;

        return (
            <div className={styles.container}>
                <StepHeading
                    stepNumber="Step 3"
                    stepText="Enter the six digit code generated by your authenticator app"
                    linebreak
                />
                <AuthenticatorForm submitAction={onSubmit} />
            </div>
        );
    }

    /**
     * Renders the drawer
     *
     * @return {ReactElement} The drawer
     */
    render = () => (
        <>
            {this.renderStepOne()}
            {this.renderStepTwo()}
            {this.renderStepThree()}
        </>
    );

}

export default appState.attachState(TOTPSetupForm);
