import React from 'react';
import PropTypes from 'prop-types';
import appState from "state/App";
import { withRouter } from "react-router-dom";

import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';
import { AddButton } from "@dataplan/react-components/dist/components/forms";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageHeader } from "components/";
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";

import styles from './MessageListHeader.module.scss';

class MessageListHeader extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { accentColour } = this.props.appState;

        const addButtonTimers = {
            delay: 1300,
            transition: 250,
        };

        return (
            <PageHeader title={this.props.title} inBoxedLayout>
                <div className={styles.addNew}>
                    <AnimationContainer
                        appearTimeout={addButtonTimers.delay + addButtonTimers.transition}
                        animationStyle={"animationWobble"}
                    >
                        <Tooltip text="Add Message" position="bottom">
                            <AddButton
                                colour={accentColour}
                                iconColour={getMostContrastingColour(accentColour, contrastingColourList)}
                                onClick={() => this.props.history.push('/messages/compose')}
                            />
                        </Tooltip>
                    </AnimationContainer>
                </div>
            </PageHeader>
        );
    }

}

export default withRouter(appState.attachState(MessageListHeader));
