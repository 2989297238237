import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getAppName } from "lib/";

import styles from './PageHeader.module.scss';

class PageHeader extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        headingClass: PropTypes.string,
        inBoxedLayout: PropTypes.bool,
        title: PropTypes.string,
        wrapperClass: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        headingClass: "",
        inBoxedLayout: false,
        title: getAppName(),
        wrapperClass: "",
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { title, children, wrapperClass, headingClass, inBoxedLayout } = this.props;
        const wrapperClassList = classNames({
            [styles.headerWrapper]: true,
            [wrapperClass]: true,
            [styles.boxedHeader]: inBoxedLayout,
        });

        return (
            <div className={wrapperClassList}>
                <h1 aria-label={title} className={classNames([styles.title, headingClass])}>{title}</h1>
                {children}
            </div>
        );
    }

}

export default PageHeader;
