import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { AnimationContainer } from "@dataplan/react-components/dist/components";
import { payslipPropTypes } from 'lib/prop-schemas/payslipsSchema';
import { PayslipAnimation, PayslipPrimaryCard, PayslipSecondaryCard } from './';

const disabledTransition = {
    left: "payslipFirst",
    right: "payslipLast",
};

/**
 * Renders the payslip components and animation
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const Payslip = ({ canNavigate, navigatePayslips, payslip, payslipLoaded, previousPayslip }) => {
    const { toggleIE } = useSelector(({ appState }) => appState);
    const [detailsOpen, setDetailsOpen] = useState(null);
    const [transition, setTransition] = useState({ animationStart: false, direction: null });
    const { animationStart, direction } = transition;
    const animationStyle = (canNavigate[direction]) ? "payslipDefault" : disabledTransition[direction];

    /**
     * Triggers the payslip carousel animation when changing payslip
     *
     * @param {object} newDirection the direction of changing payslip
     */
    const triggerAnimation = (newDirection) => {
        setTransition({
            animationStart: false,
            direction: newDirection,
        });
    };

    const navigate = (toggleIE) ? navigatePayslips : triggerAnimation;

    useEffect(() => {
        if (!animationStart && direction) {
            setTransition({
                animationStart: true,
                direction,
            });
        }
    }, [animationStart, direction]);

    return (
        <PayslipAnimation
            transition={transition}
            onExited={navigatePayslips}
            animationStyle={animationStyle}
        >
            <PayslipPrimaryCard
                canNavigate={canNavigate}
                detailsOpen={detailsOpen}
                payslip={payslip}
                previousPayslip={previousPayslip}
                payslipLoaded={payslipLoaded}
                setOpenDetails={setDetailsOpen}
                navigatePayslips={navigate}
            />
            {payslipLoaded && (
                <AnimationContainer animationStyle="animationContainer">
                    <PayslipSecondaryCard
                        detailsOpen={detailsOpen}
                        payslip={payslip}
                        setOpenDetails={setDetailsOpen}
                    />
                </AnimationContainer>
            )}
        </PayslipAnimation>
    );
};

Payslip.propTypes = {
    canNavigate: PropTypes.object,
    navigatePayslips: PropTypes.func,
    payslip: PropTypes.shape(payslipPropTypes).isRequired,
    payslipLoaded: PropTypes.bool,
    previousPayslip: PropTypes.shape(payslipPropTypes),
};

Payslip.defaultProps = {
    canNavigate: { left: false, right: false },
    navigatePayslips: null,
    payslipLoaded: false,
    previousPayslip: {},
};

export default Payslip;
