import React from 'react';
import PropTypes from 'prop-types';
import api from 'lib/api';
import Pdf from '@mikecousins/react-pdf';
import { createErrorToasts, contrastingColourList } from "lib/";
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import classNames from 'classnames';
import appState from "state/App";
import _ from 'lodash';
import styles from './PdfView.module.scss';

class PdfView extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        className: PropTypes.string,
        resizeParent: PropTypes.func,
        url: PropTypes.string,
        visible: PropTypes.bool,
    };

    static defaultProps = {
        className: null,
        resizeParent: null,
        url: null,
        visible: false,
    };

    /**
     * Creates an instance of the component
     *
     * @param {object} props Props passed from parent component
     */
    constructor (props) {
        super(props);

        this.nativeContainer = React.createRef();

        this.state = {
            page: 1,
            pdfUrl: null,
            numPages: null,
            url: null,
        };
    }

    /**
     * Handles updating the component
     *
     * @param {object} prevProps The previous props
     */
    componentDidUpdate = () => {
        const { visible, url } = this.props;

        if (visible && url !== this.state.url) {
            this.getPdf(url);
        }
    };

    /**
     * Called once the component has been torn down
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        const { pdfUrl } = this.state;

        if (pdfUrl) {
            window.URL.revokeObjectURL(pdfUrl);
        }
    };

    /**
     * Called once the component has loaded, queries the API for the document
     *
     * @param {string} url The API url to query
     *
     * @return {void}
     */
    getPdf = (url) => {
        this.setState({
            pdfUrl: null,
            url,
        }, () => {
            api.get(url, { responseType: 'blob' })
                .then(({ data }) => {
                    this.setState({
                        pdfUrl: window.URL.createObjectURL(data),
                    });
                }).catch((apiError) => {
                    createErrorToasts(apiError.response.data);
                });
        });
    };

    /**
     * Sets the number of pages in the document
     *
     * @param {number} numPages The number of pages in the document
     *
     * @return {void}
     */
    setNumPages = (numPages) => {
        if (numPages) {
            this.setState({
                numPages,
            });
        }
    };

    /**
     * Sets which page of the document to display
     *
     * @param {number} page The page to display
     *
     * @return {void}
     */
    setPage = (page) => {
        this.setState({
            page,
        }, () => {
            if (this.nativeContainer && this.nativeContainer.current) {
                this.nativeContainer.current.scrollTo(0, 0);
            }
        });
    };

    /**
     * Renders the nav bar
     *
     * @return {ReactElement} The component
     */
    renderNav = () => {
        const { page, numPages } = this.state;
        const { brandColour } = this.props.appState;

        if (numPages <= 1) {
            return null;
        }

        const pageNumbers = [];

        _.times(numPages, (index) => {
            const number = index + 1;
            const active = (page === number);
            const className = classNames(styles.pageNumber, {
                [styles.disabled]: active,
            });
            const buttonColour = active ? brandColour : null;
            const brandContrast = getMostContrastingColour(brandColour, contrastingColourList);
            const activeBrandContrast = active ? brandContrast : null;

            pageNumbers.push((
                <button
                    aria-label={`Page ${number}`}
                    className={className}
                    style={{
                        backgroundColor: buttonColour,
                        color: activeBrandContrast,
                    }}
                    disabled={active}
                    key={index}
                    onClick={() => this.setPage(number)}
                    type="button"
                >
                    {number}
                </button>
            ));
        });

        return (
            <div className={styles.nav} >
                <div className={styles.pageNumbers} >
                    {pageNumbers}
                </div>
            </div>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render = () => {
        const { className } = this.props;
        const { numPages, page, pdfUrl } = this.state;

        if (!pdfUrl) {
            return (
                <div className={className} aria-busy="true" role="alert">
                    <LoadingSpinner label="Loading" />
                </div>
            );
        }

        const containerClasses = classNames(styles.container, {
            [styles.includeNav]: (numPages && numPages > 1),
        });

        return (
            <div className={className} >
                <div ref={this.nativeContainer} className={containerClasses} >
                    <Pdf
                        file={pdfUrl}
                        onDocumentLoadSuccess={({ _pdfInfo }) => this.setNumPages(_pdfInfo.numPages)}
                        onPageRenderSuccess={this.props.resizeParent}
                        page={page}
                        scale={(window.innerWidth >= 600) ? 1.125 : 1}
                    />
                </div>
                {this.renderNav()}
            </div>
        );
    };

}

export default appState.attachState(PdfView);
