import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { PrimaryButton } from '@dataplan/react-components/dist/components/forms';
import text from './WelcomeMessage.json';
import WelcomeMessageContent from "./WelcomeMessageContent.js";
import IntroCard from "../IntroCard.js";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';

import styles from './WelcomeMessage.module.scss';

/**
 * Renders the welcome message component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const WelcomeMessage = ({ navigate }) => {
    const { appState } = useSelector((state) => state);
    const { brandColour: backgroundColor } = appState;

    const buttonStyles = {
        backgroundColor,
        color: getMostContrastingColour(backgroundColor, contrastingColourList),
    };

    return (
        <IntroCard>
            <WelcomeMessageContent />
            <PrimaryButton
                className={styles.startButton}
                onClick={() => navigate(1)}
                style={buttonStyles}
                type="submit"
                text={text.button}
            />
        </IntroCard>
    );
};

WelcomeMessage.propTypes = {
    navigate: PropTypes.func.isRequired,
};

export default WelcomeMessage;
