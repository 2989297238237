import { connect } from 'react-redux';
import _ from 'lodash';

export default class StateAccessor {

    /**
     * Creates an instance of the state accessor
     *
     * @param {string} accessorName A unique identifier for this state, probably the name of the class
     */
    constructor (accessorName) {
        this.accessorName = accessorName;
        this.stateKeyName = `${_.lowerFirst(this.accessorName)}State`;
        this.store = null;
    }

    /**
     * Gets the initial state controlled by this accessor. Implementations need to
     * override this and return a valid initial state object to be stored by Redux.
     */
    getInitialState () {
        throw new Error("Children of StateAccessor must implement getInitialState()");
    }

    /**
     * Gets the PropTypes definition for the state information. Implementations need
     * to override this and return an object suitable to be passed to PropTypes.shape()
     */
    getPropTypes () {
        throw new Error("children of StateAccessor must implement getPropTypes()");
    }

    /**
     * Sets the redux store used by the state reducer function
     *
     * @param {object} store The store as returned by createStore()
     *
     * @return {void}
     */
    setStore = (store) => {
        this.store = store;
    };

    /**
     * Redux reducer function to handle the set state action
     *
     * @param {object} state The incoming state
     * @param {object} action The action being performed
     *
     * @return {object} The new state
     */
    setStateReducer = (state = this.getInitialState(), action = null) => {
        // Do nothing if this action is not for us
        if (!action.type || action.type !== `${this.accessorName}.mergeState`) {
            return state;
        }

        return action.updateMethod(state);
    };

    /**
     * Updates the state held by this accessor by triggering a Redux action
     *
     * @param {function} updateMethod the method to be called to update the state
     *
     * @return {void}
     */
    setState = (updateMethod) => {
        if (this.store === null) {
            throw new Error("The state property has not been set");
        }

        this.store.dispatch({
            type: `${this.accessorName}.mergeState`,
            updateMethod,
        });
    };

    /**
     * Attaches the state from this accessor to a prop on a component
     *
     * @param {Component} componentClass The component to attach to
     *
     * @return {Component} A wrapped component with the state attached
     */
    attachState = (componentClass) => {
        const mapStateToProps = (currentStore) => {
            return {
                [this.stateKeyName]: currentStore[this.stateKeyName],
            };
        };

        return connect(mapStateToProps)(componentClass);
    };

    /**
     * Gets the current session data for the given state object or falls back to the initial state
     *
     * @return {object} The current state
     */
    getLoadedState () {
        const serializedState = sessionStorage.getItem(this.stateKeyName);

        if (serializedState === null) {
            return this.getInitialState();
        }

        return JSON.parse(serializedState);
    }

}
