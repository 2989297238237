import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";
import AppHeaderButton from "./AppHeaderButton.js";
import { HelpIcon } from "../";

import styles from "./HelpButton.module.scss";

/**
 * Renders the app header help button component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const HelpButton = ({ className, history }) => {
    const buttonClasses = classNames(styles.helpButton, className);

    return (
        <AppHeaderButton
            aria-label="Go to help centre"
            className={buttonClasses}
            onClick={() => history.push("/help")}
        >
            <Tooltip text="Help Centre" position="bottom">
                <HelpIcon height="25px" width="25px" />
            </Tooltip>
        </AppHeaderButton>
    );
};

HelpButton.propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
};

HelpButton.defaultProps = {
    className: null,
};

export default withRouter(HelpButton);
