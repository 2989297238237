import api from "lib/api";
import { createErrorToasts } from "lib/";

export default class AppSetting {

    /**
     * @param {StateAccessor} appState The global app state
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the app settings
     *
     * @return {Promise} A promise that resolves with the data
     */
    load () {
        return api.get("/app_settings/epayslips")
            .then(({ data }) => this.set(data))
            .catch((apiError) => {
                createErrorToasts(apiError);
            });
    }

    /**
     * Sets the app setting data
     *
     * @param {object} data the app settings data
     */
    set (data) {
        this.appState.setAppSettings(data);
    }

}
