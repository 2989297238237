import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty } from "lodash";
import appState from "state/App";
import userState from "state/User";
import { DescriptionList } from "../../components/DescriptionList";
import { attachStates } from "utils/ReduxUtils";
import { fullPayslipPropTypes } from "../../lib/prop-schemas/payslipsSchema";

import styles from "./MyDetails.module.scss";

class MyPayrollDetails extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
        fullPayslip: PropTypes.shape(fullPayslipPropTypes).isRequired,
    };

    /**
     * Gets the address line if the address is available
     *
     * @param {string} description the address line
     * @return {string} the address line
     */
    addressChecker (description) {
        const { address } = this.props.userState;

        return (address) ? description : "";
    }

    /**
     * Gets the key info details
     *
     * @return {ReactElement} The details
     */
    getInfoDetails () {
        const { employee, paydate } = this.props.fullPayslip;
        const { address } = this.props.userState;

        const recentPaydate = (paydate)
            ? moment(paydate, "YYYY-MM-DDTHH:mm:ssZ").format("DD-MM-YYYY")
            : null;

        const {
            "NI Number": ni,
            "Payroll Number": payrollRef,
            "Tax Code": taxCode,
            "NI Code": niCode,
        } = employee;

        if (this.props.appState.payslips.length === 0) {
            return [
                {
                    term: 'Payroll No.',
                    description: this.props.userState.payrollRef,
                },
                {
                    term: 'NI No.',
                    description: this.props.userState.ni,
                },
            ].filter((item) => item.description);
        }

        return [
            {
                term: 'Payroll No.',
                description: payrollRef,
            },
            {
                term: 'NI No.',
                description: ni,
            },
            {
                term: "NI Code",
                description: niCode,
            },
            {
                term: 'Tax Code',
                description: taxCode,
            },
            {
                term: 'Most Recent Pay Date',
                description: recentPaydate,
            },
            {
                term: 'Address Line 1',
                description: this.addressChecker(address.line_1),
            },
            {
                term: 'Address Line 2',
                description: this.addressChecker(address.line_2),
            },
            {
                term: 'Address Line 3',
                description: this.addressChecker(address.line_3),
            },
            {
                term: 'Postcode',
                description: this.addressChecker(address.postcode),
            },
        ].filter((item) => item.description);
    }

    /**
     * Renders the hmrc info
     *
     * @returns {ReactElement} The hmrc details
     */
    renderHMRCInfo () {
        const { hmrc_office_ref: hmrcOfficeRef, hmrc_tel: hmrcTel } = this.props.appState.payroll;

        if (hmrcOfficeRef && hmrcTel) {
            return (
                <div className={styles.hmrcSection}>
                    {`If you have any queries regarding your tax, please contact HM Revenue & Customs`
                    + ` at ${hmrcTel}. Please quote PAYE Ref: ${hmrcOfficeRef} and your National Insurance Number.`}
                </div>
            );
        }

        return null;
    }

    /**
     * Renders the payroll contact details
     *
     * @return {ReactElement} The payroll information contact details
     */
    renderPayrollInfo () {
        const { employee_support_email: payrollSupportEmail } = this.props.appState.payroll;

        const message = (isEmpty(payrollSupportEmail))
            ? "Should any of this information need updating, please contact your Payroll/HR Team."
            : `Should any of this information need updating, please email ${payrollSupportEmail}.`;

        return (
            <div className={styles.infoSection}>
                {message}
            </div>
        );
    }

    /**
     * Renders my payroll details component
     *
     * @return {ReactElement} The payroll details
     */
    render () {
        return (
            <div className={styles.bodyContent}>
                <DescriptionList details={this.getInfoDetails()} />
                {this.renderPayrollInfo()}
                {this.renderHMRCInfo()}
            </div>
        );
    }

}

export default (attachStates([appState, userState], MyPayrollDetails));
