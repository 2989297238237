import React from 'react';
import text from './AccountSetupComplete.json';
import { HelpIcon } from "../../";

import styles from './AccountSetupComplete.module.scss';

/**
 * Renders the welcome message content components
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const AccountSetupCompleteContent = () => (
    <>
        <div className={styles.header}>
            {text.header}
        </div>
        <div className={styles.body} >
            {text.body.map((line) => (
                <span key={line}>
                    {line}
                </span>
            ))}
        </div>
        <div className={styles.footer}>
            <HelpIcon
                className={styles.helpGraphic}
                fill="#CCC"
                height="66.39px"
                width="66.39px"
            />
            <div className={styles.footerText} >
                <span>{text.footer}</span>
            </div>
        </div>
    </>
);

export default AccountSetupCompleteContent;
