import moment from 'moment';

/**
 * Sorts the array by paydate
 *
 * @param {array} payslips Used to make a new array
 * @return {array} The array sorted by date
 */
const getSortedPayslips = (payslips) => {
    return payslips.map((payslip) => {
        return {
            paydateObject: moment(payslip.paydate, "YYYY-MM-DD"),
            ...payslip,
        };
    }).sort((a, b) => a.paydateObject.valueOf() - b.paydateObject.valueOf());
};

/**
 * Finds the index of the given payslip
 *
 * @param {array} sortedArray Array sorted by paydate
 * @param {numbers} id The payslip id
 * @return {numbers} The index of the given payslip
 */
const getIndexOfPayslip = (sortedArray, id) => {
    const idArray = sortedArray.map((payslip) => payslip.id);
    const index = idArray.indexOf(id);
    return index;
};

const exportableObject = {
    getSortedPayslips,
    getIndexOfPayslip,
};

export default exportableObject;
