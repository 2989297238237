import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { AnnouncementCard } from '@dataplan/react-components/dist/components';
import appStateProp from "state/App";
import { EmptyAnnouncement } from '.';

class Announcements extends React.Component {

    static propTypes = {
        animationDelay: PropTypes.number,
        announcements: PropTypes.array,
        appState: PropTypes.shape(appStateProp.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        isAnimated: PropTypes.bool,
        pageQuantity: PropTypes.number,
        quantity: PropTypes.number,
        isWhiteOnHover: PropTypes.bool,
    };

    static defaultProps = {
        animationDelay: 0,
        announcements: null,
        isAnimated: false,
        pageQuantity: Infinity,
        quantity: Infinity,
        isWhiteOnHover: true,
    };

    /**
     * Returns required animation timeout based on index of element in list
     * The first 3 (initial phase) appear in 200ms intervals
     * The others appear in 100ms intervals
     *
     * @param {number} index The index of element in list
     * @return {Array} The required animation timeout
     */
    getAppearTimeout = (index) => {
        const { animationDelay, pageQuantity } = this.props;
        const itemNum = ((index % pageQuantity) + 1);
        const endPhaseNum = 3;
        const withinInitialPhase = (itemNum <= endPhaseNum);

        const timeout = (withinInitialPhase)
            ? (itemNum * 200)
            : ((itemNum + endPhaseNum) * 100);

        return (animationDelay + timeout);
    };

    /**
     * Return array of newest announcements by quantity
     *
     * @param {Array} announcements Original announcements
     * @param {number} quantity Quantity required in return
     * @return {Array} List of announcements
     */
    getNewestAnnouncements = (announcements, quantity) => {
        const announcementsCopy = announcements.map((announcement) => {
            const dateaddedObject = moment(announcement.dateadded, "YYYY-MM-DDTHH:mm:ss");
            const dateadded = dateaddedObject.format("DD-MM-YYYY");
            const dateaddedFormatted = (dateadded === moment().format("DD-MM-YYYY"))
                ? dateaddedObject.fromNow() : dateadded;

            return {
                ...announcement,
                dateaddedObject,
                dateaddedFormatted,
            };
        });

        announcementsCopy.sort((a, b) => {
            return a.dateaddedObject.valueOf() - b.dateaddedObject.valueOf();
        });

        return announcementsCopy.slice(-quantity).reverse();
    };

    /**
     * Gets the notification text based on the notice type
     *
     * @param {string} type The notice type
     *
     * @return {object} The notification text object
     */
    getNotificationText = (type) => {
        const notificationTextRef = {
            "p60": {
                headerTitle: "Your latest P60 is ready to view",
                url: "/p60s",
            },
            "p11d": {
                headerTitle: "Your latest P11D is ready to view",
                url: "/p11ds",
            },
            "p45": {
                headerTitle: "Your P45 is ready to view",
                url: "/p45s",
            },
            "document": {
                headerTitle: "You have been sent a new document",
                url: "/documents",
            },
            "message": {
                headerTitle: "You have received a new message from your employer",
                url: "/messages",
            },
        };

        return notificationTextRef[type] || {};
    };

    /**
     * Render list of announcements
     *
     * @return {ReactElement} List of notifications cards, or empty notifications message
     */
    render () {
        const { announcements, appState, history, isAnimated, quantity, isWhiteOnHover } = this.props;
        const { accentColour } = appState;

        if (!announcements.length) {
            return <EmptyAnnouncement />;
        }

        return this.getNewestAnnouncements(announcements, quantity)
            .map((announcement, index) => {
                const { id, notice_type: noticeType, subject } = announcement; // eslint-disable-line camelcase
                const appearTimeout = (isAnimated) ? this.getAppearTimeout(index) : null;

                const {
                    headerTitle = subject,
                    url = `/announcement/${id}`,
                } = this.getNotificationText(noticeType.system_name);

                return (
                    <AnnouncementCard
                        accentColour={accentColour}
                        announcement={announcement}
                        appearTimeout={appearTimeout}
                        headerTitle={headerTitle}
                        key={id}
                        onClick={() => history.push(url)}
                        isWhiteOnHover={isWhiteOnHover}
                    />
                );
            });
    }

}

export default withRouter(appStateProp.attachState(Announcements));
