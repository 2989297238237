import api from "lib/api";

export default class P45s {

    /**
     * @param {StateAccessor} appState The appState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the documents data
     *
     * @return {void}
     */
    load () {
        api.get("/p45s?generate_access_token=true")
            .then(({ data }) => {
                const p45s = data.map((p45docs) => {
                    return {
                        type: 'p45',
                        description: `P45 - ${p45docs.leaving_date.split('-').reverse().join('-')}`,
                        ...p45docs,
                    };
                });

                this.set(p45s);
            });
    }

    /**
     * Handles the p45 data loaded from the API
     *
     * @param {array} p45s The p45 data for the current user
     *
     * @return {void}
     */
    set (p45s) {
        this.appState.setP45s(p45s);
    }

}
