import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { AnimationContainer } from "@dataplan/react-components/dist/components";
import SecondaryButton from "@dataplan/react-components/dist/components/forms/controls/SecondaryButton.js";
import IntroCardHeader from "./IntroCardHeader.js";
import { doFullLogout } from "../../lib/clearSessionData";
import { history } from "../../redux-store";

import styles from './IntroCard.module.scss';

/**
 * Renders the intro card component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const IntroCard = ({ children, onSkip, showLogout, noLeftMargin }) => {
    const { accentColour, payroll } = useSelector(({ appState }) => appState);

    /**
     * Logs the user out
     *
     * @return {void} Logs user out and redirects to login page
     */
    const handleLogout = () => {
        doFullLogout();
        history.replace('/');
    };

    return (
        <div className={styles.mask}>
            <AnimationContainer
                appearTimeout={200}
                className={styles.container}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <div className={styles.card}>
                    <IntroCardHeader />
                    <div className={styles.body}>
                        {children}
                        <div className={noLeftMargin ? styles.bottomButtonNoMargin : styles.bottomButton}>
                            {onSkip && (
                                <SecondaryButton
                                    accent={accentColour}
                                    aria-label="Not now"
                                    onClick={onSkip}
                                    text="Not now"
                                />
                            )}

                            {showLogout && payroll.mandatory_mfa && (
                                <SecondaryButton
                                    accent={accentColour}
                                    aria-label="Logout"
                                    onClick={handleLogout}
                                    text="Logout"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </AnimationContainer>
        </div>
    );
};

IntroCard.propTypes = {
    children: PropTypes.any,
    onSkip: PropTypes.func,
    showLogout: PropTypes.bool,
    noLeftMargin: PropTypes.bool,
};

IntroCard.defaultProps = {
    children: null,
    onSkip: null,
    showLogout: false,
    noLeftMargin: false,
};

export default IntroCard;
