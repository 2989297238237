import React from "react";
import { withRouter } from "react-router-dom";
import classNames from 'classnames';
import PropTypes from "prop-types";
import appState from "state/App";
import numeral from "numeral";
import _ from "lodash";
import { attachStates } from "utils/ReduxUtils";
import { contrastingColourList } from 'lib/';
import { DonutChart } from "./";
import { ACCENT_PRIMARY } from "shared/Colours";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";
import { ArrowDropDownIcon } from "@dataplan/react-components/dist/components/icons";

import styles from "./PayslipElementList.module.scss";
import UnderscoreIcon from "components/assets/icon/UnderscoreIcon";

class PayslipElementList extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        element: PropTypes.object,
        animationStart: PropTypes.string,
        previousPayslip: PropTypes.object,
        title: PropTypes.string,
    };

    static defaultProps = {
        element: {},
        animationStart: '',
        previousPayslip: null,
        title: null,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.arcData = this.getCardData();

        this.state = {
            arcData: this.arcData,
            selected: this.arcData[0],
            showDonut: this.shouldShowDonut(this.arcData),
        };
    }

    /**
     * Gets the data for the card to show
     *
     * @return {array} The data to push into the donut chart
     */
    getCardData () {
        const { element } = this.props;
        const arcData = [];

        _.forOwn(element, (value, key) => {
            arcData.push({
                label: key,
                value: parseFloat(value),
            });
        });

        return _.orderBy(arcData, 'value', ['desc']);
    }

    /**
     * Logic to decide if the donut should show or not
     *
     * @param {object} arcData the data for the donut chart
     * @returns {boolean} the bool for if the donut should show or not
     */
    shouldShowDonut (arcData) {
        return arcData.filter(({ value }) => (value < 0)).length === 0;
    }

    /**
     * Renders the list of payslip elements
     *
     * @param {element} event The event to prevent clicking the wrong object and closing the card
     * @param {array} selected The item object selected
     */
    callBack = (event, selected) => {
        event.stopPropagation();

        this.setState((prevState) => {
            return {
                ...prevState,
                selected,
            };
        });
    };

    /**
     * Renders the icon for no difference
     *
     * @param {string} label The current row
     *
     * @returns {React.Component} The no difference icon
     */
    renderNoVarianceIcon = (label) => (
        <Tooltip text={`No change to ${label}`} position="bottom">
            <UnderscoreIcon width="30" height="30" className={styles.underscoreIcon} />
        </Tooltip>
    );

    /**
     * Gets the styles for the arrows
     *
     * @param {bool} higher Whether the value has increased or decreased
     *
     * @returns {object} The styles for the arrows
     */
    getArrowStyles = (higher) => {
        const additionStyles = classNames(styles.additionsIcon, {
            [styles.increase]: higher,
        });

        const deductionStyles = classNames(styles.deductionsIcon, {
            [styles.increase]: higher,
        });

        return (this.props.title === "Deductions") ? deductionStyles : additionStyles;
    };

    /**
     * Renders the correct icon based on the difference
     *
     * @param {string} label The current row
     * @returns {React.Component} The icon for the row
     */
    renderVarianceIcon = (label) => {
        if (!this.props.previousPayslip || !this.props.previousPayslip[label]) {
            return this.renderNoVarianceIcon(label);
        }

        const difference = _.round((this.props.element[label] - this.props.previousPayslip[label]), 2);
        const higher = difference > 0;
        const variance = higher ? "increased" : "decreased";

        if (difference === 0) {
            return this.renderNoVarianceIcon(label);
        }

        const tooltipText = `Your ${label} has ${variance} by £${Math.abs(difference)}`;

        return (
            <Tooltip text={tooltipText} position="bottom">
                <ArrowDropDownIcon className={this.getArrowStyles(higher)} />
            </Tooltip>
        );
    };

    /**
     * Renders the list of payslip elements
     *
     * @return {ReactElement} The list or null if the details are hidden
     */
    render () {
        const { accentColour } = this.props.appState;
        const { arcData, selected, showDonut } = this.state;

        const rows = arcData.map(({ value, label }) => {
            const shouldDisplay = (_.isNumber(value))
                ? !_.isNull(value)
                : !_.isEmpty(value);

            const arcSelected = arcData.find((item) => item.label === label);
            const colourCheck = getMostContrastingColour(accentColour, contrastingColourList);
            const selectedColour = (colourCheck === '#000') ? ACCENT_PRIMARY : accentColour;
            const fillValue = (selected.label === label) ? selectedColour : '#eeeeee';

            if (shouldDisplay) {
                return (
                    <div
                        onClick={(event) => this.callBack(event, arcSelected)}
                        onKeyDown={(event) => this.callBack(event, arcSelected)}
                        tabIndex={0}
                        role="button"
                        key={label}
                    >
                        <dl key={label} className={styles.elementLine}>
                            <dt>
                                <svg width="20" height="20" className={styles.currentIcon}>
                                    <circle cx="10" cy="10" r="5" height="25" width="25" fill={fillValue} />
                                </svg>
                                <div className={styles.listKey}>{label}</div>
                            </dt>
                            <dd className={styles.listValue}>
                                {numeral(value).format("0,0.00")}
                                {this.renderVarianceIcon(label)}
                            </dd>
                        </dl>
                    </div>
                );
            }

            return null;
        }).filter((item) => item !== null);

        const fullCard = () => {
            return (
                <div className={styles.listContainer}>
                    <DonutChart
                        data={arcData}
                        selected={selected}
                        callBack={this.callBack}
                        animationStart={this.props.animationStart}
                    />
                    {rows}
                </div>
            );
        };

        const noDonut = () => {
            return (
                <div className={styles.listContainer}>
                    {rows}
                </div>
            );
        };

        return (showDonut ? fullCard() : noDonut());
    }

}

export default withRouter(attachStates([appState], PayslipElementList));
