import React from 'react';
import PropTypes from 'prop-types';

import { TertiaryButton } from "@dataplan/react-components/dist/components/forms/controls";

import styles from "./ErrorPageButton.module.scss";

class ErrorPageButton extends React.Component {

    static propTypes = {
        children: PropTypes.string.isRequired,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: null,
    };

    /**
     * Renders the button
     *
     * @return {ReactElement} The button
     */
    render () {
        const { children, onClick } = this.props;

        return (
            <TertiaryButton
                className={styles.button}
                onClick={onClick}
                text={children}
                aria-label={children}
            />
        );
    }

}

export default ErrorPageButton;
