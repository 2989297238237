import React from 'react';
import PropTypes from 'prop-types';
import api from "lib/api";
import appState from "state/App";
import { withRouter } from "react-router-dom";
import { createErrorToasts, contrastingColourList } from "lib/";
import _ from 'lodash';
import moment from 'moment';

import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import {
    DefaultForm,
    PrimaryButton,
    SecondaryButton,
} from '@dataplan/react-components/dist/components/forms';
import { LoadingSpinner } from '@dataplan/react-components/dist/components/ui/loading_spinner';
import { ChangeFormComponents } from '../';
import { formPropTypes } from 'lib/prop-schemas/formsSchema';

import styles from './ChangeFormLayout.module.scss';

class ChangeFormLayout extends React.Component {

    static propTypes = {
        ...formPropTypes,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.state = {
            questions: {},
            questionOrder: [],
            dataLoaded: false,
            inputChanged: false,
            submitting: false,
        };

        this.formComponentKey = 1;
    }

    /**
    * Called just after the component is added to the DOM
    *
    * @return {void}
    */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Get the form data from the API
     *
     * @return {void}
     */
    handleApiCall = () => {
        api.get(`/form/${this.props.id}`)
            .then(({data}) => {
                this.mapFormFields(data);
            });
    };

    /**
     * Map the form into an array to be used for the questions
     *
     * @param {object} formFields The form data
     *
     * @return {void} Modifies the state
     */
    mapFormFields (formFields) {
        const questions = {};
        const questionOrder = [];

        _(formFields.pages)
            .sortBy("page_order")
            .map((page) => {
                return page.questions;
            })
            .flatten()
            .forEach((question) => {
                questionOrder.push(question.id);

                questions[question.id] = {
                    ...question,
                    value: "",
                    isValid: this.checkIsValid("", question),
                };
            });
        this.setState({
            questions,
            questionOrder,
            dataLoaded: true,
        });
    }

    /**
     * Checks if the input is valid based on answer
     *
     * @param {string|Date} value The value of the input
     * @param {object} question The question
     *
     * @return {boolean} If the answer is valid
     */
    checkIsValid (value, question) {
        return !question.required || value;
    }

    /**
     * Check if we can submit the form based on fields validity and state
     *
     * @return {boolean} If we can submit
     */
    checkCanSubmit () {
        const { inputChanged, questions, submitting } = this.state;

        let invalidQuestions = _.filter(questions, (question) => {
            return !question.isValid;
        });

        return (inputChanged && invalidQuestions.length < 1 && !submitting);
    }

    /**
     * Called when any form input is changed
     *
     * @param {event} event The onChange event
     * @param {number} id The ID of the question
     *
     * @return {void} Modifies the state
     */
    handleInputChange = (event, id) => {
        if (event.preventDefault) {
            event.preventDefault();
        }

        const value = event.target.value;

        this.saveInputChangeToState(id, value);
    };

    /**
     * Called when any form input is changed
     *
     * @param {Date} date The date selected
     * @param {number} id The ID of the question
     *
     * @return {void} Modifies the state
     */
    handleDateChange = (date, id) => {
        this.saveInputChangeToState(id, date);
    };

    /**
     * Save the change from an input change to state
     *
     * @param {number} id The ID of the question
     * @param {string|number|Date} value The new value to save
     *
     * @return {void}
     */
    saveInputChangeToState (id, value) {
        this.setState((prevState) => {
            return {
                questions: {
                    ...prevState.questions,
                    [id]: {
                        ...prevState.questions[id],
                        value,
                        isValid: this.checkIsValid(value, prevState.questions[id]),
                    },
                },
                inputChanged: true,
            };
        }, () => {
            appState.blockNavigation();
        });
    }

    /**
     * Called when the form is submitted
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        appState.unblockNavigation();
        event.preventDefault();

        if (!this.checkCanSubmit()) {
            return;
        }

        this.setState({
            inputChanged: false,
            submitting: true,
        }, () => {
            let answers = {};

            _.forEach(this.state.questions, (question) => {
                answers[question.name] = (question.value instanceof Date)
                    ? moment(question.value).format("DD/MM/YYYY")
                    : question.value;
            });

            this.submit(answers);
        });
    };

    /**
     * Submit form to api
     *
     * @param {object} answers The form answers
     *
     * @return {void}
     */
    submit = (answers) => {
        api.post(`/form/${this.props.id}`, { answers })
            .then(() => {
                this.setState({
                    submitting: false,
                });

                appState.addNotification({
                    type: "success",
                    text: [
                        "Thanks, your changes have been submitted to your employer.",
                        "\r\n\r\n",
                        "Your details will be updated once approved.",
                    ].join(""),
                    duration: 5,
                }, () => {
                    this.handleApiCall();

                    // Force redraw of the form, removing the touched states from the input components
                    // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
                    this.formComponentKey++;

                    // For Safari
                    document.body.scrollTop = 0;
                    // For Chrome, Firefox, IE and Opera
                    document.documentElement.scrollTop = 0;
                });
            })
            .catch((apiError) => {
                createErrorToasts(apiError.response.data);
            });
    };

    /**
     * Render the form content based on state
     *
     * @return {ReactElement} The form components
     */
    renderFormContent () {
        const { dataLoaded, questions, questionOrder } = this.state;

        if (!dataLoaded) {
            return <LoadingSpinner label="Loading..." />;
        }

        return (
            <ChangeFormComponents
                key={this.formComponentKey}
                questions={questions}
                questionOrder={questionOrder}
                handleInputChange={this.handleInputChange}
                handleDateChange={this.handleDateChange}
            />
        );
    }

    /**
     * Render the form buttons
     *
     * @return {ReactElement} The form buttons
     */
    renderButtons () {
        const canSubmit = this.checkCanSubmit();
        const { accentColour } = this.props.appState;
        const buttonStyles = {
            primary: {
                backgroundColor: accentColour,
                color: getMostContrastingColour(accentColour, contrastingColourList),
            },
        };

        return (
            <div className={styles.buttonContainer}>
                <div className={styles.primaryButtonContainer}>
                    <PrimaryButton
                        type="submit"
                        style={(canSubmit) ? buttonStyles.primary : null}
                        disabled={!canSubmit}
                        text="Save"
                    />
                </div>
                <div className={styles.secondaryButtonContainer}>
                    <SecondaryButton
                        onClick={() => this.props.history.push("/")}
                        text="Cancel"
                    />
                </div>
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                {this.renderFormContent()}
                {this.renderButtons()}
            </DefaultForm>
        );
    }

}

export default withRouter(appState.attachState(ChangeFormLayout));
