import React from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import classNames from "classnames";
import { Drawer } from "@dataplan/react-components/dist/components/ui/drawer";
import { TertiaryButton } from "@dataplan/react-components/dist/components/forms/controls";
import { ArrowIcon, CloseIcon } from "@dataplan/react-components/dist/components/icons";

import { PageHeader, PdfView } from "components/";
import handleFile from '../lib/downloaders/handleFile';
import styles from "./PdfDrawer.module.scss";
import appState from "state/App";

class PdfDrawer extends React.Component {

    static propTypes = {
        accentColour: PropTypes.string,
        enablePrint: PropTypes.bool,
        pageName: PropTypes.string,
        renderSendForm: PropTypes.func,
        url: PropTypes.string,
        printUrl: PropTypes.string,
        visible: PropTypes.bool,
    };

    static defaultProps = {
        accentColour: "#eee",
        enablePrint: false,
        visible: false,
        pageName: "",
        renderSendForm: null,
        url: null,
        printUrl: null,
    };

    /**
     * Creates an instance of the component
     *
     * @param {object} props Props passed from parent component
     */
    constructor (props) {
        super(props);

        this.resizeThrottle = _.throttle(this.handleResize, 100);

        this.state = {
            thin: null,
            snackVisible: true,
        };

        this.drawer = React.createRef();
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        window.addEventListener("resize", this.resizeThrottle);
    };

    /**
     * Handles updating the component
     *
     * @param {object} prevProps The previous props
     */
    componentDidUpdate (prevProps) {
        const { url, visible } = this.props;

        if (url !== prevProps.url) {
            this.toggleSendFormVisibility(false);
        }

        if (this.drawer.current && visible !== prevProps.visible) {
            this.toggleDrawer();
        }
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        window.removeEventListener("resize", this.resizeThrottle);
    };

    /**
     * Sets drawer and pdf classes depending on width of drawer and pdf
     */
    handleResize = () => {
        const drawer = this.drawer.current;

        // Only execute if drawer is visible
        if (drawer && drawer.state.visible) {
            // Checks if drawer class requires updating
            const { thin } = this.state;
            const width = drawer.getWidth();
            const updateRequired = thin ? width >= 600 : width < 600;

            if (updateRequired) {
                this.setState((prevState) => {
                    return {
                        thin: !prevState.thin,
                    };
                });
            }
        }
    };

    /**
     * Toggle send form visibility
     *
     * @param {boolean} setVisible Optional parameter to set form visibility as true or false
     * @return {void} Changes state of pdf drawer
     */
    toggleSendFormVisibility = (setVisible) => {
        this.setState((prevState) => {
            const sendFormVisible = (_.isBoolean(setVisible))
                ? setVisible
                : !prevState.sendFormVisible;

            return {
                sendFormVisible,
            };
        });
    };

    /**
     * Downloads the PDF file to the client's device
     */
    downloadPdf = () => {
        const { printUrl } = this.props;

        if (printUrl) {
            handleFile(printUrl, {
                target: '_blank',
            }, this.drawer.current.nativeDrawer.current);
        }
    };

    /**
     * Helper method to get the drawer top action
     *
     * @param {boolean} sendFormVisible Whether the send form is visible
     * @return {Object} The drawer top action
     */
    getTopAction = (sendFormVisible) => {
        let topAction;

        const backIconClasses = classNames(styles.icon, styles.back);

        if (!sendFormVisible) {
            topAction = {
                icon: <CloseIcon aria-label="Close button" className={styles.icon} />,
                action: this.toggleDrawer,
            };
        } else {
            topAction = {
                icon: <ArrowIcon className={backIconClasses} />,
                action: this.toggleSendFormVisibility,
            };
        }
        return topAction;
    };

    /**
     * Render method to get the tertiary button component
     *
     * @param {string} text The button text
     * @param {function} onClick The function called when the button is clicked
     * @return {ReactElement} The tertiary button component
     */
    renderButton = (text, onClick = null) => {
        const { accentColour } = this.props;

        return (
            <TertiaryButton
                accent={accentColour}
                aria-label={text}
                onClick={onClick}
                text={text}
                role="button"
            />
        );
    };

    /**
     * Render method to get the drawer header
     *
     * @return {ReactElement} The drawer header buttons
     */
    renderHeader = () => {
        const { enablePrint, renderSendForm } = this.props;

        if (this.state.sendFormVisible) {
            return null;
        }

        const sendButton = (renderSendForm)
            ? this.renderButton("Send", this.toggleSendFormVisibility)
            : null;

        const printButton = (enablePrint)
            ? this.renderButton("Download", this.renderSnackBar)
            : null;

        return (
            <div className={styles.buttonContainer}>
                {sendButton}
                {printButton}
            </div>
        );
    };

    /**
     * Render method to get the drawer footer
     *
     * @return {ReactElement} The drawer footer
     */
    renderFooter = () => {
        const { renderSendForm } = this.props;
        const { sendFormVisible } = this.state;

        if (sendFormVisible) {
            const sendFormProps = {
                sendFormVisible,
                closeDrawer: this.toggleDrawer,
            };

            return (
                <>
                    {renderSendForm(sendFormProps)}
                    {this.renderButton("Back", this.toggleSendFormVisibility)}
                </>
            );
        }

        return this.renderButton("Close", this.toggleDrawer);
    };

    /**
     * Handle user confirmation of navigating away
     *
     * @return {void}
     */
    handleDownload = () => {
        this.downloadPdf();
        this.handleSnackClose();
    };

    /**
     * Handle user cancelling navigation
     *
     * @return {void} Closes snack
     */
    handleSnackClose = () => this.setState(({ snackVisible: false }), appState.closeSnackBar);

    /**
     * Render the snack bar to prompt the user
     */
    renderSnackBar = () => {
        this.setState(({
            snackVisible: true,
        }), () => {
            appState.addSnackBar({
                type: 'warn',
                message: "This file may contain sensitive information. Because this file may contain personal"
                    + " data about yourself, you should keep it secure and take precautions when storing, "
                    + "sharing or uploading it to any other services.",
                onConfirm: this.handleDownload,
                confirmText: "Download",
                onCancel: this.handleSnackClose,
                cancelText: "Cancel",
                showCancelButton: false,
            });
        });
    };

    /**
     * Toggle drawer visibility
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = () => this.drawer.current.toggleDrawer();

    /**
     * Renders the drawer
     *
     * @return {ReactElement} The drawer
     */
    render = () => {
        const { pageName, url, visible } = this.props;
        const { getTopAction, handleResize, toggleDrawer } = this;
        const { thin, snackVisible, sendFormVisible } = this.state;

        const drawerClasses = classNames(styles.drawer, {
            [styles.visible]: visible,
            [styles.thin]: thin,
        });

        const pdfContainerClasses = classNames(styles.pdfContainer, {
            [styles.hidden]: sendFormVisible,
        });

        return (
            <>
                <div>
                    <Drawer
                        title={"PDFDrawer"}
                        className={drawerClasses}
                        ref={this.drawer}
                        targetNode={document.body}
                        topAction={getTopAction(sendFormVisible)}
                        onClickOutside={toggleDrawer}
                        disableFocusTrap={snackVisible}
                    >
                        <PageHeader title={pageName}>
                            {this.renderHeader()}
                        </PageHeader>
                        <PdfView
                            className={pdfContainerClasses}
                            resizeParent={handleResize}
                            url={url}
                            visible={visible}
                        />
                        <div role="navigation" aria-label="PDF drawer navigation options">
                            {this.renderFooter()}
                        </div>
                    </Drawer>
                </div>
            </>
        );
    };

}

export default PdfDrawer;
