import React from "react";
import PropTypes from 'prop-types';
import appState from "state/App";
import { getAppName } from "lib/";

import { PageHeader, PageFrame } from "components/";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { ChangeFormLayout } from './';

class Forms extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.pageName = "Forms";
    }


    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Returns the tab content for the page layout
     *
     * @return {array} The tab content
     */
    getTabContent () {
        const { forms } = this.props.appState;

        return forms.map((form) => {
            let { id, title, description } = form;
            return {
                text: title,
                component: (
                    <ChangeFormLayout
                        key={id}
                        id={id}
                        title={title}
                        description={description}
                    />
                ),
            };
        });
    }

    /**
     * Renders the forms page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { accentColour } = this.props.appState;

        return (
            <AnimationContainer
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <PageFrame maxWidth={1200}>
                    <PageLayout
                        customHeader={<PageHeader title={this.pageName} inBoxedLayout />}
                        tabContent={this.getTabContent()}
                        accentColour={accentColour}
                    />
                </PageFrame>
            </AnimationContainer>
        );
    }

}

export default appState.attachState(Forms);
