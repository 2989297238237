import React from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import ReactRouterPropTypes from "react-router-prop-types";
import appState from "state/App";
import { getAppName } from "lib/";

import { PageHeader, PageFrame } from 'components/';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import {
    ChangePassword,
    ChangeEmailAddress,
    ChangeMobileNumber,
    ChangeUsername,
    ChangeSecurityQuestions,
    MultiFactorAuthentication,
    Devices,
} from './';

class MyAccount extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        location: ReactRouterPropTypes.location.isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.pageName = "My Account";
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Returns the tab content for the page layout
     *
     * @return {array} The tab content
     */
    getTabContent () {
        return [
            {
                text: "Username",
                component: <ChangeUsername />,
            },
            {
                text: "Password",
                component: <ChangePassword />,
            },
            {
                text: "Security questions",
                component: <ChangeSecurityQuestions />,
            },
            {
                text: "Email address",
                component: <ChangeEmailAddress />,
            },
            {
                text: "Mobile number",
                component: <ChangeMobileNumber />,
            },
            {
                text: "Multi-Factor Authentication",
                component: <MultiFactorAuthentication />,
            },
            {
                text: "Devices",
                component: <Devices />,
            },
        ];
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { location } = this.props;
        const { accentColour } = this.props.appState;
        const tabIndex = (location.state?.tabIndex ?? 0);

        return (
            <AnimationContainer
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <PageFrame maxWidth={1200}>
                    <PageLayout
                        customHeader={<PageHeader title={this.pageName} inBoxedLayout />}
                        tabContent={this.getTabContent()}
                        initialTabIndex={tabIndex}
                        accentColour={accentColour}
                        maxWidth={"950px"}
                    />
                </PageFrame>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(MyAccount));
