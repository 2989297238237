import React from 'react';
import PropTypes from "prop-types";
import IntroCard from "../IntroCard.js";
import TOTPSetupForm from "../../TOTPSetupForm";
import phoneLockGraphic from "components/assets/icon/phone-lock.svg";

import styles from './TOTPSetupIntro.module.scss';
import {useSelector} from "react-redux";

/**
 * Renders the authentication intro component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const TOTPSetupIntro = ({ navigate }) => {
    const { payroll } = useSelector(({ appState }) => appState);

    const onSkip = (!payroll.mandatory_mfa)
        ? () => navigate(1)
        : null;

    return (
        <IntroCard onSkip={onSkip} showLogout>
            <div className={styles.header}>
                Setup Authentication
            </div>
            <div className={styles.body}>
                <TOTPSetupForm onSubmit={() => navigate(1)} />
                <img
                    alt="phoneLockGraphic"
                    className={styles.graphic}
                    src={phoneLockGraphic}
                />
            </div>
        </IntroCard>
    );
};

TOTPSetupIntro.propTypes = {
    navigate: PropTypes.func.isRequired,
};

export default TOTPSetupIntro;
