import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import numeral from "numeral";

import styles from "../PayslipSharedStyles.module.scss";

/**
 * Renders the payslip accordion header
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The payslip accordion header
 */
const PayslipAccordionHeader = ({ className, detailsOpen, title, total }) => {
    const headerStyles = classNames(styles.header, styles.primary, className);
    const valueStyles = classNames(styles.value, className);

    const labelStyles = classNames(styles.label, styles.primary, {
        [styles.highlighted]: (detailsOpen === title),
    });

    return (
        <div className={headerStyles}>
            <h2 className={labelStyles}>
                {title}
            </h2>
            <h2 className={valueStyles}>
                {numeral(total).format("0,0.00")}
            </h2>
        </div>
    );
};

PayslipAccordionHeader.propTypes = {
    className: PropTypes.string,
    detailsOpen: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number.isRequired,
};

PayslipAccordionHeader.defaultProps = {
    className: null,
    detailsOpen: null,
    title: null,
};

export default PayslipAccordionHeader;
