import React from 'react';
import PropTypes from 'prop-types';
import { attachStates } from "utils/ReduxUtils";
import appState from 'state/App';
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import {
    PageFrame,
    AnimateTextUnderline,
} from "components/";
import AnimationContainer from "@dataplan/react-components/dist/components/ui/animation/AnimationContainer";
import SelectInput from "@dataplan/react-components/dist/components/forms/controls/SelectInput";
import { PageLayout } from "@dataplan/react-components/dist/components/ui/page_layout";
import BulkDownloadTable from "./Assets/BulkDownloadTable";

import styles from './PayslipBulkDownload.module.scss';

class PayslipBulkDownload extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        forwardedRef: PropTypes.any,
    };

    static defaultProps = {
        forwardedRef: null,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);
        this.subjectTextInput = React.createRef();

        this.pageName = "Payslip Download";

        this.state = {
            taxYear: null,
            allShown: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;

        appState.setPageDetails("Payslip Download", [
            {
                "text": "Home",
                "url": "/",
            },
            {
                "text": "Payslips",
                "url": "/payslips",
            },
            {
                "text": "Download",
                "url": null,
            },
        ]);
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = "ePayslips";
    }

    /**
     * Render the button text with underline element
     *
     * @return {ReactElement} The button text
     * @param {string} text The text of the button
     */
    renderButtonText (text) {
        const classList = {
            underlineFill: styles.underlineFill,
        };

        return (
            <AnimateTextUnderline classList={classList}>
                {text}
            </AnimateTextUnderline>
        );
    }

    /**
     * Renders the show more button
     *
     * @return {ReactElement} The button
     * @param {object} allPayslips All the payslips of the selected year
     */
    renderShowMoreButton (allPayslips) {
        if (this.state.allShown || allPayslips.length <= 12) {
            return null;
        }

        return (
            <div
                role="button"
                tabIndex={0}
                onClick={() => this.setState({allShown: true})}
                onKeyDown={() => this.setState({allShown: true})}
                className={styles.closeButton}
            >
                <button
                    type="button"
                    className={styles.button}
                    ref={this.props.forwardedRef}
                >
                    {this.renderButtonText("Show more")}
                </button>
            </div>

        );
    }

    /**
     * Create an object of all payslips, payslips to show and current tax year from filter
     *
     * @return {object} The object of payslips
     */
    createPayslipList () {
        const currentTaxYear = this.getCurrentTaxYear();

        const all = _(this.props.appState.payslips)
            .filter((payslip) => payslip.year === currentTaxYear)
            .sortBy((payslip) => moment(payslip.paydate, "YYYY-MM-DD").unix())
            .reverse()
            .value();
        const visible = _(all)
            .slice(0, (!this.state.allShown) ? 12 : undefined)
            .value();
        return {
            all,
            visible,
            currentTaxYear,
        };
    }

    /**
     * Gets the currently selected tax year
     *
     * @return {integer} The year
     */
    getCurrentTaxYear () {
        return (this.state.taxYear !== null)
            ? this.state.taxYear
            : _.last(this.props.appState.payslips).year;
    }

    /**
     * Gets a list of years that have payslips
     *
     * @return {array} A list of years
     */
    getTaxYearOptions () {
        return _(this.props.appState.payslips)
            .map((payslip) => payslip.year)
            .uniq()
            .sort()
            .reverse()
            .value();
    }

    /**
     * Called when the tax year filter is updated
     *
     * @param {Event} event the event
     *
     * @return {void}
     */
    handleFilterChange = (event) => {
        this.setState({
            taxYear: parseInt(event.target.value, 10),
            allShown: false,
        });
    };

    /**
     * Renders the Year Select Drop Down
     *
     * @return {ReactElement} the select input
     */
    renderYearSelect () {
        const payslips = this.createPayslipList();

        return (
            <SelectInput
                name="payslipYearSelect"
                value={_.toString(payslips.currentTaxYear)}
                onChange={this.handleFilterChange}
                className={styles.yearSelect}
                aria-label="payslip tax year select input"
            >
                {this.getTaxYearOptions().map((year) => {
                    return (
                        <option key={year} value={_.toString(year)}>
                            {year} - {year + 1}
                        </option>
                    );
                })}
            </SelectInput>
        );
    }

    /**
     * Renders the header of the page
     *
     * @return {ReactElement} The header
     */
    renderHeader () {
        return (
            <div className={styles.bulkHeader}>
                <h1 className={styles.pageTitle}>
                    {this.pageName}
                </h1>
                {this.renderYearSelect()}
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { all, visible, currentTaxYear } = this.createPayslipList();

        return (
            <AnimationContainer
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <PageFrame maxWidth={1200}>
                    <PageLayout
                        customHeader={this.renderHeader()}
                        className={styles.pageContent}
                        dataHeader={false}
                    >
                        <BulkDownloadTable key={(this.state.taxYear || currentTaxYear)} payslips={visible} />
                        {this.renderShowMoreButton(all)}
                    </PageLayout>
                </PageFrame>
            </AnimationContainer>
        );
    }

}

export default withRouter(attachStates([appState], PayslipBulkDownload));
