import React from 'react';
import { useSelector } from "react-redux";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList, getAppName } from 'lib/';

import styles from './IntroCard.module.scss';

/**
 * Renders the intro card component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const IntroCardHeader = () => {
    const { brandColour: backgroundColor } = useSelector(({ appState }) => appState);

    const headerStyle = {
        backgroundColor,
    };

    const textStyle = {
        color: getMostContrastingColour(backgroundColor, contrastingColourList),
    };

    return (
        <div
            className={styles.header}
            style={headerStyle}
        >
            <h1
                className={styles.headerText}
                style={textStyle}
            >
                Welcome to {getAppName()}
            </h1>
        </div>
    );
};

export default IntroCardHeader;
