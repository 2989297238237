import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Drawer } from "@dataplan/react-components/dist/components/ui/drawer";
import { CloseIcon } from "@dataplan/react-components/dist/components/icons";
import { PrimaryButton } from "@dataplan/react-components/dist/components/forms/controls";
import { PasswordInputRow } from "@dataplan/react-components/dist/components/forms";
import { PageHeader } from "components/";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';

import styles from "./EvcDrawer.module.scss";

const EvcDrawer = ({ forwardedRef, disable, pageName, reset, buttonColour, apiCall }) => {
    const [visible, setVisible] = useState(false);
    const [password, setPassword] = useState("");

    const drawerClasses = classNames(styles.drawer, {
        [styles.visible]: visible,
    });

    const buttonStyles = {
        backgroundColor: buttonColour,
        color: getMostContrastingColour(buttonColour, contrastingColourList),
    };

    const disabled = !password;

    /**
     * Toggle drawer visibility
     *
     * @return {void} Changes state of drawer
     */
    const toggleDrawer = () => {
        const drawer = forwardedRef.current;

        setVisible(!visible);

        drawer.toggleDrawer(() => reset(true));
    };

    /**
     * Helper method to get the drawer top action
     *
     * @return {Object} The drawer top action
     */
    const getTopAction = () => {
        return {
            icon: <CloseIcon aria-label="Close button" className={styles.icon} />,
            action: toggleDrawer,
        };
    };

    /**
     * Handles the api calla nd closing of drawer
     *
     * @returns {void}
     */
    const handleApiCall = () => {
        apiCall(password).then((
            toggleDrawer
        ));
    };

    return (
        <Drawer
            title={(disable) ? "Authorization" : "Setup"}
            className={drawerClasses}
            ref={forwardedRef}
            targetNode={document.body}
            topAction={getTopAction()}
            onClickOutside={() => toggleDrawer()}
        >
            <PageHeader title={pageName} />
            <PasswordInputRow
                name="password"
                label="Enter password"
                autoComplete="new-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                rowClassName={styles.inputRow}
            />
            <PrimaryButton
                style={(!disabled) ? buttonStyles : null}
                text="Save"
                disabled={disabled}
                type="submit"
                onClick={handleApiCall}
            />
        </Drawer>
    );
};

EvcDrawer.propTypes = {
    pageName: PropTypes.string,
    disable: PropTypes.bool,
    reset: PropTypes.func,
    buttonColour: PropTypes.string,
    forwardedRef: PropTypes.any.isRequired,
    apiCall: PropTypes.func.isRequired,
};

EvcDrawer.defaultProps = {
    pageName: "",
    disable: false,
    reset: null,
    buttonColour: "#BD3A1C",
};

export default React.forwardRef((props, ref) => {
    return <EvcDrawer forwardedRef={ref} {...props} />;
});
