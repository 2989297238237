import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import FeedbackCard from "components/feedback/FeedbackCard";
import { hasActiveEpayslipsSurvey, surveyNotBeenSeen } from "lib/surveyHelpers";
import appState from "state/App";

/**
 * Renders the feedback component
 *
 * @param {object} props The component props
 * @param {object} history The router history
 *
 * @return {ReactElement} The component
 */
const Feedback = ({ history, ...props }) => {
    if (!hasActiveEpayslipsSurvey(props.appState?.survey) || !surveyNotBeenSeen(props.appState?.survey)) {
        history.push("/");
    }

    return (
        <main id="main" role="main">
            <FeedbackCard />
        </main>
    );
};

Feedback.propTypes = {
    history: PropTypes.object.isRequired,
    appState: PropTypes.shape(appState.getPropTypes()).isRequired,
};

export default withRouter(appState.attachState(Feedback));
