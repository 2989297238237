import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { SecurityCard } from "@dataplan/react-components/dist/components/ui/cards/security_card";
import AuthenticationForm from "./AuthenticationForm";

const AuthenticationCard = ({ history }) => {
    const onSubmit = () => history.push({
        pathname: '/my-account',
        state: { tabIndex: 5 },
    });

    return (
        <AnimationContainer
            appearTimeout={200}
            enterTimeout={1000}
            exitTimeout={100}
            animationStyle={"animationContainer"}
        >
            <SecurityCard
                bodyContent={(
                    <AuthenticationForm
                        enableSkip
                        onSubmit={onSubmit}
                    />
                )}
            />
        </AnimationContainer>
    );
};

AuthenticationCard.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(AuthenticationCard);
