import { history, store } from "../redux-store";
import api from "lib/api";

/**
 * Helper to log the user out
 *
 * @param {string} redirectUrl Optional external url to redirect to after logout
 *
 * @return {void}
 */
const clearSessionData = (redirectUrl) => {
    sessionStorage.clear();

    if (redirectUrl) {
        sessionStorage.setItem("redirectUrl", redirectUrl);
    }

    history.replace();
    store.dispatch({ type: "RESET" });
};

/**
 * Ends the current session
 *
 * @param {string|null} redirectUrl Optional external url to redirect to after logout
 *
 * @return {void}
 */
const doFullLogout = (redirectUrl = null) => {
    api.delete("/auth")
        .then(() => clearSessionData(redirectUrl))
        .catch(() => clearSessionData(redirectUrl));
};

export { doFullLogout, clearSessionData };
export default clearSessionData;
