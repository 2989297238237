import React from "react";
import appState from "state/App";
import PropTypes from 'prop-types';
import { apiUrl } from "lib/api";
import { getAppName } from "lib/";

import {
    PageHeader,
    PageFrame,
    P45sFrame,
    PdfDrawer,
} from "components/";

class P45s extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.pageName = "P45s";

        this.state = {
            viewedP45: null,
            showPreview: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Toggle drawer visibility
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                showPreview: !prevState.showPreview,
            };
        });
    };

    /**
     * Set P45 in state
     *
     * @param {object} viewedP45 The P45 to set in state
     *
     * @return {void} Changes state of page
     */
    viewP45 = (viewedP45) => {
        this.setState({
            viewedP45,
        }, () => {
            // Set timeout so that drawer click outside occurs first
            window.setTimeout(this.toggleDrawer, 0);
        });
    };

    /**
     * Renders the P45s page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { accentColour, p45s } = this.props.appState;
        const { viewedP45, showPreview } = this.state;

        const P45PrintUrl = (viewedP45) ? `${apiUrl}${viewedP45.url}&disposition=inline` : null;
        const P45Url = (viewedP45) ? `${apiUrl}${viewedP45.url}` : null;

        const headerTitle = (viewedP45) ? viewedP45.description : "";

        return (
            <PageFrame>
                <PdfDrawer
                    accentColour={accentColour}
                    enablePrint={true}
                    pageName={headerTitle}
                    url={P45Url}
                    printUrl={P45PrintUrl}
                    visible={showPreview}
                />
                <PageHeader title={this.pageName} />
                <P45sFrame p45s={p45s} onClick={this.viewP45} />
            </PageFrame>
        );
    }

}

export default appState.attachState(P45s);
