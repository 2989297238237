import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import classNames from 'classnames';
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';
import CalendarDate from "./CalendarDate.js";

import styles from "../PayslipSharedStyles.module.scss";

/**
 * Renders the payslip date badge
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The payslip date badge
 */
const PayslipDateBadge = ({ paydate, shouldWobble }) => {
    const { brandColour: backgroundColor } = useSelector(({ appState }) => appState);
    const momentPaydate = moment(paydate, "YYYY-MM-DD");
    const dateColour = getMostContrastingColour(backgroundColor, contrastingColourList);
    const toggleWobble = (shouldWobble) ? styles.wobbleAnimation : null;
    const dateBadgeClasses = classNames(styles.dateBadge, toggleWobble);

    return (
        <div className={styles.dateCutout} role="presentation">
            <div
                className={dateBadgeClasses}
                style={{ backgroundColor }}
            >
                <CalendarDate
                    date={momentPaydate}
                    colour={dateColour}
                    className={styles.dateLabel}
                />
            </div>
        </div>
    );
};

PayslipDateBadge.propTypes = {
    paydate: PropTypes.string,
    shouldWobble: PropTypes.bool,
};

PayslipDateBadge.defaultProps = {
    paydate: null,
    shouldWobble: false,
};

export default PayslipDateBadge;
