import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import appStateMethods from "state/App";
import { contrastingColourList } from 'lib/';
import AppHeaderButton from "./AppHeaderButton.js";

import styles from "./SideMenuButton.module.scss";

/**
 * Renders the app header side menu button component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const SideMenuButton = ({ className, forwardedRef }) => {
    const { brandColour, colouredNav, menuOpen } = useSelector(({ appState }) => appState);

    const ariaLabel = classNames({
        "Open": !menuOpen,
        "Close": menuOpen,
    }, "side menu");

    const backgroundColor = (colouredNav && menuOpen)
        ? getMostContrastingColour(brandColour, contrastingColourList)
        : null;

    const buttonClasses = classNames(styles.menuButton, className, {
        [styles.menuOpen]: menuOpen,
    });

    return (
        <AppHeaderButton
            aria-label={ariaLabel}
            className={buttonClasses}
            onClick={() => appStateMethods.setMenuOpen(!menuOpen)}
            ref={forwardedRef}
        >
            <div className={styles.menuButtonLine} style={{ backgroundColor }} />
            <div className={styles.menuButtonLine} style={{ backgroundColor }} />
            <div className={styles.menuButtonLine} style={{ backgroundColor }} />
        </AppHeaderButton>
    );
};

SideMenuButton.propTypes = {
    className: PropTypes.string,
    forwardedRef: PropTypes.any,
};

SideMenuButton.defaultProps = {
    className: null,
    forwardedRef: null,
};

export default React.forwardRef((props, ref) => <SideMenuButton forwardedRef={ref} {...props} />);
