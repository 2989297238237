import React from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";
import { ArrowDropDownIcon } from "@dataplan/react-components/dist/components/icons";
import { TertiaryButton, PrimaryButton } from "@dataplan/react-components/dist/components/forms/controls";
import { TextInputRow, PasswordInputRow } from '@dataplan/react-components/dist/components/forms';

import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";

import appState from "state/App";
import { contrastingColourList } from 'lib/';
import { attachStates } from "../utils/ReduxUtils";
import userState from "../state/User";

import styles from './AccountRecoveryForm.module.scss';
import sharedStyles from "../shared/SharedStyles.module.scss";

const mobilePattern = /[0-9]{10,11}/;
const emailPattern = /[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/;

class AccountRecoveryForm extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
        enableRemind: PropTypes.bool,
        history: PropTypes.object.isRequired,
        onSubmit: PropTypes.func,
        isWelcomePage: PropTypes.bool,
    };

    static defaultProps = {
        enableRemind: false,
        onSubmit: _.noop,
        isWelcomePage: false,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.emailRef = React.createRef();
        this.numberRef = React.createRef();

        const { email, mobileNumber } = this.props.userState;
        const mobileTestNumber = mobileNumber ? mobileNumber.replace(/ /g, '') : "";

        this.state = {
            open: false,
            email: {
                value: email,
                valid: emailPattern.test(email),
                changed: false,
            },
            mobile: {
                value: mobileNumber ? mobileNumber : "",
                valid: mobilePattern.test(mobileTestNumber),
                changed: false,
            },
            password: {
                value: "",
                valid: false,
                changed: false,
            },
        };
    }

    /**
     * Toggles / sets if the drop down is open
     *
     * @param {boolean} setOpen Optional argument to set open or not
     */
    toggleOpen = (setOpen) => {
        this.setState((prevState) => {
            const open = (_.isBoolean(setOpen))
                ? setOpen
                : !prevState.open;

            return {
                open,
            };
        });
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handleInputChange = (event) => {
        const { target: { value, name, validity }} = event;

        this.setState((prevState) => {
            return {
                [name]: {
                    ...prevState[name],
                    changed: (value !== prevState[name].value),
                    valid: !_.isEmpty(value) && !validity.patternMismatch,
                    value,
                },
            };
        });
    };

    /**
     * Called when the value of the mobile input changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handleMobileInputChange = (event) => {
        const { target: { value, name }} = event;
        const spacelessValue = value.replace(/ /g, '');

        const mobileValidityCheck = (spacelessValue.match(mobilePattern)
            ? spacelessValue.match(mobilePattern)[0] === spacelessValue.match(mobilePattern).input : null);

        this.setState((prevState) => {
            return {
                [name]: {
                    ...prevState[name],
                    changed: (value !== prevState[name].value),
                    valid: !_.isEmpty(value) && mobileValidityCheck,
                    value,
                },
            };
        });
    };

    /**
     * Renders the input for the Email address
     *
     * @return {ReactElement} The Email address input
     */
    renderEmailAddress () {
        const { valid } = this.state.email;

        const errorText = !valid ? "Please enter a valid email address" : null;

        return (
            <>
                <h3 className={styles.inputTitle}>
                    Email Address
                </h3>

                <TextInputRow
                    ref={this.emailRef}
                    inputClassName={styles.input}
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="something@..."
                    pattern="[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$"
                    onChange={this.handleInputChange}
                    value={this.state.email.value}
                    errorText={errorText}
                />

                <div className={styles.inputInfo}>
                    An Email address you‘ll always have access to (not work)
                </div>
            </>
        );
    }

    /**
     * Renders the input for the phone number
     *
     * @return {ReactElement} The phone number input
     */
    renderMobileNumber () {
        const { valid } = this.state.mobile;
        const inputStyle = !valid ? styles.error : styles.input;

        const errorText = !valid ? "Please enter a valid phone number" : null;

        return (
            <>
                <h3 className={styles.inputTitle}>
                    Mobile No.
                </h3>

                <TextInputRow
                    ref={this.numberRef}
                    inputClassName={inputStyle}
                    name="mobile"
                    type="text"
                    autoComplete="tel"
                    placeholder="078..."
                    onChange={this.handleMobileInputChange}
                    value={this.state.mobile.value}
                    errorText={errorText}
                />

                <div className={styles.inputInfo}>
                    Please provide a mobile number in the following format: 07123 456 789.
                </div>
            </>
        );
    }

    /**
     * Renders the more info description
     *
     * @return {ReactElement} The more info description
     */
    renderMoreInfoDescription () {
        const { open } = this.state;
        // eslint-disable-next-line max-len
        const description = "We know that there may be a time in the future when you need to recover your account information. It happens. We get it.\n"
            + "\n"
            // eslint-disable-next-line max-len
            + "To make this process as a secure as possible, we will be asking you to confirm information such as your email address and mobile number which we need to hold on file.\n"
            + "\n"
            + "This is an extra level of security to protect your sensitive pay information.";

        const descriptionClasses = classNames(styles.tertText, {
            [styles.hidden]: !open,
        });

        return <div className={descriptionClasses}>{description}</div>;
    }

    /**
     * Renders the more info component
     *
     * @return {ReactElement} The more info component
     */
    renderMoreInfo () {
        const { open } = this.state;
        const { accentColour } = this.props.appState;
        const arrowStyles = (open) ? styles.arrowOpen : styles.arrowClosed;

        return (
            <>
                <TertiaryButton
                    accent={accentColour}
                    onClick={this.toggleOpen}
                    text="Why do we need this information?"
                    aria-label="More info"
                />
                <ArrowDropDownIcon width={24} height={24} className={arrowStyles} />
                {this.renderMoreInfoDescription()}
            </>
        );
    }

    /**
     * Helper: determines if a user can submit the security change form
     *
     * @return {boolean} True if all values are set and valid
     */
    getUserCanSubmitStatus () {
        const { email, mobile, password } = this.state;
        const validation = (email.valid && mobile.valid);

        return (validation && !_.isEmpty(password.value));
    }

    /**
     * Renders the buttons in the footer
     *
     * @return {ReactElement} The buttons in the footer
     */
    renderFooterButtons () {
        const { onSubmit } = this.props;
        const { email, mobile, password } = this.state;
        const { accentColour } = this.props.appState;

        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        const canSubmit = this.getUserCanSubmitStatus();

        return (
            <div className={styles.footerButtons}>
                <PrimaryButton
                    disabled={!canSubmit}
                    style={(canSubmit) ? buttonStyles : null}
                    aria-label="Save"
                    text="Save"
                    type="submit"
                    onClick={() => onSubmit(mobile, email, password.value)}
                />
                {this.renderReminderButton()}
            </div>
        );
    }

    /**
     * Renders the reminder button in the footer
     *
     * @return {ReactElement} The reminder button
     */
    renderReminderButton () {
        const { enableRemind } = this.props;
        const { accentColour } = this.props.appState;

        if (!enableRemind) {
            return null;
        }

        return (
            <TertiaryButton
                accent={accentColour}
                onClick={() => this.props.history.push('/')}
                text="Remind me later"
                aria-label="Remind me later"
                className={styles.skipButton}
            />
        );
    }

    /**
     * Renders the password input field to handle the API submissions. Password is required in order
     * to make any account changes
     *
     * @return {ReactElement} The password input row
     */
    renderPasswordInput () {
        const { email, mobile } = this.state;
        const emailMobileValid = (mobile.valid && email.valid);

        if (!emailMobileValid) {
            return null;
        }

        return this.getPasswordInputRow();
    }

    /**
     * Gets the password input row
     *
     * @return {ReactElement} The password input row
     */
    getPasswordInputRow () {
        const { value, valid } = this.state.password;

        const errorText = (!valid)
            ? "Password is required to make changes to your account"
            : null;

        return (
            <PasswordInputRow
                name="password"
                label="Password"
                onChange={this.handleInputChange}
                value={value}
                inputClassName={(!valid) ? sharedStyles.error : ''}
                showPasswordToggle
                errorText={errorText}
                autoComplete="current-password"
            />
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render = () => {
        const { isWelcomePage } = this.props;

        return (
            <>
                <h2 className={styles.cardText}>
                    We have some changes coming around account security - to help secure and recover
                    your account, please provide us with the following:
                </h2>
                <div className={styles.secondaryContent}>
                    {this.renderEmailAddress()}
                    {this.renderMobileNumber()}
                    {this.renderPasswordInput()}
                </div>
                <div className={isWelcomePage ? styles.welcomeTertText : styles.tertiaryContent}>
                    {this.renderMoreInfo()}
                </div>
                <div>
                    {this.renderFooterButtons()}
                </div>
            </>
        );
    };

}

export default withRouter(attachStates([appState, userState], AccountRecoveryForm));
