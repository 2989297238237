import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, withRouter } from "react-router-dom";
import appState from "../state/App";
import userState from "../state/User";
import { attachStates } from "./ReduxUtils";
import { hasActiveEpayslipsSurvey, surveyNotBeenSeen } from "../lib/surveyHelpers";

import NotAvailable from "../pages/Errors/NotAvailable";

class PrivateRoute extends React.Component {

    static propTypes = {
        component: PropTypes.any.isRequired,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
        location: PropTypes.object.isRequired,
        conditional: PropTypes.bool,
        resetScroll: PropTypes.bool,
        path: PropTypes.string.isRequired,
    };

    static defaultProps = {
        conditional: true,
        resetScroll: true,
    };

    /**
     * Calls whenever the component updates
     * Scrolls window to default 0, 0 when new page is loaded
     *
     * @param {object} prevProps Props before component update
     */
    componentDidUpdate (prevProps) {
        if (this.props.resetScroll && this.props.path !== prevProps.path) {
            window.scrollTo(0, 0);
        }
    }

    /**
     * Returns the welcome redirect component or null
     *
     * @returns {ReactElement} The welcome redirect component
     */
    renderWelcome = () => {
        const { pathname } = this.props.location;
        const { loggedIn, mfa_totp: mfaSetup } = this.props.userState;
        const { payroll } = this.props.appState;

        const shouldRedirectToWelcome = (loggedIn && pathname !== '/welcome' && payroll.mandatory_mfa && !mfaSetup);

        // Prevent users from changing URL to bypass introduction and mandatory MFA setup
        if (shouldRedirectToWelcome) {
            return <Redirect to={'/welcome'} />;
        }

        return null;
    };

    /**
     * Determines if the feedback form should show
     *
     * @return {boolean} True if we should display the form
     */
    requiresFeedback = () => {
        const { pathname } = this.props.location;
        const { survey } = this.props.appState;

        return (
            pathname !== '/feedback'
            && hasActiveEpayslipsSurvey(survey)
            && localStorage.getItem("loginCount") >= 2
            && surveyNotBeenSeen(survey)
        );
    };

    /**
     * Renders the PrivateRoute component
     * Redirects user to /login if API token isn't present
     *
     * @return {ReactElement} The intended route/component
     */
    render () {
        const { component: Component, conditional, ...otherProps } = this.props;
        const { loggedIn } = this.props.userState;
        const { redirectUrl } = this.props.appState;

        const renderFunction = () => {
            if (!loggedIn) {
                return <Redirect to={redirectUrl || "/login"} />;
            }

            this.renderWelcome();

            if (this.requiresFeedback()) {
                return <Redirect to={'/feedback'} />;
            }

            if (conditional) {
                return <Component />;
            } else {
                return <NotAvailable />;
            }
        };

        return <Route {...otherProps} render={renderFunction} />;
    }

}

export default withRouter(attachStates([appState, userState], PrivateRoute));
