import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import appState from "state/App";
import { PageHeader, PageFrame, PayslipStatic } from "components/";
import { contrastingColourList, getAppName } from "lib/";

import {
    AnimationContainer,
    CloudDownloadIcon,
    DateRangeIcon,
    SelectInput,
    TertiaryButton,
    PrimaryButton,
    Tooltip,
} from "@dataplan/react-components/dist/components";
import { DateTimeRow } from "@dataplan/react-components/dist/components/forms";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { ButtonMenu } from "@dataplan/react-components/dist/components/ui/floating_menu";

import styles from "./PayslipList.module.scss";

class PayslipList extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        animateAmount: PropTypes.number,
    };

    static defaultProps = {
        animateAmount: 3,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.pageName = "Payslips";

        this.state = {
            taxYear: null,
            allShown: false,
            dateFilterFrom: new Date(),
            dateFilterTo: new Date(),
            dateFilterRange: {
                from: null,
                to: null,
            },
            filterVisible: false,
            hover: false,
            keepOpen: true,
        };
    }

    /**
     * Decides if the component should update
     *
     * @param {object} nextProps The next props
     * @param {object} nextState The next state
     *
     * @return {bool} If the component needs to update
     */
    shouldComponentUpdate (nextProps, nextState) {
        const currentPayslips = this.props.appState.payslips;
        const nextPayslips = nextProps.appState.payslips;

        const stateChanged = !_.isEqual(this.state, nextState);
        const payslipsChanged = !_.isEqual(currentPayslips, nextPayslips);

        return stateChanged || payslipsChanged;
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Gets the currently selected tax year
     *
     * @return {integer} The year
     */
    getCurrentTaxYear () {
        return (this.state.taxYear !== null)
            ? this.state.taxYear
            : this.props.appState.latestPayslip.year;
    }

    /**
     * Gets a list of years that have payslips
     *
     * @return {array} A list of years
     */
    getTaxYearOptions () {
        return _(this.props.appState.payslips)
            .map((payslip) => payslip.year)
            .uniq()
            .sort()
            .reverse()
            .value();
    }

    /**
     * Called when the load more button is clicked
     *
     * @return {void}
     */
    handleLoadMoreClick = () => {
        this.setState({ allShown: true });
    };

    /**
     * Called when the tax year filter is updated
     *
     * @param {Event} event the event
     *
     * @return {void}
     */
    handleFilterChange = (event) => {
        this.setState({
            taxYear: parseInt(event.target.value, 10),
            allShown: false,
        });
    };

    /**
     * Create an object of all payslips, payslips to show and current tax year from filter
     *
     * @return {object} The object of payslips
     */
    createPayslipList () {
        const currentTaxYear = this.getCurrentTaxYear();
        const { dateFilterRange } = this.state;

        const filterFunction = (dateFilterRange.from && dateFilterRange.to)
            ? (payslip) => {
                const paydate = new Date(payslip.paydate);

                return (dateFilterRange.from <= paydate) && (paydate <= dateFilterRange.to);
            }
            : (payslip) => (
                payslip.year === currentTaxYear
            );

        const all = _(this.props.appState.payslips)
            .filter(filterFunction)
            .sortBy((payslip) => moment(payslip.paydate, "YYYY-MM-DD").unix())
            .reverse()
            .value();

        const visible = _(all)
            .slice(0, (!this.state.allShown) ? this.props.animateAmount : undefined)
            .value();

        return {
            all,
            visible,
            currentTaxYear,
        };
    }

    /**
     * Renders the load more button at the bottom of the list unless all payslip are alrady shown
     *
     * @param {array} allPayslips A collection of all payslips for the tax year
     *
     * @return {ReactElement} The button container
     */
    renderLoadMoreButton (allPayslips) {
        if (this.state.allShown || allPayslips.length <= this.props.animateAmount) {
            return null;
        }

        const { accentColour } = this.props.appState;

        return (
            <AnimationContainer
                animationStyle="animationContainerShowMore"
                appearTimeout={1400}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <TertiaryButton
                    text="Show More"
                    accent={accentColour}
                    onClick={this.handleLoadMoreClick}
                    className={styles.viewMore}
                    aria-label="Show more notifications"
                />
            </AnimationContainer>
        );
    }

    /**
     * Renders the date filter body
     *
     * @returns {React.Element} The date filter body
     */
    renderDateFilterBody () {
        const { accentColour } = this.props.appState;
        const { dateFilterTo, dateFilterFrom } = this.state;
        const payslips = this.createPayslipList();
        const maxYear = new Date(`${payslips.currentTaxYear + 1}, 01, 01`);

        const buttonStyles = {
            primary: {
                backgroundColor: accentColour,
                color: getMostContrastingColour(accentColour, contrastingColourList),
            },
        };

        return (
            <div className={styles.dateFilterBody}>
                <div className={styles.dateFilterTitle}>
                    Filter payslips by date:
                </div>
                <div className={styles.dateFilterContent}>
                    <DateTimeRow
                        key={"From"}
                        name={"From"}
                        label={"From"}
                        required={true}
                        showOptionalText
                        todayButton="Today"
                        onChange={(date) => this.setState({
                            dateFilterFrom: date,
                        })}
                        selected={dateFilterFrom}
                        inputClassName={styles.dateInput}
                        labelClassName={styles.dateInputTitle}
                        rowClassName={styles.dateInputContainer}
                        yearDropdownItemNumber={10}
                        maxDate={maxYear}
                    />
                    <DateTimeRow
                        key={"To"}
                        name={"To"}
                        label={"To"}
                        required={true}
                        showOptionalText
                        todayButton="Today"
                        onChange={(date) => this.setState({
                            dateFilterTo: date,
                        })}
                        selected={dateFilterTo}
                        inputClassName={styles.dateInput}
                        labelClassName={styles.dateInputTitle}
                        rowClassName={styles.dateInputContainer}
                        yearDropdownItemNumber={10}
                        maxDate={maxYear}
                    />
                </div>
                <PrimaryButton
                    text="Update"
                    onClick={() => this.setState({
                        dateFilterRange: {
                            from: dateFilterFrom,
                            to: dateFilterTo,
                        },
                    })}
                    style={buttonStyles.primary}
                    className={styles.updateButton}
                    aria-label="Updates payslip filter"
                />
                <PrimaryButton
                    text="Clear"
                    onClick={() => this.setState({
                        dateFilterRange: {
                            from: null,
                            to: null,
                        },
                    })}
                    style={buttonStyles.primary}
                    aria-label="Clears payslip filter"
                />
            </div>
        );
    }

    /**
     * Renders the date filter for the searching of payslips
     *
     * @return {ReactElement} The date filter
     */
    renderDateFilter () {
        return (
            <ButtonMenu className={styles.buttonMenu}
                button={(clickHandler, parentRef) => (
                    <button
                        type="button"
                        aria-label="opens side menu"
                        className={styles.roundedIcon}
                        onClick={clickHandler}
                        ref={parentRef}
                    >
                        <Tooltip text="Filter payslips" position="bottom">
                            <DateRangeIcon />
                        </Tooltip>
                    </button>
                )}
                keepBodyOpen={true}
                showCloseButton
            >
                {this.renderDateFilterBody()}
            </ButtonMenu>
        );
    }

    /**
     * Renders the Year Select Drop Down
     *
     * @return {ReactElement} the select input
     */
    renderYearSelect () {
        const payslips = this.createPayslipList();

        return (
            <AnimationContainer
                animationStyle="animationContainerShowMore"
                appearTimeout={600}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <SelectInput
                    name="payslipYearSelect"
                    value={_.toString(payslips.currentTaxYear)}
                    onChange={this.handleFilterChange}
                    className={styles.yearSelect}
                    altBackground={true}
                    aria-label="payslip tax year select input"
                >
                    {this.getTaxYearOptions().map((year) => {
                        return (
                            <option key={year} value={_.toString(year)}>
                                {year} - {year + 1}
                            </option>
                        );
                    })}
                </SelectInput>
            </AnimationContainer>
        );
    }

    /**
     * Called when the bulk payslip button is clicked
     *
     * @param {Event} event The native click event
     * @return {void}
     */
    handleBulkDownloadClick = (event) => {
        this.props.history.push('/payslips/download');
        event.preventDefault();
    };

    /**
     * Renders the page header
     *
     * @return {ReactElement} the page header
     */
    renderPageHeader () {
        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <div className={styles.header}>
                    <PageHeader title={this.pageName}>
                        <div className={styles.pageHeaderRight}>
                            {this.renderYearSelect()}
                        </div>
                    </PageHeader>
                    <div>
                        <button
                            type="button"
                            aria-label="opens side menu"
                            className={styles.roundedIcon}
                            onClick={this.handleBulkDownloadClick}
                        >
                            <Tooltip text="Download Payslips" position="bottom">
                                <CloudDownloadIcon />
                            </Tooltip>
                        </button>
                        {this.renderDateFilter()}
                    </div>
                </div>
            </AnimationContainer>
        );
    }

    /**
     * Renders the payslip stats inside of an animation container
     *
     * @param {object} payslip The payslip details
     * @param {number} appearTimeout The timeout for the animation container
     *
     * @return {ReactElement} The animated payslip container
     */
    renderPayslipAnimation = (payslip, appearTimeout) => (
        <AnimationContainer
            animationStyle="animationContainer"
            appearTimeout={appearTimeout}
            enterTimeout={1000}
            exitTimeout={100}
            key={payslip.id}
        >
            {this.renderPayslip(payslip)}
        </AnimationContainer>
    );

    /**
     * Renders the payslip stats
     *
     * @param {object} payslip The payslip details
     *
     * @return {ReactElement} The payslip component
     */
    renderPayslip = (payslip) => (
        <div
            role="presentation"
            className={styles.payslipContainer}
        >
            <PayslipStatic payslip={payslip} />
        </div>
    );

    /**
     * Renders the payslips page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { animateAmount } = this.props;
        let appearTimeout = 0;
        const payslips = this.createPayslipList();

        return (
            <PageFrame>
                {this.renderPageHeader()}
                {payslips.visible.length ? null : (
                    <div className={styles.emptyText}>No payslips within filter range</div>
                )}
                <div>
                    {payslips.visible.map((payslip, index) => {
                        if (index >= animateAmount) {
                            return this.renderPayslip(payslip);
                        }

                        _.forEach([payslip], () => {
                            appearTimeout += 200;
                        });

                        return this.renderPayslipAnimation(payslip, appearTimeout);
                    })}
                </div>

                {this.renderLoadMoreButton(payslips.all)}
            </PageFrame>
        );
    }

}

export default withRouter(appState.attachState(PayslipList));
