import React from "react";
import { withRouter } from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import { api, handleLoginPhase1, handleLoginPhase2 } from "lib/";
import LoadingSpinner from "@dataplan/react-components/dist/components/ui/loading_spinner/LoadingSpinner";
import appState from "state/App";

import styles from "./SetToken.module.scss";

class SetToken extends React.Component {

    static propTypes = {
        history: ReactRouterPropTypes.history.isRequired,
        location: ReactRouterPropTypes.location.isRequired,
    };

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const token = this.props.location.hash.substr(1);

        if (!token) {
            this.props.history.push("/");
        } else {
            this.handleSetToken(token);
        }
    }

    /**
     * Triggers the app data loading then redirects once done
     *
     * @param {string} token The API token to set
     *
     * @return {void}
     */
    handleSetToken (token) {
        sessionStorage.setItem("apiToken", token);

        handleLoginPhase1()
            .then(() => {
                handleLoginPhase2();
                this.setRedirectUrl();
            });
    }

    /**
     * Sets the redirect url in app state
     */
    setRedirectUrl = () => {
        api.get("/legacy_config")
            .then(({ data }) => {
                if (!data.domain) {
                    return;
                }

                const development = window.ENV.ENVIRONMENT === "development";
                const urlPrefix = (development) ? "http" : "https";
                const redirectUrl = `${urlPrefix}://${data.domain}`;

                appState.setRedirectUrl(redirectUrl);
            });
    };

    /**
     * Renders the loading spinner
     *
     * @return {ReactElement} The page
     */
    render () {
        return (
            <div className={styles.container}>
                <LoadingSpinner label="Loading" />
            </div>
        );
    }

}

export default withRouter(SetToken);
