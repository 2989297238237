import React, {Component} from 'react';
import appState from "state/App";
import styles from "./Authentication.module.scss";
import { AuthenticationCard } from "../../components";

import PageFrame from "components/PageFrame";

class Authentication extends Component {

    /**
     * Creates an instance of the authentication page
     *
     * @param {object} props Authentication page properties
     */
    constructor (props) {
        super(props);
        this.pageName = "Authentication";
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = "ePayslips";
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    /**
     * Renders the home page
     *
     * @return {ReactElement} The page
     */
    render () {
        return (
            <PageFrame>
                <div className={styles.authentication}>
                    <AuthenticationCard />
                </div>
            </PageFrame>
        );
    }

}

export default Authentication;
