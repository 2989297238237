import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import appState from "state/App";
import _ from 'lodash';

import { HomeButton, PrevPageButton } from './assets';
import { PageFrame, ErrorFrame } from "components/";

import styles from './Error.module.scss';

class NotAvailable extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName("Resource Not Available");
    }

    /**
     * Checks the conditional and returns accordingly
     *
     * @param {bool} conditional The value of a conditional to use
     * @param {string} value The value to display if conditional is true
     *
     * @return {?string} The value to display
     */
    checkConditional = (conditional, value) => {
        if (!conditional) {
            return null;
        }

        return value;
    };

    /**
     * Get a list of resources available in the app
     *
     * @return {object} The list
     */
    getResourceList = () => {
        const {
            eP60,
            P11d,
            personal,
            personalcompany,
            company,
            p45,
            pension,
            "drawing_board_documents": drawingBoardDocuments,
        } = this.props.appState.documents;

        const personalCompanyDocs = [...personal, ...personalcompany, ...company, ...drawingBoardDocuments];

        return {
            payslips: this.checkConditional(!_.isEmpty(this.props.appState.payslips), 'Payslips'),
            p60s: this.checkConditional(!_.isEmpty(eP60), 'eP60'),
            p11ds: this.checkConditional(!_.isEmpty(P11d), 'P11d'),
            p45s: this.checkConditional(!_.isEmpty(p45), 'p45'),
            messages: this.checkConditional(this.props.appState.messagesEnabled, 'Messages'),
            documents: this.checkConditional(!_.isEmpty(personalCompanyDocs), 'Documents'),
            pensions: this.checkConditional(!_.isEmpty(pension), 'pension'),

            forms: 'Forms',
        };
    };

    /**
     * Renders the content to show on the error frame
     *
     * @return {ReactElement} The content to display
     */
    renderContent = () => {
        const resourceList = this.getResourceList();
        const { accentColour } = this.props.appState;

        return (
            <>
                <p>Please use the suggestions below</p>
                <ul className={styles.list}>
                    {_.map(resourceList, (resource, key) => {
                        if (!resource) {
                            return null;
                        }

                        return (
                            <li key={key} className={styles.listItem}>
                                <Link
                                    to={`/${key}`}
                                    style={{color: accentColour}}
                                >
                                    {resource}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    /**
     * Renders the buttons to be shown on the error frame
     *
     * @return {ReactElement} The button(s)
     */
    renderButtons = () => {
        return (
            <div className={styles.buttonContainer}>
                <HomeButton />
                <PrevPageButton />
            </div>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <PageFrame maxWidth={1200}>
                <ErrorFrame
                    title="Sorry, the resource you requested is not available"
                    content={this.renderContent()}
                    buttons={this.renderButtons()}
                />
            </PageFrame>
        );
    }

}

export default appState.attachState(NotAvailable);
