import React from 'react';
import PropTypes from "prop-types";
import appState from "state/App";
import Messages from "../../lib/preloaders/Messages";
import naturalCompare from 'string-natural-compare';
import _ from 'lodash';

import { getAppName } from "lib/";
import { PageLayout } from "@dataplan/react-components/dist/components/ui/page_layout";
import { MessageListHeader, MessageTable } from ".";
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";

class MessageList extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the view message list page
     *
     * @param {object} props The component properties
     *
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.pageName = "Messages";
        this.preloader = new Messages(appState);

        this.state = {
            messages: {
                received: [],
                sent: [],
            },
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);

        this.getMessages();
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Gets a list of messages from the API
     *
     * @return {void}
     */
    getMessages = () => {
        new Promise((resolve) => {
            resolve(this.preloader.load());
        }).then(() => {
            this.categoriseMessages(this.props.appState.messages);
        });
    };

    /**
     * Put each message into the received or sent list depending on recipient
     *
     * @param {array} messages The list of messages to categorise
     *
     * @return {void}
     */
    categoriseMessages = (messages) => {
        if (messages.length < 1) {
            return;
        }

        let received = [];
        let sent = [];

        _.forEach(messages, (message) => {
            if (message.recipient === "employee") {
                received.push(message);
            } else if (message.recipient === "company") {
                sent.push(message);
            }
        });

        _.forEach([received, sent], (messageList) => {
            messageList.sort((a, b) => {
                return naturalCompare(b.time_sent, a.time_sent);
            });
        });

        this.setState({
            messages: {
                received,
                sent,
            },
        });
    };

    /**
     * Creates the tab array for the PageLayout component
     *
     * @return {array} An array of tabs
     */
    getTabContent = () => {
        const { messages } = this.state;

        return [
            {
                text: "Inbox",
                component: <MessageTable messages={messages.received} />,
            },
            {
                text: "Sent",
                component: <MessageTable messages={messages.sent} isSentMessages />,
            },
        ];
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { accentColour } = this.props.appState;

        return (
            <AnimationContainer
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <PageLayout
                    customHeader={<MessageListHeader title={this.pageName} />}
                    tabContent={this.getTabContent()}
                    accentColour={accentColour}
                />
            </AnimationContainer>
        );
    }

}

export default appState.attachState(MessageList);
