import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { ArrowIcon } from "@dataplan/react-components/dist/components/icons";
import { contrastingColourList } from 'lib/';
import PayslipDateBadge from "./PayslipDateBadge.js";

import styles from "./PayslipHeader.module.scss";

/**
 * Renders the payslip component header
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The header element
 */
const PayslipHeader = ({ canNavigate, navigatePayslips, paydate, shouldWobble }) => {
    const { brandColour } = useSelector(({ appState }) => appState);

    /**
     * Renders the arrow component
     *
     * @param {string} direction the direction of arrow renders
     *
     * @return {ReactElement} The component
     */
    const renderArrow = (direction) => { // eslint-disable-line react/no-multi-comp
        if (!navigatePayslips) {
            return null;
        }

        const isDisabled = !canNavigate[direction];
        const ariaLabel = classNames(direction, "arrow", { "disabled": isDisabled });
        const arrowColour = getMostContrastingColour(brandColour, contrastingColourList);
        const arrowStyles = classNames(styles.arrow, styles[direction], { [styles.disabled]: isDisabled });

        return (
            <ArrowIcon
                aria-label={ariaLabel}
                className={arrowStyles}
                fill={arrowColour}
                onClick={() => navigatePayslips(direction)}
            />
        );
    };

    return (
        <div
            className={styles.header}
            style={{ backgroundColor: brandColour }}
        >
            <PayslipDateBadge
                paydate={paydate}
                shouldWobble={shouldWobble}
            />
            {renderArrow("left")}
            {renderArrow("right")}
        </div>
    );
};

PayslipHeader.propTypes = {
    canNavigate: PropTypes.object,
    navigatePayslips: PropTypes.func,
    paydate: PropTypes.string,
    shouldWobble: PropTypes.bool,
};

PayslipHeader.defaultProps = {
    canNavigate: { left: false, right: false },
    navigatePayslips: null,
    paydate: null,
    shouldWobble: false,
};

export default PayslipHeader;
