import React from "react";
import appState from "state/App";
import { attachStates } from "utils/ReduxUtils";
import api from "lib/api";
import PropTypes from "prop-types";
import { fullPayslipDefaultProps } from 'lib/prop-schemas/payslipsSchema';
import { PageFrame } from "components/";
import KeyInfoSection from "./KeyInfoSection.js";
import LatestPayslipSection from "./LatestPayslipSection.js";
import NotificationsSection from "./NotificationsSection.js";

import styles from "./Home.module.scss";

class Home extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the documents page
     *
     * @param {object} props Documents page properties
     */
    constructor (props) {
        super(props);
        this.pageName = "Home";

        this.state = {
            fullPayslip: fullPayslipDefaultProps,
            fullPayslipLoaded: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageDetails("Home", [
            {
                "text": "Home",
                "url": null,
            },
        ]);
        appState.setPageName(this.pageName);
        document.title = this.pageName;
        this.loadFullPayslip();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = "ePayslips";
    }

    /**
     * Loads the full payslip data from the API
     *
     * @return {void}
     */
    loadFullPayslip () {
        const { latestPayslip } = this.props.appState;

        if (!latestPayslip) {
            return null;
        }

        return api.get(`/payslip/${latestPayslip.id}`)
            .then((response) => {
                this.setState({
                    fullPayslip: response.data,
                    fullPayslipLoaded: true,
                });
            });
    }

    /**
     * Renders the home page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { fullPayslip, fullPayslipLoaded } = this.state;

        return (
            <PageFrame>
                <LatestPayslipSection />
                <div className={styles.footer}>
                    <KeyInfoSection fullPayslip={fullPayslip} fullPayslipLoaded={fullPayslipLoaded} />
                    <NotificationsSection />
                </div>
            </PageFrame>
        );
    }

}

export default attachStates([appState], Home);
