import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import {
    AccountSetupComplete,
    AuthenticationIntro,
    SecurityIntro,
    TOTPSetupIntro,
    WelcomeMessage,
    MobileAppIntro,
} from "components/";

const sequence = {
    existing: [
        WelcomeMessage,
        SecurityIntro,
        AuthenticationIntro,
        TOTPSetupIntro,
        MobileAppIntro,
        AccountSetupComplete,
    ],
    new: [
        WelcomeMessage,
        AuthenticationIntro,
        TOTPSetupIntro,
        MobileAppIntro,
        AccountSetupComplete,
    ],
};

/**
 * Helper: determines the user sequence type
 *
 * @param {boolean} mfaRequired Is MFA mandatory
 * @param {boolean} lastLogin Has user logged in to this application before
 *
 * @return {string} The login sequence to use
 */
const getSequenceType = (mfaRequired, lastLogin) => {
    return (lastLogin) ? "existing" : "new";
};

/**
 * Renders the welcome component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const Welcome = ({ history }) => {
    const { appState, userState } = useSelector((state) => state);

    const {
        last_login: lastLogin,
        new_epayslips_onboarding_seen: onboardingSeen,
        mfa_totp: mfaSetup,
    } = userState;

    const [index, setIndex] = useState(0);

    const mfaRequired = (!mfaSetup && appState.payroll.mandatory_mfa);

    if ((onboardingSeen && !mfaRequired) || appState.ssoEnabled) {
        history.push("/");
    }

    const type = getSequenceType(mfaRequired, lastLogin);
    const Card = sequence[type][index];
    const navigate = (increment) => setIndex(index + increment);

    return (
        <main id="main" role="main">
            <Card navigate={navigate} />
        </main>
    );
};

Welcome.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(Welcome);
