import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import _ from "lodash";
import numeral from "numeral";
import { PayslipCard } from "@dataplan/react-components/dist/components/ui/cards/payslip_card";
import { payslipPropTypes } from 'lib/prop-schemas/payslipsSchema';
import { AccordionList } from 'components/';

import styles from "../PayslipSharedStyles.module.scss";

class PayslipSecondaryCard extends React.Component {

    static propTypes = {
        detailsOpen: PropTypes.string,
        payslip: PropTypes.shape(payslipPropTypes).isRequired,
        setOpenDetails: PropTypes.func,
    };

    static defaultProps = {
        detailsOpen: null,
        setOpenDetails: null,
    };

    /**
     * Gets the list of payslip elements
     *
     * @param {object} elements Key-value list of payslip elements
     * @param {boolean} decimal Convert value to decimal number
     *
     * @return {ReactElement} The list or null if the details are hidden
     */
    getListElements (elements, decimal = false) {
        const rows = [];

        _.forOwn(elements, (value, key) => {
            const shouldDisplay = (_.isNumber(value))
                ? !_.isNull(value)
                : !_.isEmpty(value);

            if (shouldDisplay) {
                rows.push((
                    <div key={key} className={styles.elementLine}>
                        <dt className={styles.listKey}>{key}</dt>
                        <dd className={styles.listValue}>{(decimal) ? numeral(value).format("0,0.00") : value}</dd>
                    </div>
                ));
            }
        });

        if (rows.length === 0) {
            return null;
        }

        return (
            <dl className={styles.listContainer}>
                {rows}
            </dl>
        );
    }

    /**
     * Gets the accordion config
     *
     * @param {string} title Title for the accordion
     * @param {object} elements Key-value list of payslip elements
     * @param {boolean} decimal Decimal checker for dropdown
     *
     * @return {object} The list or null if the details are hidden
     */
    getSecondaryAccordionConfig (title, elements, decimal) {
        const { detailsOpen } = this.props;
        const bodyContent = this.getListElements(elements, decimal);
        const headerStyles = classNames(styles.header, styles.secondary);
        const labelStyles = classNames(styles.label, styles.secondary, {
            [styles.highlighted]: (detailsOpen === title),
        });

        return {
            title,
            headerContent: (
                <div className={headerStyles}>
                    <h3 className={labelStyles}>
                        {title}
                    </h3>
                </div>
            ),
            bodyContent,
        };
    }

    /**
     *  Renders the list for the secondary accordion
     *
     * @return {array} the list array
     */
    renderList = () => {
        const list = [];

        const {
            "NI Number": ni,
            "Payroll Number": payrollRef,
            "Tax Code": taxCode,
            "NI Code": niCode,
        } = this.props.payslip.employee;

        const accordionExtraDetails = [
            {
                title: 'Note',
                value: null,
                decimal: false,
            },
            {
                title: 'Key info',
                value: {
                    "NI Number": ni,
                    "Payroll Number": payrollRef,
                    "Tax Code": taxCode,
                    "NI Code": niCode,
                },
                decimal: false,
            },
            {
                title: `Other details`,
                value: this.props.payslip.extras,
                decimal: false,
            },
            {
                title: 'Year to date',
                value: this.props.payslip.to_date,
                decimal: true,
            },
        ];

        accordionExtraDetails.filter(({ value }) => value)
            .forEach(({ title, value, decimal }) => list.push(this.getSecondaryAccordionConfig(title, value, decimal)));

        return list;
    };

    /**
     * Render component
     *
     * @return {ReactElement} The secondary payslip card
     */
    render () {
        const { setOpenDetails } = this.props;
        const listClasses = classNames(styles.accordionList, styles.borderRadius);

        return (
            <div className={styles.secondaryCard}>
                <PayslipCard>
                    <AccordionList
                        className={listClasses}
                        headerHeight={'55px'}
                        list={this.renderList()}
                        onSwitch={(tab) => setOpenDetails((tab) ? tab.title : null)}
                    />
                </PayslipCard>
            </div>
        );
    }

}

export default PayslipSecondaryCard;

