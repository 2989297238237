import api from "lib/api";

export default class Payroll {

    /**
     * @param {StateAccessor} appState The global app state
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the payroll information
     *
     * @return {Promise} A promise that resolves with the data
     */
    load = () => api.get("/payroll")
        .then((response) => {
            this.appState.setPayroll(response.data);
        });

}
