import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { ButtonMenu } from "@dataplan/react-components/dist/components/ui/floating_menu";
import { Announcements } from 'components/';
import NotificationsButton from "./NotificationsButton.js";

import styles from "./NotificationsMenu.module.scss";

/**
 * Renders the app header notifications button and menu components
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const NotificationsMenu = ({ history }) => {
    const { accentColour, announcements } = useSelector(({ appState }) => appState);
    const maxNotifications = 7;
    const isMobileDevice = window.matchMedia("(max-width: 600px)").matches;
    const showButton = (announcements.length) ? "Show more" : null;

    const content = (announcements.length)
        ? <Announcements announcements={announcements} quantity={maxNotifications} isWhiteOnHover={false} />
        : (
            <div className={styles.notificationContainer}>
                <div className={styles.notificationModal}>
                    No notifications
                </div>
            </div>
        );

    return (
        <ButtonMenu
            emptyToggle={!announcements.length}
            accentColour={accentColour}
            button={(clickHandler, parentRef) => (
                <NotificationsButton
                    onClick={clickHandler}
                    ref={parentRef}
                />
            )}
            className={styles.notificationsMenu}
            footerButtonClick={() => history.push("/notifications")}
            footerText={showButton}
            headerText={"Notifications"}
            showCloseButton
            menuFullscreen={isMobileDevice}
        >
            {content}
        </ButtonMenu>
    );
};

NotificationsMenu.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(NotificationsMenu);
