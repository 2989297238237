import React from 'react';
import PropTypes from "prop-types";
import appState from "state/App";
import userState from "state/User";
import { attachStates } from "utils/ReduxUtils";
import api from "lib/api";
import {
    createErrorToasts,
    refreshUserDetails,
    contrastingColourList,
} from "lib/";
import {
    getMostContrastingColour,
    checkValidity,
} from "@dataplan/react-components/dist/lib";

import {
    DefaultForm,
    ReadOnlyRow,
    TextInputRow,
    PasswordInputRow,
    PrimaryButton,
} from '@dataplan/react-components/dist/components/forms';

import sharedStyles from '../../../shared/SharedStyles.module.scss';

class ChangeMobileNumber extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the change mobile number page
     *
     * @param {object} props Change mobile number page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            currentMobile: this.props.userState.mobileNumber,
            password: '',
            mobile: {
                newMobile: '',
                confirmNewMobile: '',
            },
            hasChanged: false,
            error: {
                isValid: true,
                invalidInputs: [],
            },
        };
    }

    /**
     * Sets the component default state
     *
     * @return {void}
     */
    resetInitialState = () => {
        this.setState({
            currentMobile: this.props.userState.mobileNumber,
            password: '',
            mobile: {
                newMobile: '',
                confirmNewMobile: '',
            },
            hasChanged: false,
        });
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handlePasswordInput = (event) => {
        const password = event.target.value;

        this.setState({
            password,
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     * @param {string} field Which mobile field has changed
     *
     * @return {void}
     */
    handleMobileInput = (event, field) => {
        const mobile = event.target.value;

        this.setState((prevState) => {
            return {
                mobile: {
                    ...prevState.mobile,
                    [field]: mobile,
                },
                hasChanged: true,
            };
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    };

    /**
     * Checks the password field in completed and the mobile is valid
     *
     * @return {void}
     */
    handleValidation = () => {
        const { password, mobile } = this.state;
        const passwordEntered = checkValidity(password, 'passwordEntered');
        const mobileValidation = checkValidity(mobile.newMobile, 'mobile');
        const confirmMobileValidation = checkValidity([mobile.newMobile, mobile.confirmNewMobile], 'newMobile');

        this.setState({
            error: {
                isValid: (passwordEntered.isValid && confirmMobileValidation.isValid),
                invalidInputs: [
                    !passwordEntered.isValid ? 'currentPassword' : null,
                    !mobileValidation.isValid ? 'newMobile' : null,
                    !confirmMobileValidation.isValid ? 'confirmNewMobile' : null,
                ].filter((input) => input !== null),
            },
        });
    };

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        event.preventDefault();
        appState.unblockNavigation();

        const { password, mobile, hasChanged, error } = this.state;

        if (!hasChanged || !error.isValid) {
            return;
        }

        api.patch('/security/mobile_number', {
            password,
            "mobile_number": mobile.newMobile,
            "mobile_number_confirm": mobile.confirmNewMobile,
        }).then(() => {
            appState.addNotification({
                text: "Mobile number successfully updated.",
                type: "success",
                duration: 5,
            });
            refreshUserDetails().then(this.resetInitialState);
        }).catch((apiError) => {
            createErrorToasts(apiError?.response?.data ?? "Failed to update mobile number");
        });
    };

    /**
     * Renders the password field
     *
     * @return {ReactElement} The password input
     */
    renderPasswordField = () => {
        const { invalidInputs } = this.state.error;
        const hasError = (invalidInputs.indexOf('currentPassword') >= 0);
        const errorText = (hasError)
            ? "Password is required to make changes to your account"
            : null;

        return (
            <PasswordInputRow
                name="currentPassword"
                label="Password"
                autoComplete="current-password"
                onChange={this.handlePasswordInput}
                value={this.state.password}
                inputClassName={(hasError) ? sharedStyles.error : ''}
                showPasswordToggle
                errorText={errorText}
            />
        );
    };

    /**
     * Renders a mobile input field
     *
     * @param {string} field Which mobile field to render
     * @param {string} display The textual label to display
     * @param {string} errorMessage The error message to display
     *
     * @return {ReactElement} The mobile input component
     */
    renderMobileField = (field, display, errorMessage) => {
        const { invalidInputs } = this.state.error;
        const hasError = (invalidInputs.indexOf(field) >= 0);
        const errorText = (hasError && errorMessage)
            ? errorMessage
            : null;

        const classList = (hasError) ? sharedStyles.error : '';

        return (
            <TextInputRow
                name={field}
                label={display}
                type="text"
                autoComplete="tel"
                onChange={(event) => this.handleMobileInput(event, field)}
                value={this.state.mobile[field]}
                placeholder={this.state.currentMobile}
                className={classList}
                errorText={errorText}
            />
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { hasChanged, error, currentMobile } = this.state;
        const { accentColour } = this.props.appState;
        const canSubmit = (hasChanged && error.isValid);
        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <ReadOnlyRow label="Current number" value={currentMobile} />
                {this.renderMobileField(
                    "newMobile",
                    "New mobile number",
                    "Please enter a valid phone number",
                )}
                {this.renderMobileField(
                    "confirmNewMobile",
                    "Confirm new mobile number",
                    "Mobile numbers must match",
                )}
                {this.renderPasswordField()}
                <PrimaryButton
                    type="submit"
                    text="save"
                    disabled={!canSubmit}
                    style={(canSubmit) ? buttonStyles : null}
                    className={sharedStyles.button}
                />
            </DefaultForm>
        );
    }

}

export default attachStates([appState, userState], ChangeMobileNumber);
