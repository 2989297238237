import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { PayslipCard } from "@dataplan/react-components/dist/components/ui/cards/payslip_card";
import stylesActionMenu from "@dataplan/react-components/dist/components/ui/cards/actions/CardActionMenu.module.scss";
import { payslipPropTypes } from 'lib/prop-schemas/payslipsSchema';
import { PayslipHeader } from '../';
import PayslipBody from './PayslipBody.js';

const { actionFillHover } = stylesActionMenu;

/**
 * Renders the payslip primary card
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The payslip primary card
 */
const PayslipPrimaryCard = ({
    canNavigate,
    detailsOpen,
    navigatePayslips,
    payslip,
    payslipLoaded,
    setOpenDetails,
    previousPayslip,
}) => {
    const { paydate } = payslip;

    const wrapperStyles = {
        actionFillHover,
    };

    return (
        <PayslipCard wrapperStyles={wrapperStyles}>
            <PayslipHeader
                canNavigate={canNavigate}
                navigatePayslips={navigatePayslips}
                paydate={paydate}
                shouldWobble={payslipLoaded}
            />
            <PayslipBody
                detailsOpen={detailsOpen}
                onSwitch={(tab) => setOpenDetails(tab?.title)}
                payslip={payslip}
                payslipLoaded={payslipLoaded}
                previousPayslip={previousPayslip}
            />
        </PayslipCard>
    );
};

PayslipPrimaryCard.propTypes = {
    canNavigate: PropTypes.object,
    detailsOpen: PropTypes.string,
    navigatePayslips: PropTypes.func,
    payslip: PropTypes.shape(payslipPropTypes).isRequired,
    payslipLoaded: PropTypes.bool,
    previousPayslip: PropTypes.object,
    setOpenDetails: PropTypes.func,
};

PayslipPrimaryCard.defaultProps = {
    canNavigate: { left: false, right: false },
    detailsOpen: null,
    navigatePayslips: null,
    payslipLoaded: false,
    previousPayslip: {},
    setOpenDetails: _.noop,
};

export default PayslipPrimaryCard;
