import React from 'react';
import PropTypes from 'prop-types';

import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { WarningIcon } from "@dataplan/react-components/dist/components/icons";

import styles from './ErrorFrame.module.scss';

class ErrorFrame extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.any,
        buttons: PropTypes.element,
    };

    static defaultProps = {
        title: '',
        content: '',
        buttons: null,
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { title, content, buttons } = this.props;

        return (
            <AnimationContainer
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <div className={styles.contentWrapper}>
                    <div className={styles.errorCard}>
                        <div className={styles.cardHeader}>
                            <div className={styles.cardHeaderInner}>
                                <WarningIcon className={styles.icon} />
                                {title}
                            </div>
                        </div>
                        <div className={styles.cardContent}>
                            <div className={styles.cardText}>{content}</div>
                            <div className={styles.buttonsContainer}>
                                {buttons}
                            </div>
                        </div>
                    </div>
                </div>
            </AnimationContainer>
        );
    }

}

export default ErrorFrame;
