import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { payslipPropTypes } from 'lib/prop-schemas/payslipsSchema';
import { PayslipCard } from "@dataplan/react-components/dist/components/ui/cards/payslip_card";
import CardFooter from "@dataplan/react-components/dist/components/ui/cards/CardFooter";
import stylesActionMenu from "@dataplan/react-components/dist/components/ui/cards/actions/CardActionMenu.module.scss";
import { PayslipAccordionHeader, PayslipHeader } from '../';

import styles from "../PayslipSharedStyles.module.scss";

const { actionFillHover } = stylesActionMenu;

/**
 * Renders the payslip components and animation
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const PayslipStatic = ({ history, payslip }) => {
    const { accentColour } = useSelector(({ appState }) => appState);
    const { id, gross, net, paydate, total_deductions: totalDeductions } = payslip;
    const handleCardClick = () => history.push(`/payslip/${id}`);

    return (
        <PayslipCard
            clickHandler={handleCardClick}
            innerClasses={styles.staticWrapper}
            wrapperStyles={{ actionFillHover }}
        >
            <PayslipHeader paydate={paydate} />
            <PayslipAccordionHeader className={styles.static} title="Gross" total={gross} />
            <PayslipAccordionHeader className={styles.static} title="Deductions" total={totalDeductions} />
            <PayslipAccordionHeader className={styles.net} title="Net Pay" total={net} />
            <CardFooter
                actions={[{
                    label: "More Details",
                    action: handleCardClick,
                    backgroundColor: accentColour,
                }]}
            />
        </PayslipCard>
    );
};

PayslipStatic.propTypes = {
    history: PropTypes.object.isRequired,
    payslip: PropTypes.shape(payslipPropTypes),
};

PayslipStatic.defaultProps = {
    payslip: [],
};

export default withRouter(PayslipStatic);
