import React from "react";
import PropTypes from "prop-types";
import { Accordion } from 'components/';

class AccordionList extends React.Component {

    static propTypes = {
        accordionClass: PropTypes.string,
        arrowIcon: PropTypes.bool,
        className: PropTypes.string,
        headerHeight: PropTypes.string,
        list: PropTypes.array,
        onSwitch: PropTypes.func,
        openUpwards: PropTypes.bool,
        title: PropTypes.object,
    };

    static defaultProps = {
        accordionClass: null,
        arrowIcon: true,
        className: null,
        headerHeight: null,
        list: null,
        onSwitch: null,
        openUpwards: false,
        title: null,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.listRef = React.createRef();

        this.state = {
            openTab: null,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        window.addEventListener('mousedown', this.handleWindowClick);
        window.addEventListener('touchstart', this.handleWindowClick);
    };

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        window.removeEventListener('mousedown', this.handleWindowClick);
        window.removeEventListener('touchstart', this.handleWindowClick);
    };

    /**
     * Handles user clicking page
     *
     * @param {ClickEvent} event The event
     *
     * @return {void}
     */
    handleWindowClick = (event) => {
        const { openTab } = this.state;
        const list = this.listRef.current;
        const root = document.getElementById('root');

        // Closes all tabs if user clicks away (unless target is not in root i.e. a toast)
        if (openTab && list && !list.contains(event.target) && root.contains(event.target)) {
            this.closeTabs();
        }
    };

    /**
     * Opens new tab and closes previous tab
     *
     * @param {object} tab new tab
     */
    switchTab = (tab) => {
        const { onSwitch } = this.props;
        const { openTab } = this.state;

        this.setState({
            openTab: tab,
        }, () => {
            if (openTab && openTab !== tab) {
                openTab.ref.toggleOpen(false);
            }
            if (tab) {
                tab.ref.toggleOpen(true);
            }
            if (onSwitch) {
                onSwitch(tab);
            }
        });
    };

    /**
     * Closes all tabs
     */
    closeTabs = () => {
        this.switchTab(null);
    };

    /**
     * Renders the accordion list
     *
     * @return {ReactElement} The accordion list
     */
    render () {
        const {
            accordionClass,
            arrowIcon,
            className,
            headerHeight,
            list,
            openUpwards,
            title,
        } = this.props;

        const content = list.map((item) => {
            const {
                headerContent,
                bodyContent,
            } = item;

            return (
                <Accordion
                    arrowIcon={arrowIcon}
                    className={accordionClass}
                    headerContent={headerContent}
                    headerHeight={headerHeight}
                    key={item.title}
                    openUpwards={openUpwards}
                    switchTab={this.switchTab}
                    title={item.title}
                >
                    {bodyContent}
                </Accordion>
            );
        });

        if (!content.some((item) => item)) {
            return null;
        }

        return (
            <>
                {title}
                <div className={className} ref={this.listRef} >
                    {content}
                </div>
            </>
        );
    }

}

export default AccordionList;
