import appState from "./state/App";
import userState from './state/User';

const stateManagers = [
    appState,
    userState,
];

const stateReducers = {
    [userState.stateKeyName]: userState.setStateReducer,
    [appState.stateKeyName]: appState.setStateReducer,
};

export { stateManagers, stateReducers };
