import React from 'react';
import PropTypes from 'prop-types';
import api from 'lib/api';
import userState from "state/User";
import { createErrorToasts } from "lib/";
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import { AuthenticatorCodeDropdown } from "./index";
import styles from './SetupSharedStyles.module.scss';

class QRcode extends React.Component {

    static propTypes = {
        visible: PropTypes.bool,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
    };

    static defaultProps = {
        visible: true,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.state = {
            qrImage: "",
            code: "",
        };
    }

    /**
     * Called when the component mounts
     */
    componentDidMount = () => {
        const { visible } = this.props;

        if (visible && !this.props.userState.mfa_totp) {
            this.handleAPI();
        }
    };

    /**
     * Updates the component based on whether the draw is visible
     *
     * @param {object} prevProps The prev props
     */
    componentDidUpdate = (prevProps) => {
        const { visible } = this.props;
        const { qrImage } = this.state;
        const hasOpened = (visible && !prevProps.visible);

        if (!qrImage && hasOpened && !this.props.userState.mfa_totp) {
            this.handleAPI();
        }
    };

    /**
     * Handles the API call
     *
     * @return {void}
     */
    handleAPI () {
        // eslint-disable-next-line camelcase
        api.get("/auth/mfa/totp/setup?", { params: { include_qr: 1 }})
            .then(({ data }) => {
                this.setState({
                    // eslint-disable-next-line camelcase
                    qrImage: data.qr_image,
                    code: this.getFormatCode(data.key),
                });
            }).catch((apiError) => {
                createErrorToasts(apiError.response.data);
            });
    }

    /**
     * Formats the code
     *
     * @param {string} code the 16 digit authenticator code
     *
     * @return {string} The formatted code to display
     */
    getFormatCode = (code) => {
        const formattedCode = code.match(/.{1,3}/g);

        return formattedCode.join("-");
    };

    /**
     * Renders the QR code
     *
     * @return {ReactElement} QR code
     */
    renderQRCode () {
        const { qrImage } = this.state;

        if (!qrImage) {
            return (
                <div className={styles.QRcontainer}>
                    <LoadingSpinner label="Loading" />
                </div>
            );
        }

        return (
            <div className={styles.QRcontainer}>
                <img
                    src={`data:image/png;base64, ${qrImage}`}
                    alt="The QR code to setup multi-factor authentication"
                />
            </div>
        );
    }

    /**
     * Renders the dropdown
     *
     * @return {ReactElement} 16 digit dropdown
     */
    renderDropdown () {
        return (
            <AuthenticatorCodeDropdown code={this.state.code} />
        );
    }

    /**
     * Renders the QR component once loaded
     *
     * @return {ReactElement} QR code
     */
    render () {
        return (
            <>
                {this.renderQRCode()}
                {this.renderDropdown()}
            </>
        );
    }

}

export default userState.attachState(QRcode);
