import React from 'react';
import PropTypes from 'prop-types';
import { ToggleSwitchRow } from '@dataplan/react-components/dist/components/forms';
import { Tooltip } from "@dataplan/react-components/dist/components";

import appState from "state/App";
import userState from "state/User";
import { attachStates } from "utils/ReduxUtils";
import { TOTPSetupDrawer } from "components/";

class MultiFactorAuthentication extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
    };

    /**
     * Instance of MFA page
     *
     * @param {object} props MFA props
     */
    constructor (props) {
        super(props);

        this.nativeDrawer = React.createRef();
        this.disableDrawer = React.createRef();

        this.state = {
            reset: false,
            mfaValue: this.props.userState.mfa_totp,
            snackbarOpen: false,
        };
    }

    /**
     * Called if the component is updated
     *
     * @param {object} prevProps The components previous props
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        if (this.props.userState.mfa_totp !== prevProps.userState.mfa_totp) {
            this.setMfaValue();
            this.handleReset(true);
        }
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        if (this.state.snackbarOpen) {
            const { currentSnackbarId } = this.props.appState;

            appState.closeSnackBar(currentSnackbarId);
        }
    };

    /**
     * Sets the value of the toggle switch
     *
     * @return {void}
     */
    setMfaValue () {
        this.setState({ mfaValue: this.props.userState.mfa_totp });
    }

    /**
     * Renders the toggle switch
     *
     * @return {ReactElement} The toggle switch component
     */
    renderToggleSwitch () {
        const { accentColour, payroll } = this.props.appState;

        const toggle = (
            <ToggleSwitchRow
                accentColour={accentColour}
                handleChange={(value) => this.handleMFAToggle(value)}
                label={"Multi-Factor Authentication"}
                key={"multi_factor_authentication"}
                initialValue={this.state.mfaValue}
                disabled={payroll.mandatory_mfa}
                reset={this.state.reset}
            />
        );

        if (payroll.mandatory_mfa) {
            return (
                <Tooltip text="Mandatory MFA Policy Enabled" position="left">
                    {toggle}
                </Tooltip>
            );
        }

        return toggle;
    }

    /**
     * Called when a value of the toggle switch is changed
     *
     * @param {bool} value the value of the toggle
     *
     * @return {void}
     */
    handleMFAToggle = (value) => {
        const { mfa_totp } = this.props.userState; // eslint-disable-line camelcase

        if (value && !mfa_totp) { // eslint-disable-line camelcase
            this.toggleDrawer();
        }

        if (mfa_totp) { // eslint-disable-line camelcase
            this.renderSnackBar();
        }
    };

    /**
     * Toggles the setup drawer
     *
     * @return {void}
     */
    toggleDrawer = () => {
        const drawer = this.nativeDrawer.current;

        if (drawer) {
            drawer.toggleDrawer();
            this.handleReset(drawer.state.visible);
        }
    };

    /**
     * Toggles the disable mfa drawer
     *
     * @return {void}
     */
    toggleDisableDrawer = () => {
        const disableDrawer = this.disableDrawer.current;

        if (disableDrawer) {
            disableDrawer.toggleDrawer();
            this.handleReset(disableDrawer.state.visible);
        }
    };

    /**
     * Renders the snackbar
     *
     * @return {void}
     */
    renderSnackBar () {
        appState.addSnackBar({
            type: 'warn',
            message: "Are you sure you want to turn off authentication?",
            onConfirm: this.toggleDisableDrawer,
            onCancel: this.handleSnackClose,
            showCancelButton: false,
        });
        this.setState({ snackbarOpen: true, reset: false });

    }

    /**
     * Handle snackbar cancellation and restores MFA toggle state
     *
     * @return {void} Snackbar closes
     */
    handleSnackClose = () => {
        const { currentSnackbarId } = this.props.appState;

        appState.closeSnackBar(currentSnackbarId);
        this.setState({ snackbarOpen: true, reset: false });
    };

    /**
     * Handles the restting of the toggle
     *
     * @param {bool} value whether the toggle should be reset
     *
     * @return {void}
     */
    handleReset = (value) => {
        this.setState({
            reset: value,
        });
    };

    /**
     * Renders the MFA tab
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <>
                {this.renderToggleSwitch()}
                <TOTPSetupDrawer
                    ref={this.nativeDrawer}
                    pageName={"Setup Authentication"}
                    reset={this.handleReset}
                />
                <TOTPSetupDrawer
                    ref={this.disableDrawer}
                    pageName={"Authorize changes"}
                    reset={this.handleReset}
                    disable
                />
            </>
        );
    }

}

export default attachStates([appState, userState], MultiFactorAuthentication);
