import PropTypes from 'prop-types';

const defaultDocTypes = {
    id: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    datetime_added: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    type: PropTypes.string.isRequired,
};

const documentPropTypes = PropTypes.shape({
    eP60: PropTypes.arrayOf(PropTypes.shape(defaultDocTypes)),
    P11d: PropTypes.arrayOf(PropTypes.shape(defaultDocTypes)),
    personal: PropTypes.arrayOf(PropTypes.shape(defaultDocTypes)),
    personalcompany: PropTypes.arrayOf(PropTypes.shape(defaultDocTypes)),
    company: PropTypes.arrayOf(PropTypes.shape(defaultDocTypes)),
    pension: PropTypes.arrayOf(PropTypes.shape(defaultDocTypes)),
});

const documentDefaultProps = {
    eP60: [],
    P11d: [],
    personal: [],
    personalcompany: [],
    company: [],
    pension: [],
    "drawing_board_documents": [],
};

export { documentPropTypes, documentDefaultProps, defaultDocTypes };
