import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { UserAvatar } from "@dataplan/react-components/dist/components/ui/user_avatar";
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";
import appStateMethods from "state/App";
import { contrastingColourList } from 'lib/';
import AppHeaderButton from "./AppHeaderButton.js";

import styles from './ProfileButton.module.scss';

/**
 * Stores the position of the profile button
 *
 * This is needed for the login form animation which
 * moves a placeholder avatar over this position.
 *
 * @param {Element} button The profile button
 */
const setProfileButtonPosition = (button) => {
    const buttonRect = button.getBoundingClientRect();
    const headerHeight = button.parentElement.getBoundingClientRect().height;

    appStateMethods.setProfileMenuPosition(
        (buttonRect.top + headerHeight - 50),
        buttonRect.left,
    );
};

/**
 * Renders the app header profile button component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const ProfileButton = ({ className, forwardedRef, onClick }) => {
    const { appState, userState } = useSelector((state) => state);
    const { brandColour } = appState;
    const { forename, surname } = userState;
    const buttonClasses = classNames(styles.button, className);

    useEffect(() => {
        const button = forwardedRef.current;

        if (button) {
            setProfileButtonPosition(button);
        }
    }, [forwardedRef]);

    return (
        <AppHeaderButton
            aria-label="Open Profile menu"
            className={buttonClasses}
            onClick={onClick}
            ref={forwardedRef}
        >
            <Tooltip text="Account" position="bottom">
                <UserAvatar
                    forename={forename}
                    surname={surname}
                    color={brandColour}
                    textColour={getMostContrastingColour(brandColour, contrastingColourList)}
                    size={25}
                />
            </Tooltip>
        </AppHeaderButton>
    );
};

ProfileButton.propTypes = {
    className: PropTypes.string,
    forwardedRef: PropTypes.any.isRequired,
    onClick: PropTypes.func,
};

ProfileButton.defaultProps = {
    className: null,
    onClick: _.noop,
};

export default React.forwardRef((props, ref) => <ProfileButton forwardedRef={ref} {...props} />);
