import React from 'react';
import PropTypes from 'prop-types';
import appState from 'state/App';
import { withRouter, Prompt } from 'react-router-dom';
import { navBlockDefaultProps } from 'lib/prop-schemas/navBlockSchema';

class RouterNavigationBlocking extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.state = {
            navBlock: navBlockDefaultProps,
        };
    }

    /**
     * Handles updating the component
     *
     * @param {object} prevProps The previous props
     * @param {object} prevState The previous state
     */
    componentDidUpdate (prevProps, prevState) {
        const { showSnackBar } = this.state.navBlock;

        if (showSnackBar && (prevState.navBlock.showSnackBar !== showSnackBar)) {
            this.renderSnackBar();
        }
    }

    /**
     * Sets the initial state of the component
     *
     * @return {void} Modifies the state
     */
    setInitialState () {
        this.setState({
            navBlock: navBlockDefaultProps,
        });
    }

    /**
     * Called when the Prompt is triggered, replaces default browser modal
     * https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
     *
     * @param {object} nextLocation The location object of the navigation from react router
     *
     * @return {boolean} Expected return for the prompt
     */
    handleBlockedNavigation = (nextLocation) => {
        const { confirmedNavigation } = this.state.navBlock;

        if (!confirmedNavigation) {
            this.setState({
                navBlock: {
                    showSnackBar: true,
                    nextLocation,
                },
            });

            return false;
        }

        return true;
    };

    /**
     * Handle user confirmation of navigating away
     *
     * @return {void}
     */
    handleConfirm = () => {
        const { nextLocation } = this.state.navBlock;
        const { currentSnackbarId } = this.props.appState;

        appState.unblockNavigation();

        this.setState({
            navBlock: {
                confirmedNavigation: true,
                showSnackBar: false,
            },
        }, () => {
            if (nextLocation) {
                this.props.history.push(nextLocation.pathname);
            }

            this.setInitialState();
        });

        appState.closeSnackBar(currentSnackbarId);
    };

    /**
     * Handle user cancelling navigation
     */
    handleCancel = () => {
        const { currentSnackbarId } = this.props.appState;

        this.setState({
            navBlock: {
                showSnackBar: false,
            },
        });

        appState.closeSnackBar(currentSnackbarId);
    };

    /**
     * Render the snack bar to prompt the user
     */
    renderSnackBar () {
        const { navigationBlockPrompt } = this.props.appState;
        const { showSnackBar } = this.state.navBlock;

        if (!showSnackBar) {
            return;
        }

        appState.addSnackBar({
            type: 'warn',
            heading: navigationBlockPrompt.heading,
            message: navigationBlockPrompt.message,
            onConfirm: () => this.handleConfirm(),
            confirmText: "Leave page",
            onCancel: () => this.handleCancel(),
            cancelText: "Stay on page",
            showCancelButton: false,
        });
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { navigationBlocked } = this.props.appState;

        return (
            <Prompt
                when={navigationBlocked}
                message={this.handleBlockedNavigation}
            />
        );
    }

}

export default withRouter(appState.attachState(RouterNavigationBlocking));
