import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import styles from "./FocusHandler.module.scss";

/**
 * Handles resetting focus when the user navigates to a different page
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const FocusHandler = ({ location, staticContext, ...props }) => {
    const { pathname } = location;
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [pathname]);

    return (
        <div
            className={styles.container}
            ref={ref}
            tabIndex="-1"
            {...props}
        />
    );
};

FocusHandler.propTypes = {
    location: PropTypes.object.isRequired,
    staticContext: PropTypes.object,
};

FocusHandler.defaultProps = {
    staticContext: null,
};

export default withRouter(FocusHandler);
