import PropTypes from "prop-types";

const formPropTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

const formsPropTypes = PropTypes.arrayOf(PropTypes.shape(formPropTypes));

const formDefaultProps = [];

export {
    formPropTypes,
    formsPropTypes,
    formDefaultProps,
};
