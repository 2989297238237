import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./AccordionSection.module.scss";

class AccordionTextCard extends Component {

    static propTypes = {
        text: PropTypes.any,
    };

    static defaultProps = {
        text: null,
    };

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        return (
            <div className={styles.card}>
                <div className={styles.text}>
                    {this.props.text}
                </div>
            </div>
        );
    }

}

export default AccordionTextCard;
