import appState from 'state/App';
import _ from 'lodash';

/**
 * Adds the notification to the appState
 *
 * @param {string} error The error message to display
 *
 * @return {void}
 */
const addNotification = (error) => {
    appState.addNotification({
        text: error,
        type: "error",
        duration: 5,
    });
};

/**
 * Renders a toast error if the API returns an error
 *
 * @param {object} error The error returned from the API
 *
 * @return {void}
 */
const createErrorToasts = (error) => {
    if (!error) {
        return;
    }

    if (error.messages) {
        _.forEach(error.messages, (fieldError) => {
            _.forEach(fieldError, (validationError) => {
                addNotification(validationError);
            });
        });
    } else if (error.message) {
        addNotification(error.message);
    }
};

export default createErrorToasts;
