import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import styles from "./AccordionSection.module.scss";

class AccordionVideoCard extends Component {

    static propTypes = {
        video: PropTypes.any,
        title: PropTypes.string.isRequired,
    };

    static defaultProps = {
        video: null,
    };

    /**
     * Creates an instance of the component
     *
     * @param {object} props Props passed from parent component
     */
    constructor (props) {
        super(props);
        const dpr = window.devicePixelRatio || 1;
        const width = window.screen.width * dpr;
        this.videoRef = React.createRef();

        this.resizeThrottle = _.throttle(this.handleResize, 100);

        this.state = {
            desktopWidth: (width >= 1080),
            mobileWidth: (width <= 600),
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        window.addEventListener("resize", this.resizeThrottle);
    };

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        window.removeEventListener("resize", this.resizeThrottle);
    };

    /**
     * Handles resize
     */
    handleResize = () => {
        const width = window.screen.width;

        this.setState({
            desktopWidth: (width >= 1080),
            mobileWidth: (width <= 600),
        });
    };

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        const { desktopWidth, mobileWidth } = this.state;
        const desktopOrTablet = desktopWidth ? "750" : "600";
        const width = mobileWidth ? "280" : desktopOrTablet;

        return (
            <div className={styles.card}>
                <div className={styles.videoContainer}>
                    <video id={this.props.title} width={width} controls>
                        <source ref={this.videoRef} src={this.props.video} type="video/mp4" />
                        <track kind="captions" />
                    </video>
                </div>
            </div>
        );
    }

}

export default AccordionVideoCard;
