import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
    TextInputRow,
    NumberInputRow,
    TextAreaRow,
    SelectInputRow,
    DateTimeRow,
} from '@dataplan/react-components/dist/components/forms';

import styles from './ChangeFormComponents.module.scss';

class ChangeFormComponents extends React.Component {

    static propTypes = {
        questions: PropTypes.object.isRequired,
        questionOrder: PropTypes.array.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        handleDateChange: PropTypes.func.isRequired,
    };

    /**
     * Get the help text for an input depending on what is set on the question
     *
     * @param {object} question The question we are getting the help text for
     *
     * @return {?string} The help text
     */
    getHelpText (question) {
        return question.short_help || question.long_help || null;
    }

    /**
     * Get the error text for an input based on it's validity
     *
     * @param {object} question The question we are getting the error text for
     *
     * @return {?string} The error text
     */
    getErrorText (question) {
        if (question.hasChanged && !question.isValid) {
            return "This field is required";
        }

        return null;
    }

    /**
     * Render a standard text input for textline_big field
     *
     * @param {object} question The question the input is for
     *
     * @return {ReactElement} The input
     */
    renderTextLineBig = (question) => {
        return this.renderTextLine(question, true);
    };

    /**
     * Render a standard text input for textline_small field
     *
     * @param {object} question The question the input is for
     *
     * @return {ReactElement} The input
     */
    renderTextLineSmall = (question) => {
        return this.renderTextLine(question);
    };

    /**
     * Render a standard text input
     *
     * @param {object} question The question the input is for
     * @param {boolean} isBig If the input is big or small
     *
     * @return {ReactElement} The input
     */
    renderTextLine = (question, isBig = false) => {
        const { id, name, display_name: displayName, required, value } = question;

        return (
            <TextInputRow
                key={id}
                name={name}
                label={displayName}
                helpText={this.getHelpText(question)}
                errorText={this.getErrorText(question)}
                required={required}
                showOptionalText
                onChange={(event) => this.props.handleInputChange(event, id)}
                value={value}
                inputClassName={(isBig) ? null : styles.textInputSmall}
            />
        );
    };

    /**
     * Render a textarea input for textbox field
     *
     * @param {object} question The question the input is for
     *
     * @return {ReactElement} The input
     */
    renderTextBox = (question) => {
        const { id, name, display_name: displayName, required, value } = question;

        return (
            <TextAreaRow
                key={id}
                name={name}
                label={displayName}
                helpText={this.getHelpText(question)}
                errorText={this.getErrorText(question)}
                required={required}
                showOptionalText
                onChange={(event) => this.props.handleInputChange(event, id)}
                value={value}
                inputClassName={styles.textArea}
            />
        );
    };

    /**
     * Render a select input for dropdown field
     *
     * @param {object} question The question the input is for
     *
     * @return {ReactElement} The input
     */
    renderDropDown = (question) => {
        const { id, name, display_name: displayName, required, options, value } = question;
        const selectValues = options.map((option) => {
            return <option value={option} key={option}>{option}</option>;
        });

        return (
            <SelectInputRow
                key={id}
                name={`${id}-${name}`}
                label={displayName}
                helpText={this.getHelpText(question)}
                errorText={this.getErrorText(question)}
                placeholder="Please select"
                required={required}
                showOptionalText
                onChange={(event) => this.props.handleInputChange(event, id)}
                value={value}
                inputClassName={styles.selectInput}
            >
                {selectValues}
            </SelectInputRow>
        );
    };

    /**
     * Render a date input for datebox & dateboxaction field
     *
     * @param {object} question The question the input is for
     *
     * @return {ReactElement} The input
     */
    renderDateBox = (question) => {
        const { id, name, display_name: displayName, required, value } = question;

        return (
            <DateTimeRow
                key={id}
                name={name}
                label={displayName}
                helpText={this.getHelpText(question)}
                errorText={this.getErrorText(question)}
                required={required}
                showOptionalText
                todayButton={"Today"}
                onChange={(date) => this.props.handleDateChange(date, id)}
                selected={value || null}
                inputClassName={styles.dateInput}
                yearDropdownItemNumber={10}
            />
        );
    };

    /**
     * Render a number input for number field
     *
     * @param {object} question The question the input is for
     *
     * @return {ReactElement} The input
     */
    renderNumber = (question) => {
        const { id, name, display_name: displayName, required, value } = question;

        return (
            <NumberInputRow
                key={id}
                name={name}
                label={displayName}
                helpText={this.getHelpText(question)}
                errorText={this.getErrorText(question)}
                required={required}
                showOptionalText
                onChange={(event) => this.props.handleInputChange(event, id)}
                value={value}
                inputClassName={styles.textInputSmall}
            />
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { questions, questionOrder } = this.props;
        const componentMap = {
            textline_small: this.renderTextLineSmall, // eslint-disable-line camelcase
            textline_big: this.renderTextLineBig, // eslint-disable-line camelcase
            textbox: this.renderTextBox,
            dropdown: this.renderDropDown,
            datebox: this.renderDateBox,
            dateboxaction: this.renderDateBox,
            number: this.renderNumber,
        };

        return _.map(questionOrder, (order) => {
            let question = questions[order];
            return componentMap[question.field_type](question);
        });
    }

}

export default ChangeFormComponents;
