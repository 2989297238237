import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";
import AppHeaderButton from "./AppHeaderButton.js";
import AppIcon from 'components/assets/icon/AppIcon.js';

import styles from "./AppButton.module.scss";

/**
 * Renders the app header app button component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const AppButton = ({ className, history }) => {
    const buttonClasses = classNames(styles.appButton, className);

    return (
        <AppHeaderButton
            aria-label="Go to ePayslips mobile app page"
            className={buttonClasses}
            onClick={() => history.push("/app")}
        >
            <Tooltip text="ePayslips mobile app" position="bottom">
                <AppIcon height="25px" width="25px" />
            </Tooltip>
        </AppHeaderButton>
    );
};

AppButton.propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
};

AppButton.defaultProps = {
    className: null,
};

export default withRouter(AppButton);
