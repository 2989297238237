import PropTypes from 'prop-types';

const appSettingPropTypes = {
    payslip_privacy: PropTypes.bool, // eslint-disable-line camelcase
};

const appSettingDefaultProps = {
    payslip_privacy: true, // eslint-disable-line camelcase
};

const appSettingsPropTypes = PropTypes.shape(appSettingPropTypes);

export { appSettingsPropTypes, appSettingDefaultProps };
