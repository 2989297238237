import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class Toast extends React.Component {

    static propTypes = {
        children: PropTypes.any,
    };

    static defaultProps = {
        children: null,
    };

    /**
     * Renders the toast areas to the DOM
     *
     * @return {ReactElement} The toast areas
     */
    render () {
        return ReactDOM.createPortal(
            this.props.children,
            document.body,
        );
    }

}
