import api from "../api";
import moment from 'moment';
import handleFile from './handleFile';

export default class DrawingBoardDocument {

    /**
     * Constructor class for handling drawing board documents
     *
     * @param {object} file The drawing board file meta
     */
    constructor (file) {
        this.id = file.id;
        this.type = file.type;
        this.fileName = file.filename;

        this.config = {
            fileDateFormat: 'YYYY-MM-DD',
            dateDisplayFormat: 'DD-MM-YYYY',
            newCondition: {
                unitOfTime: 'day',
                time: 45,
            },
        };

        this.dateTime = file.datetime_added;
    }

    /**
     * Helper function to download a given file. Triggers a download event in IE or opens the
     * file in a new tab in other browsers
     *
     * @return {void}
     */
    download = () => {
        api.get(`/drawing_board/${this.id}/pdf`, {
            responseType: 'arraybuffer',
        })
            .then((response) => {
                const payslipPdf = new Blob([response.data], { type: 'application/pdf' });

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(payslipPdf, this.fileName);
                } else {
                    const url = window.URL.createObjectURL(payslipPdf);

                    handleFile(url, {
                        download: this.fileName,
                    });
                }
            });
    };

    /**
     * Setter for the date time added for a given file and parses it to
     * month day (IE: APR 19). The file format and date display format
     * can be altered by changing the config
     *
     * @param {string} date The date the file was added
     *
     * @return {void} Write only
     */
    set dateTime (date) {
        const { fileDateFormat } = this.config;

        this.datetimeAdded = moment(date, fileDateFormat);
    }

    /**
     * Getter for the dateTimeAdded
     *
     * @return {string} Returns the parsed date
     */
    get dateTime () {
        const { dateDisplayFormat } = this.config;

        return this.datetimeAdded.format(dateDisplayFormat);
    }

}
