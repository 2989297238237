import React from 'react';
import PropTypes from "prop-types";
import { doFullLogout } from '../../lib/clearSessionData';
import { withRouter } from 'react-router-dom';
import appState from "state/App";
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";

import styles from './Logout.module.scss';

class Logout extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Called after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const { redirectUrl } = this.props.appState;
        const isExternalRedirect = this.isExternalRedirect(redirectUrl);

        doFullLogout(isExternalRedirect && redirectUrl);
    }

    /**
     * Checks if the given url is a valid http/https url
     *
     * @param {string} url The url
     *
     * @return {Boolean} If the url is valid
     */
    isExternalRedirect = (url) => {
        return (url && (url.startsWith("https://") || url.startsWith("http://")));
    };

    /**
     * Renders loading spinner
     *
     * @return {null} Always null
     */
    render () {
        return (
            <LoadingSpinner className={styles.loadingSpinner} label="Redirecting" />
        );
    }

}

export default withRouter(appState.attachState(Logout));
