import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import appState from "state/App";
import axios from 'axios';
import api from 'lib/api';
import _ from 'lodash';
import { createErrorToasts, contrastingColourList } from "lib/";
import {
    getMostContrastingColour,
    checkValidity,
} from "@dataplan/react-components/dist/lib";

import {
    DefaultForm,
    TextInputRow,
    PasswordInputRow,
    PrimaryButton,
} from '@dataplan/react-components/dist/components/forms';

import styles from './ChangeSecurityQuestions.module.scss';
import sharedStyles from '../../../shared/SharedStyles.module.scss';

class ChangeSecurityQuestions extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the change security questions page
     *
     * @param {object} props Change security questions page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            password: '',
            securityQuestions: [
                {
                    "number": 1,
                    "question": "",
                    "answer": "",
                },
                {
                    "number": 2,
                    "question": "",
                    "answer": "",
                },
            ],
            hasChanged: false,
            error: {
                isValid: true,
                invalidInputs: [],
            },
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.getCurrentQuestions();
    }

    /**
     * Sets the component default state
     *
     * @return {void}
     */
    resetInitialState = () => {
        this.setState({
            password: '',
            securityQuestions: [
                {
                    "number": 1,
                    "question": "",
                    "answer": "",
                },
                {
                    "number": 2,
                    "question": "",
                    "answer": "",
                },
            ],
            hasChanged: false,
        }, () => {
            this.getCurrentQuestions();
        });
    };

    /**
     * Gets the users current questions and answers
     *
     * @return {void}
     */
    getCurrentQuestions = () => {
        api.get('/security/questions')
            .then((response) => {
                this.setState({
                    securityQuestions: response.data.map((answer) => {
                        return {
                            id: answer.id,
                            number: answer.number,
                            question: answer.question,
                            answer: answer.answer,
                        };
                    }),
                });
            });
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handlePasswordInput = (event) => {
        const password = event.target.value;

        this.setState({
            password,
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     * @param {string} type Which security question field has changed
     * @param {number} number The security question number
     *
     * @return {void}
     */
    handleQuestionAnswerInput = (event, type, number) => {
        const value = event.target.value;

        this.setState((prevState) => {
            let change = _.find(prevState.securityQuestions, (question) => question.number === number);
            change[type] = value;

            return {
                securityQuestions: _.uniq([
                    ...prevState.securityQuestions,
                    change,
                ]),
                hasChanged: true,
            };
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    };

    /**
     * Checks the password field in completed and the question/answers are valid
     *
     * @return {void}
     */
    handleValidation = () => {
        const { password, securityQuestions } = this.state;
        const passwordEntered = checkValidity(password, 'passwordEntered');
        const questionOne = checkValidity(securityQuestions[0], 'securityQuestionAnswer');
        const questionTwo = checkValidity(securityQuestions[1], 'securityQuestionAnswer');

        this.setState({
            error: {
                isValid: _.every([passwordEntered, questionOne, questionTwo], ['isValid', true]),
                invalidInputs: [
                    !passwordEntered.isValid ? 'currentPassword' : null,
                    !questionOne.isValid ? 1 : null,
                    !questionTwo.isValid ? 2 : null,
                ].filter((input) => input !== null),
            },
        });
    };

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        appState.unblockNavigation();
        event.preventDefault();

        const { password, securityQuestions, hasChanged, error } = this.state;

        if (!hasChanged || !error.isValid) {
            return;
        }

        const requests = _.map(securityQuestions, (question) => {
            return api.patch(`/security/questions/${question.number}`, {
                password,
                question: question.question,
                answer: question.answer,
            });
        });

        axios.all(requests)
            .then(() => {
                appState.addNotification({
                    text: "Security questions successfully saved.",
                    type: "success",
                    duration: 5,
                });
                this.resetInitialState();
            })
            .catch((responseErrors) => {
                createErrorToasts(responseErrors.response.data);
            });
    };

    /**
     * Renders the password field
     *
     * @return {ReactElement} The password input
     */
    renderPasswordField = () => {
        const { invalidInputs } = this.state.error;
        const hasError = (invalidInputs.indexOf('currentPassword') >= 0);
        const errorText = (hasError)
            ? "Password is required to make changes to your account"
            : null;

        return (
            <PasswordInputRow
                name="currentPassword"
                label="Password"
                autoComplete="current-password"
                onChange={this.handlePasswordInput}
                value={this.state.password}
                inputClassName={(invalidInputs.indexOf('currentPassword') >= 0) ? sharedStyles.error : ''}
                showPasswordToggle
                errorText={errorText}
            />
        );
    };

    /**
     * Render the security questions question/answer pairs as inputs
     *
     * @param {array} securityQuestions The users security questions
     *
     * @return {ReactElements} The input fields
     */
    renderSecurityQuestions = (securityQuestions) => {
        const { invalidInputs } = this.state.error;

        return securityQuestions.map((row) => {
            const key = row.number;
            const hasError = (invalidInputs.indexOf(key) >= 0);
            const classList = classNames({
                [sharedStyles.error]: (hasError),
            });

            return (
                <div key={key} className={styles.inputRow}>
                    <div className={styles.inputContainer}>
                        <TextInputRow
                            name={`question-${key}`}
                            label={`Question ${key}`}
                            type="text"
                            autoComplete="off"
                            onChange={(event) => this.handleQuestionAnswerInput(event, 'question', key)}
                            value={row.question}
                            placeholder={row.question}
                            className={classList}
                            errorText={(hasError) ? 'Question required' : null}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <TextInputRow
                            name={`answer-${key}`}
                            label={`Answer ${key}`}
                            autoComplete="off"
                            onChange={(event) => this.handleQuestionAnswerInput(event, 'answer', key)}
                            value={row.answer}
                            placeholder={row.answer}
                            className={classList}
                            errorText={(hasError) ? 'Answer required' : null}
                            showToggle={true}
                        />
                    </div>
                </div>
            );
        });
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { hasChanged, error, securityQuestions } = this.state;
        const { accentColour } = this.props.appState;
        const canSubmit = (hasChanged && error.isValid);
        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                {this.renderSecurityQuestions(securityQuestions)}
                {this.renderPasswordField()}
                <PrimaryButton
                    type="submit"
                    text="save"
                    disabled={!canSubmit}
                    style={(canSubmit) ? buttonStyles : null}
                    className={sharedStyles.button}
                />
            </DefaultForm>
        );
    }

}

export default appState.attachState(ChangeSecurityQuestions);
