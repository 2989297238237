import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Action } from "@dataplan/react-components/dist/components/ui/action_menu";
import { ButtonMenu } from "@dataplan/react-components/dist/components/ui/floating_menu";
import ProfileButton from "./ProfileButton.js";

import styles from "./ProfileMenu.module.scss";

/**
 * Renders the app header profile button and menu components
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const ProfileMenu = ({ history }) => {
    const { appState } = useSelector((state) => state);

    return (
        <ButtonMenu className={styles.buttonMenu}
            button={(clickHandler, parentRef) => (
                <ProfileButton
                    onClick={clickHandler}
                    ref={parentRef}
                />
            )}
        >
            <Action
                label="My Details"
                className={styles.action}
                action={() => history.push("/my-details")}
            />
            {!appState.ssoEnabled ? (
                <Action
                    label="My Account"
                    className={styles.action}
                    action={() => history.push("/my-account")}
                />
            ) : null}
            <Action
                label="User Settings"
                className={styles.action}
                action={() => history.push("/settings")}
            />
            <Action
                label="Logout"
                className={styles.action}
                action={() => history.push("/logout")}
            />
        </ButtonMenu>
    );
};

ProfileMenu.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(ProfileMenu);
