import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from 'moment';
import appState from "state/App";
import api, { apiUrl } from "lib/api";
import { payslipListHelpers } from "../../lib";
import { TertiaryButton } from "@dataplan/react-components/dist/components/forms/controls";
import {
    PageHeader,
    PageFrame,
    Payslip,
    PdfDrawer,
    SendPayslip,
} from "components/";

import styles from "./SinglePayslip.module.scss";

class SinglePayslip extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    /**
     * Creates an instance of the app
     *
     * @param {object} props Input data
     */
    constructor (props) {
        super(props);

        this.nativeDrawer = React.createRef();

        this.state = {
            canNavigate: {
                left: false,
                right: false,
            },
            fullPayslip: null,
            payslip: null,
            payslips: payslipListHelpers.getSortedPayslips(props.appState.payslips),
            previousFullPayslip: null,
            showPreview: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        this.handlePayslipChange();
    };

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        document.title = "ePayslips";
    };

    /**
     * Handles updating the component
     *
     * @param {object} prevProps The previous props
     */
    componentDidUpdate (prevProps) {
        const { match } = this.props;

        const payslipId = match.params.payslip_id;
        const prevId = prevProps.match.params.payslip_id;

        if (payslipId !== prevId) {
            this.handlePayslipChange();
        }
    }

    /**
     * Gets payslip and full payslip
     *
     * @return {void}
     */
    handlePayslipChange = () => {
        const { match, history } = this.props;
        const { payslips } = this.state;
        const payslipId = parseInt(match.params.payslip_id, 10);
        const payslip = payslips.find(({ id }) => (id === payslipId));

        if (payslip) {
            const index = payslipListHelpers.getIndexOfPayslip(payslips, payslip.id);
            this.setPageName(payslip.paydate, payslip.pay_period);

            this.setState({
                fullPayslip: null,
                index,
                payslip,
                canNavigate: {
                    left: (index !== 0),
                    right: (index !== (payslips.length - 1)),
                },
            }, this.getFullPayslip);
        } else {
            history.replace('/forbidden');
        }
    };

    /**
     * Gets the full payslip data from the API
     *
     * @return {void}
     */
    getFullPayslip = () => {
        const url = `/payslip/${this.state.payslip.id}`;

        api.get(url)
            .then(({ data }) => {
                this.getPreviousFullPayslip();
                this.setState({
                    fullPayslip: data,
                });
            });
    };

    /**
     * Gets the previous full payslip data from the API
     *
     * @return {void}
     */
    getPreviousFullPayslip = () => {
        const { index, payslips } = this.state;

        if (payslips[index - 1]) {
            const previousId = payslips[index - 1].id;
            const url = `/payslip/${previousId}`;

            api.get(url)
                .then(({ data }) => {
                    this.setState({
                        previousFullPayslip: data,
                    });
                });
        }
    };

    /**
     * Sets the page name
     *
     * @param {string} paydate The payslip pay date
     * @param {string} period The payslip pay period
     *
     */
    setPageName = (paydate, period) => {
        this.pageName = `Payslip for ${moment(paydate).format("MMMM Do YYYY")}, Period ${period}`;
        document.title = this.pageName;
        appState.setPageName(this.pageName);
    };

    /**
     * Navigates through payslips
     *
     * @param {string} direction Direction to navigate in (left, right)
     *
     * @return {void}
     */
    navigatePayslips = (direction) => {
        const { history } = this.props;
        const { canNavigate, index: prevIndex, payslips } = this.state;
        let index = prevIndex;

        if (canNavigate[direction]) {
            if (direction === "left") {
                index--;
            } else if (direction === "right") {
                index++;
            }

            history.replace(`/payslip/${payslips[index].id}`);
        }
    };

    /**
     * Toggle drawer visibility
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                showPreview: !prevState.showPreview,
            };
        });
    };

    /**
     * Renders the page header
     *
     * @return {ReactElement} The page header
     */
    renderPageHeader () {
        const { accentColour } = this.props.appState;
        const buttonText = "View Payslip";

        return (
            <PageHeader title={this.pageName}>
                <TertiaryButton
                    className={styles.buttonContainer}
                    accent={accentColour}
                    onClick={this.toggleDrawer}
                    text={buttonText}
                    aria-label={buttonText}
                />
            </PageHeader>
        );
    }

    /**
     * Render the payslip or the loading spinner
     *
     * @return {ReactElement} The button
     */
    renderPayslip = () => {
        const { canNavigate, fullPayslip, payslip, previousFullPayslip } = this.state;

        return (
            <div
                role="presentation"
                className={styles.payslipContainer}
                key={payslip?.id}
            >
                <Payslip
                    canNavigate={canNavigate}
                    navigatePayslips={this.navigatePayslips}
                    payslip={{ ...payslip, ...fullPayslip }}
                    previousPayslip={previousFullPayslip}
                    payslipLoaded={Boolean(fullPayslip)}
                />
            </div>
        );
    };

    /**
     * Render the pdf drawer
     *
     * @return {ReactElement} The drawer
     */
    renderPdfDrawer = () => {
        const { payslip, showPreview } = this.state;
        const { accentColour } = this.props.appState;

        if (!payslip) {
            return null;
        }

        const payslipPrintUrl = `${apiUrl}/raw/payslip/${payslip.id}?token=${payslip.access_token}`;
        const payslipUrl = `${apiUrl}/payslip/${payslip.id}/pdf/download`;
        const renderSendForm = (props) => <SendPayslip payslipId={payslip.id} {...props} />;

        return (
            <PdfDrawer
                accentColour={accentColour}
                enablePrint
                pageName={this.pageName}
                renderSendForm={renderSendForm}
                url={payslipUrl}
                printUrl={payslipPrintUrl}
                visible={showPreview}
            />
        );
    };

    /**
     * Renders the payslip page
     *
     * @return {ReactElement} The page
     */
    render = () => (
        <PageFrame>
            {this.renderPageHeader()}
            {this.renderPayslip()}
            {this.renderPdfDrawer()}
        </PageFrame>
    );

}

export default withRouter(appState.attachState(SinglePayslip));
