import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { ArrowIcon } from "@dataplan/react-components/dist/components/icons";
import AppHeaderButton from "./AppHeaderButton.js";

import styles from "./BackButton.module.scss";

/**
 * Renders the app header back button component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const BackButton = ({ history }) => {
    const { pageName } = useSelector(({ appState }) => appState);

    if (!pageName || pageName === 'Home') {
        return null;
    }

    return (
        <AppHeaderButton
            aria-label="Go back"
            className={styles.backIcon}
            onClick={history.goBack}
        >
            <ArrowIcon />
        </AppHeaderButton>
    );
};

BackButton.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(BackButton);
