import React from "react";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import styles from "./PayslipAnimation.module.scss";

/**
 * Renders the payslip transition animation on single payslip view
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The transition animation for the carousel
 */
const PayslipAnimation = ({ animationStyle, children, onExited, transition }) => {
    const { toggleIE } = useSelector(({ appState }) => appState);

    if (toggleIE) {
        return children;
    }

    const { direction, animationStart } = transition;
    const animationName = `${animationStyle}${_.upperFirst(direction)}`;

    return (
        <Transition
            in={!animationStart}
            timeout={{
                appear: 0,
                enter: 100,
                exit: 500,
            }}
            appear
            mountOnEnter={false}
            onExited={() => onExited(direction)}
        >
            {(transitionState) => {
                const stateAnimationName = `${animationName}${_.upperFirst(transitionState)}`;

                const containerClasses = classNames([
                    styles[animationStyle],
                    styles[stateAnimationName],
                ]);

                return (
                    <div className={containerClasses}>
                        {children}
                    </div>
                );
            }}
        </Transition>
    );
};

PayslipAnimation.propTypes = {
    children: PropTypes.any,
    transition: PropTypes.object,
    onExited: PropTypes.func,
    animationStyle: PropTypes.string,
};

PayslipAnimation.defaultProps = {
    children: null,
    transition: null,
    onExited: _.noop,
    animationStyle: "payslipDefault",
};

export default PayslipAnimation;
