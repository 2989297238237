import PropTypes from "prop-types";

const payslipPropTypes = {
    id: PropTypes.number,
    net: PropTypes.number,
    gross: PropTypes.number,
    marked_as_read: PropTypes.bool, // eslint-disable-line camelcase
    paydate: PropTypes.string,
    period_number: PropTypes.number, // eslint-disable-line camelcase
    year: PropTypes.number,
    total: PropTypes.number,
    total_deductions: PropTypes.number, // eslint-disable-line camelcase
};
const fullPayslipPropTypes = {
    additions: PropTypes.object,
    deductions: PropTypes.object,
    extras: PropTypes.object,
    employee: PropTypes.object,
    to_date: PropTypes.object, // eslint-disable-line camelcase
    period_number: PropTypes.number, // eslint-disable-line camelcase
};
const payslipsPropTypes = PropTypes.arrayOf(PropTypes.shape(payslipPropTypes));
const fullPayslipsPropTypes = PropTypes.arrayOf(PropTypes.shape(fullPayslipPropTypes));

const payslipDefaultProps = {
    id: null,
    net: null,
    gross: null,
    marked_as_read: null, // eslint-disable-line camelcase
    paydate: null,
    period_number: null, // eslint-disable-line camelcase
    year: null,
    total: null,
    total_deductions: null, // eslint-disable-line camelcase
};
const fullPayslipDefaultProps = {
    additions: {},
    deductions: {},
    extras: {},
    employee: {
        ni: "",
        payroll_ref: "", // eslint-disable-line camelcase
        tax_code: "", // eslint-disable-line camelcase
        ni_code: "", // eslint-disable-line camelcase
    },
    to_date: { // eslint-disable-line camelcase
        total_pay: 0, // eslint-disable-line camelcase
        gross_pay: 0, // eslint-disable-line camelcase
        tax_paid: 0, // eslint-disable-line camelcase
        employees_ni: 0, // eslint-disable-line camelcase
        total_ni: 0, // eslint-disable-line camelcase
        pension: 0,
        ssp: 0,
    },
    period_number: 0, // eslint-disable-line camelcase
};
const payslipsDefaultProps = [];

export {
    payslipPropTypes,
    payslipsPropTypes,
    payslipDefaultProps,
    payslipsDefaultProps,
    fullPayslipDefaultProps,
    fullPayslipPropTypes,
    fullPayslipsPropTypes,
};
