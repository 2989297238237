import React from "react";
import appState from "state/App";
import { Switch, withRouter } from "react-router-dom";
import PrivateRoute from "utils/PrivateRoute";

import {
    PayslipList,
    PayslipBulkDownload,
} from ".";

class PayslipListController extends React.Component {

    /**
     * Renders the payslips page
     *
     * @return {ReactElement} The page
     */
    render () {
        return (
            <Switch>
                <PrivateRoute path="/payslips/download" component={PayslipBulkDownload} />
                <PrivateRoute path="/payslips" component={PayslipList} />
            </Switch>
        );
    }

}

export default withRouter(appState.attachState(PayslipListController));
