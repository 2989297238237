import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from 'lodash';
import { Drawer } from "@dataplan/react-components/dist/components/ui/drawer";
import { CloseIcon } from "@dataplan/react-components/dist/components/icons";
import { PageHeader } from "components/";
import { AuthenticatorForm } from "./setup/index";
import TOPTSetupForm from "./TOTPSetupForm";

import styles from "./TOTPSetupForm.module.scss";

class TOTPSetupDrawer extends React.Component {

    static propTypes = {
        pageName: PropTypes.string,
        disable: PropTypes.bool,
        reset: PropTypes.func,
    };

    static defaultProps = {
        pageName: "",
        disable: false,
        reset: null,
    };

    /**
     * Creates an instance of the component
     *
     * @param {object} props Props passed from parent component
     */
    constructor (props) {
        super(props);

        this.resizeThrottle = _.throttle(this.handleResize, 100);
        this.nativeDrawer = React.createRef();

        this.state = {
            thin: null,
            visible: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        window.addEventListener("resize", this.resizeThrottle);
    };

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        window.removeEventListener("resize", this.resizeThrottle);
    };

    /**
     * Sets drawer and pdf classes depending on width of drawer and pdf
     */
    handleResize = () => {
        const drawer = this.nativeDrawer.current;

        // Only execute if drawer is visible
        if (drawer && drawer.state.visible) {
            // Checks if drawer class requires updating
            const width = drawer.getWidth();

            this.setState((prevState) => {
                return {
                    thin: (width < 600),
                };
            });
        }
    };

    /**
     * Toggle drawer visibility
     *
     * @param {boolean} submit If the draw is closing due to submission
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = (submit) => {
        const drawer = this.nativeDrawer.current;
        const { visible } = this.state;

        if (drawer !== visible) {
            this.setState((prevState) => {
                return {
                    visible: !prevState.visible,
                };
            }, () => {
                drawer.toggleDrawer(this.handleResize);
                this.props.reset(false);
            });
        }

        if (!submit) {
            this.props.reset(true);
        }
    };

    /**
     * Helper method to get the drawer top action
     *
     * @return {Object} The drawer top action
     */
    getTopAction = () => {
        return {
            icon: <CloseIcon aria-label="Close button" className={styles.icon} />,
            action: this.toggleDrawer,
        };
    };

    /**
     * Renders the drawer
     *
     * @return {ReactElement} The drawer
     */
    render = () => {
        const { disable, pageName } = this.props;
        const { nativeDrawer, getTopAction, toggleDrawer } = this;
        const { thin, visible } = this.state;

        const drawerClasses = classNames(styles.drawer, {
            [styles.visible]: visible,
            [styles.thin]: thin,
        });

        const setupDrawer = (
            <TOPTSetupForm enableQR={visible} onSubmit={() => toggleDrawer(true)} />
        );

        const disableDrawer = (
            <AuthenticatorForm disable submitAction={() => toggleDrawer(true)} />
        );

        const drawerType = (disable) ? disableDrawer : setupDrawer;

        return (
            <Drawer
                title={(disable) ? "Authorization" : "Setup"}
                className={drawerClasses}
                ref={nativeDrawer}
                targetNode={document.body}
                topAction={getTopAction()}
                onClickOutside={toggleDrawer}
            >
                <PageHeader title={pageName} />
                {drawerType}
            </Drawer>
        );
    };

}

export default TOTPSetupDrawer;
