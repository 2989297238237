import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import userState from "state/User";
import { attachStates } from "utils/ReduxUtils";

import styles from "./PageFrame.module.scss";

class PageFrame extends React.Component {

    static propTypes = {
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
        maxWidth: PropTypes.number,
        children: PropTypes.any,
        hasGutter: PropTypes.bool,
    };

    static defaultProps = {
        maxWidth: 800,
        children: null,
        hasGutter: true,
    };

    /**
     * Renders the page menu and header
     *
     * @return {ReactElement} The page container
     */
    render () {
        const { children, hasGutter, maxWidth } = this.props;
        const { loggedIn } = this.props.userState;

        const containerStyles = classNames({
            [styles.contentContainer]: loggedIn,
        });

        const wrapperStyles = classNames({
            [styles.loginWrapper]: !loggedIn,
            [styles.contentWrapper]: loggedIn,
            [styles.hasGutter]: hasGutter,
        });

        return (
            <main className={containerStyles} id="main" role="main">
                <div className={wrapperStyles} style={{ maxWidth }}>
                    {children}
                </div>
            </main>
        );
    }

}

export default attachStates([userState], PageFrame);
