import React from 'react';
import PropTypes from "prop-types";
import appState from "state/App";
import PageFrame from "components/PageFrame";
import { withRouter, Redirect } from "react-router-dom";

import { TertiaryButton } from "@dataplan/react-components/dist/components/forms/controls";

import api from "lib/api";
import { createErrorToasts, getAppName } from "lib/";

import { DatedPageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';

import styles from "./Announcement.module.scss";

class Announcement extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
        forwardedRef: PropTypes.any,
    };

    static defaultProps = {
        forwardedRef: null,
    };

    /**
     * Grabs the relevant announcement from the app state
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.pageName = "Announcement";

        this.state = {
            announcement: null,
            redirect: false,
        };
    }

    /**
     * Handles mounting the component
     */
    componentDidMount () {
        this.updatePage();
        this.setAnnouncement();
    }

    /**
     * Handles updating the component
     *
     * @param {object} prevProps previous props
     */
    componentDidUpdate (prevProps) {
        const announcementId = this.props.match.params.announcement_id;
        const prevId = prevProps.match.params.announcement_id;

        if (announcementId !== prevId) {
            this.setAnnouncement();
        }
    }

    /**
     * Sets the announcement in state
     *
     * @return {void}
     */
    setAnnouncement () {
        const { announcements } = this.props.appState;
        const announcementId = this.props.match.params.announcement_id;

        this.setState({
            announcement: this.getAnnouncement(announcements, announcementId),
        });
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Called when component is mounted
     *
     * @return {void}
     */
    updatePage () {
        appState.setPageName(this.pageName);

        appState.setPageDetails("Announcement", [
            {
                "text": "Home",
                "url": "/",
            },
            {
                "text": "Notifications",
                "url": "/notifications",
            },
            {
                "text": "Announcement",
                "url": null,
            },
        ]);

        document.title = this.pageName;
    }

    /**
     * Finds announcement related to given announcementId
     *
     * @param {array} announcements The array of the announcements to search in
     * @param {string} announcementId The ID of the announcement to search for in the announcement array
     * @return {object} announcement
     */
    getAnnouncement (announcements, announcementId) {
        // Parse announcementId
        const id = parseInt(announcementId, 10);

        // Find announcement object in given array
        const announcement = announcements
            .find((item) => item.id === id);

        // Return null if no announcement found
        if (!announcement) {
            this.setState({
                redirect: true,
            });
            return null;
        }

        // Set page title to announcement title
        document.title = announcement.subject;

        // Set announcement.notice_read to true if not already
        if (!announcement.notice_read) {
            this.updateNoticeRead(announcements, announcement);
        }

        return announcement;
    }

    /**
     * Updates notice_read property of given announcement
     *
     * @param {array} announcements The array of the announcements to update in state
     * @param {object} announcement The announcement of which to update the notice_read property
     * @return {void}
     */
    async updateNoticeRead (announcements, announcement) {
        await api.put(`/announcements/${announcement.id}/notice_read`)
            .then(() => {
                // eslint-disable-next-line camelcase
                announcement.notice_read = true;
                appState.setAnnouncements(announcements);
            })
            .catch((error) => {
                createErrorToasts(error.response.data);
            });
    }

    /**
     * Renders an individual announcement page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { announcement, redirect } = this.state;

        if (redirect) {
            return <Redirect to="/forbidden" />;
        } else if (!announcement) {
            return null;
        }

        return (
            <PageFrame>
                <DatedPageLayout text={announcement.subject} date={announcement.dateadded}>
                    <div className={styles.message}>
                        {announcement.message}
                    </div>
                    <div>
                        {this.renderButton()}
                    </div>
                </DatedPageLayout>
            </PageFrame>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    renderButton () {
        const { accentColour } = this.props.appState;

        return (
            <TertiaryButton
                accent={accentColour}
                aria-label={"Close"}
                className={styles.closeButton}
                ref={this.props.forwardedRef}
                onClick={() => this.props.history.push("/notifications")}
                text={"Close"}
            />
        );
    }

}

export default withRouter(appState.attachState(Announcement));
