import userState from "state/User";
import api from './api';

/**
 * Updates the user profile details stored in the user state if changed through my account
 *
 * @return {void}
 */
const refreshUserDetails = () => {
    const getProfile = api.get("/user/profile")
        .then((profile) => {
            userState.setUserDetails(profile.data);
        });

    return Promise.resolve(getProfile);
};

export default refreshUserDetails;
