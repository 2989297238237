import React, {Component} from 'react';
import appState from "state/App";
import styles from "./Security.module.scss";
import { AccountRecovery } from "../../components";
import { getAppName } from "lib/";

import { PageFrame } from "components/";

class Security extends Component {

    /**
     * Creates an instance of the security page
     *
     * @param {object} props Security page properties
     */
    constructor (props) {
        super(props);
        this.pageName = "Security";
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Renders component
     *
     * @return {Component} The component
     */
    render () {
        return (
            <PageFrame>
                <div className={styles.accountRecovery}>
                    <AccountRecovery />
                </div>
            </PageFrame>
        );
    }

}

export default Security;
