import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import IntroCard from "../IntroCard.js";
import AuthenticationForm from "../../AuthenticationForm";
import phoneLockGraphic from "components/assets/icon/phone-lock.svg";

import styles from './AuthenticationIntro.module.scss';

/**
 * Renders the authentication intro component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const AuthenticationIntro = ({ navigate }) => {
    const { payroll } = useSelector(({ appState }) => appState);

    const onSkip = (!payroll.mandatory_mfa)
        ? () => navigate(2)
        : null;

    return (
        <IntroCard onSkip={onSkip} showLogout>
            <div className={styles.header}>
                Multi-Factor Authentication (MFA)
            </div>
            <div className={styles.body}>
                <AuthenticationForm onSubmit={() => navigate(1)} />
                <img
                    alt="phoneLockGraphic"
                    className={styles.graphic}
                    src={phoneLockGraphic}
                />
            </div>
        </IntroCard>
    );
};

AuthenticationIntro.propTypes = {
    navigate: PropTypes.func.isRequired,
};

export default AuthenticationIntro;
