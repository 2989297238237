import React from 'react';
import appState from "state/App";
import { getAppName } from "lib/";

import { HomeButton, PrevPageButton } from './assets';
import { PageFrame, ErrorFrame } from "components/";

import styles from "./Error.module.scss";

class Forbidden extends React.Component {

    /**
     * Creates an instance of the documents page
     *
     * @param {object} props Documents page properties
     */
    constructor (props) {
        super(props);
        this.pageName = "Forbidden";
    }


    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Renders the buttons to be shown on the error frame
     *
     * @return {ReactElement} The button(s)
     */
    renderButtons = () => {

        return (
            <div className={styles.buttonContainer}>
                <HomeButton />
                <PrevPageButton />
            </div>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <PageFrame maxWidth={1200}>
                <ErrorFrame
                    title="Sorry, you do not have permission to view the page requested"
                    content="Please use the buttons below to resume"
                    buttons={this.renderButtons()}
                />
            </PageFrame>
        );
    }

}

export default appState.attachState(Forbidden);
