import React from "react";
import PropTypes from "prop-types";
import appState from "state/App";
import userState from "state/User";
import { withRouter } from "react-router-dom";
import { attachStates } from "utils/ReduxUtils";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';
import { UserAvatar } from "@dataplan/react-components/dist/components/ui/user_avatar";
import { DescriptionList } from "../components/DescriptionList";
import { KeyInfoCard } from "@dataplan/react-components/dist/components/ui/cards/key_info_card";

import styles from "./KeyInfo.module.scss";

class KeyInfo extends React.Component {

    static propTypes = {
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        documentClass: PropTypes.string,
        payslip: PropTypes.object,
    };

    static defaultProps = {
        documentClass: null,
        payslip: {},
    };

    /**
     * Gets the department details
     *
     * @return {ReactElement} The details
     */
    getDepartmentDetails () {
        const jobTitle = this.createJobTitle(userState.post_titles); // eslint-disable-line camelcase
        const { department, company } = this.props.userState;
        return jobTitle || department || company || null;
    }

    /**
     * Creates a job title based on employee's listed jobs
     *
     * @param {Array} postTitles The employee's listed jobs
     * @return {string} The job title
     */
    createJobTitle (postTitles) {
        if (postTitles && postTitles.length > 0) {
            return postTitles.reduce((acc, val) => {
                return `${acc}, ${val}`;
            });
        }

        return null;
    }

    /**
     * Gets the key info details
     *
     * @return {ReactElement} The details
     */
    getKeyInfoDetails () {
        const { employee } = this.props.payslip;

        const {
            "NI Number": ni,
            "Payroll Number": payrollRef,
            "Tax Code": taxCode,
            "NI Code": niCode,
        } = employee;

        if (this.props.appState.payslips.length === 0) {
            return [
                {
                    term: 'Payroll No.',
                    description: this.props.userState.payrollRef,
                },
                {
                    term: 'NI No.',
                    description: this.props.userState.ni,
                },
            ].filter((item) => item.description);
        }

        return [
            {
                term: 'Payroll No.',
                description: payrollRef,
            },
            {
                term: 'NI No.',
                description: ni,
            },
            {
                term: "NI Code",
                description: niCode,
            },
            {
                term: 'Tax Code',
                description: taxCode,
            },
        ].filter((item) => item.description);

    }

    /**
     * Renders the key info component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { documentClass } = this.props;
        const { forename, surname } = this.props.userState;
        const { brandColour } = this.props.appState;
        const keyInfoDetails = this.getKeyInfoDetails();

        return (
            <>
                <KeyInfoCard
                    clickHandler={this.handleCardClick}
                    documentClass={documentClass}
                >
                    <div className={styles.keyInfo}>
                        <div className={styles.keyInfoHeader}>
                            <div className={styles.profileIcon}>
                                <UserAvatar
                                    forename={forename}
                                    surname={surname}
                                    color={brandColour}
                                    textColour={getMostContrastingColour(brandColour, contrastingColourList)}
                                    size={50}
                                />
                            </div>
                            <div className={styles.keyInfoTitleBox}>
                                <h3 className={styles.keyInfoTitle}>
                                    {forename} {surname}
                                </h3>
                                <h4 className={styles.KeyInfoDepartment}>
                                    {this.getDepartmentDetails()}
                                </h4>
                            </div>
                        </div>
                        <div className={styles.listContainer}>
                            <DescriptionList details={keyInfoDetails} />
                        </div>
                    </div>
                </KeyInfoCard>
            </>
        );
    }

}

export default withRouter(attachStates([appState, userState], KeyInfo));
