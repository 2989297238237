import PropTypes from 'prop-types';
import moment from "moment";
import _ from "lodash";

import StateAccessor from './StateAccessor';
import { documentPropTypes, documentDefaultProps } from 'lib/prop-schemas/documentSchema';
import { p45PropTypes, p45DefaultProps } from 'lib/prop-schemas/p45Schema';
import { announcementsPropTypes, announcementsDefaultProps } from 'lib/prop-schemas/announcementSchema';
import { payslipsPropTypes, payslipsDefaultProps } from 'lib/prop-schemas/payslipsSchema';
import { messagesPropTypes, messagesDefaultProps } from 'lib/prop-schemas/messagesSchema';
import { benefitsPropTypes, benefitDefaultProps } from 'lib/prop-schemas/benefitsSchema';
import { payrollPropTypes } from 'lib/prop-schemas/payrollSchema';
import { formsPropTypes, formDefaultProps } from 'lib/prop-schemas/formsSchema';
import { appSettingDefaultProps, appSettingsPropTypes } from 'lib/prop-schemas/appSettingSchema';

class App extends StateAccessor {

    /**
     * Creates an instance of the app state container
     */
    constructor () {
        super("App");
    }

    /**
     * Gets the initial state
     *
     * @return {object} The state object
     */
    getInitialState () {
        return {
            loaded: false,
            redirectUrl: "",
            menuOpen: false,
            profileMenuPosition: {
                top: 0,
                left: 0,
            },
            pageName: null,
            brandColour: null,
            accentColour: null,
            logoData: null,
            colouredNav: false,
            messagesEnabled: false,
            documents: documentDefaultProps,
            p45s: p45DefaultProps,
            currentNotificationId: 0,
            currentSnackbarId: 0,
            notifications: [],
            snackBars: [],
            announcements: announcementsDefaultProps,
            payslips: payslipsDefaultProps,
            latestPayslip: null,
            messages: messagesDefaultProps,
            forms: formDefaultProps,
            firstLoad: true,
            toggleIE: false,
            appSetting: appSettingDefaultProps,
            benefits: benefitDefaultProps,
            navigationBlocked: false,
            navigationBlockPrompt: {
                heading: "You have unsaved changes",
                message: "Are you sure you wish to leave this page?",
            },
            ssoEnabled: false,
            survey: null,
        };
    }

    /**
     * Gets the propTypes description of the state
     *
     * @return {object} The description
     */
    getPropTypes () {
        return {
            loaded: PropTypes.bool,
            redirectUrl: PropTypes.string,
            forename: PropTypes.string,
            surname: PropTypes.string,
            menuOpen: PropTypes.bool,
            profileMenuPosition: PropTypes.shape({
                top: PropTypes.number.isRequired,
                left: PropTypes.number.isRequired,
            }),
            pageName: PropTypes.string,
            brandColour: PropTypes.string,
            accentColour: PropTypes.string,
            logoData: PropTypes.string,
            colouredNav: PropTypes.bool,
            messagesEnabled: PropTypes.bool,
            documents: documentPropTypes,
            p45s: p45PropTypes,
            currentNotificationId: PropTypes.number,
            currentSnackbarId: PropTypes.number,
            notifications: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                text: PropTypes.string.isRequired,
                type: PropTypes.oneOf(["warn", "success", "error", "info"]).isRequired,
                duration: PropTypes.number,
            })),
            snackBars: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                type: PropTypes.oneOf(["warn", "success", "error", "info"]).isRequired,
                heading: PropTypes.string,
                message: PropTypes.string.isRequired,
                onConfirm: PropTypes.func,
                onCancel: PropTypes.func,
                confirmText: PropTypes.string,
                cancelText: PropTypes.string,
            })),
            announcements: announcementsPropTypes,
            payroll: PropTypes.shape(payrollPropTypes),
            payslips: payslipsPropTypes,
            messages: messagesPropTypes,
            forms: formsPropTypes,
            firstLoad: PropTypes.bool,
            toggleIE: PropTypes.bool,
            appSetting: appSettingsPropTypes,
            benefits: benefitsPropTypes,
            navigationBlocked: PropTypes.bool.isRequired,
            navigationBlockPrompt: PropTypes.shape({
                heading: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired,
            }).isRequired,
            ssoEnabled: PropTypes.bool,
            survey: PropTypes.shape({
                url: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired,
            }),
        };
    }

    /**
     * Sets the redirect url upon login
     *
     * @param {string} redirectUrl The url to redirect to upon logout
     */
    setRedirectUrl (redirectUrl) {
        this.setState((prevState) => {
            return {
                ...prevState,
                redirectUrl,
            };
        });
    }

    /**
     * Sets that the initial data has loaded from the API
     */
    setLoaded () {
        this.setState((prevState) => {
            return {
                ...prevState,
                loaded: true,
            };
        }, () => {
            this.setToggleIE();
        });
    }

    /**
     * Sets if the menu is currently open
     *
     * @param {boolean} open If the menu is open
     *
     * @return {void}
     */
    setMenuOpen (open) {
        this.setState((prevState) => {
            return {
                ...prevState,
                menuOpen: open,
            };
        });
    }

    /**
     * Sets the position of the profile menu icon
     *
     * @param {integer} top The number of pixels from the top of the page
     * @param {integer} left The number of pixels from the left of the page
     */
    setProfileMenuPosition (top, left) {
        this.setState((prevState) => {
            return {
                ...prevState,
                profileMenuPosition: {
                    top,
                    left,
                },
            };
        });
    }

    /**
     * Sets the name of the currently visible page
     *
     * @param {string} name The name of the page
     *
     * @return {void}
     */
    setPageName (name) {
        this.setState((prevState) => {
            return {
                ...prevState,
                pageName: name,
            };
        });
    }

    /**
     * Sets the name of the page
     *
     * @param {string} name The page name/title
     *
     * @return {void}
     */
    setPageDetails (name) {
        this.setPageName(name, false);

        this.setState((prevState) => {
            return {
                ...prevState,
            };
        });
    }

    /**
     * Sets the global site brand colours
     *
     * @param {string} brandColour The primary brand colour
     * @param {string} accentColour The accent colour
     * @param {string} logoData Base64 encoded image data
     * @param {boolean} colouredNav If the navigation should be coloured
     * @param {boolean} ssoEnabled If the user has SSO enabled
     *
     * @return {void}
     */
    setBrandingData (brandColour, accentColour, logoData, colouredNav, ssoEnabled) {
        this.setState((prevState) => {
            return {
                ...prevState,
                brandColour,
                accentColour,
                logoData,
                colouredNav,
                ssoEnabled,
            };
        });
    }

    /**
     * Sets if the mesage suite is enabled for the users payroll
     *
     * @param {bool} status If enabled or not
     *
     * @return {void}
     */
    setMessagesEnabled (status) {
        this.setState((prevState) => {
            return {
                ...prevState,
                messagesEnabled: status,
            };
        });
    }

    /**
     * Helper function to set the documents appState to
     * keep track of current documents for a given user
     *
     * @param {array} newDocuments An array of documents
     */
    setDocuments (newDocuments) {
        this.setState((prevState) => {
            let documents = Object.assign(prevState.documents, newDocuments);

            return {
                ...prevState,
                documents,
            };
        });
    }

    /**
     * Helper function to set the drawing board documents appState to
     *
     * @param {array} documents An array of drawing board documents
     */
    setDrawingBoardDocuments = (documents) => {
        this.setState((prevState) => {
            const documentList = documents.map((drawingBoardDocument) => {
                drawingBoardDocument.type = 'drawing_board';

                return drawingBoardDocument;
            });

            return {
                ...prevState,
                documents: {
                    ...prevState.documents,
                    "drawing_board_documents": documentList,
                },
            };
        });
    };

    /**
     * Helper function to set the p45s appState to
     * keep track of current p45s for a given user
     *
     * @param {array} p45s An array of p45s
     */
    setP45s (p45s) {
        this.setState((prevState) => {
            return {
                ...prevState,
                p45s,
            };
        });
    }

    /**
     * Helper function to set the eP60 appState to
     *
     * @param {array} eP60 An array of p60s
     */
    setP60s (eP60) {
        this.setState((prevState) => {
            return {
                ...prevState,
                documents: {
                    ...prevState.documents,
                    eP60,
                },
            };
        });
    }

    /**
     * Sets the form list from the API into the App state
     *
     * @param {array} forms An array of change forms
     */
    setForms (forms) {
        this.setState((prevState) => {
            return {
                ...prevState,
                forms,
            };
        });
    }

    /**
     * Adds a notification to the appState
     *
     * @param {object} notification The notification
     * @param {?function} callback An optional callback
     */
    addNotification (notification, callback = null) {
        this.setState((prevState) => {
            let nextId = prevState.currentNotificationId + 1;
            const { duration } = notification;
            const newNotifications = prevState.notifications.concat({
                id: nextId,
                ...notification,
            });

            if (duration > 0) {
                this.startNotificationTimer(nextId, duration);
            }

            return {
                ...prevState,
                notifications: newNotifications,
                currentNotificationId: nextId,
            };
        });

        if (callback) {
            callback();
        }
    }

    /**
     * Starts the timer to close the notification if a duration is supplied
     *
     * @param {integer} id The ID of the notification to close
     * @param {integer} duration The duration (in seconds) to display the notification
     */
    startNotificationTimer (id, duration) {
        const timer = duration * 1000;

        window.setTimeout(() => {
            this.closeNotification(id);
        }, timer);
    }

    /**
     * Removes the notification from the appState on close
     *
     * @param {integer} id The ID of the notification to remove
     */
    closeNotification = (id) => {
        this.setState((prevState) => {
            let newNotifications = prevState.notifications.filter((notification) => {
                return notification.id !== id;
            });

            return {
                ...prevState,
                notifications: newNotifications,
            };
        });
    };

    /**
     * Adds a snack bar to the appState
     *
     * @param {object} snackBar The snack bar
     * @param {?function} callback An optional callback
     */
    addSnackBar (snackBar, callback = null) {
        this.setState((prevState) => {
            const nextId = prevState.currentSnackbarId + 1;

            const snack = {
                ...snackBar,
            };

            // ensure we close the current snackbar on confirmation otherwise this is left lingering in the pool
            if (snackBar && snackBar.onConfirm) {
                snack.onConfirm = () => {
                    this.closeSnackBar(nextId);
                    snackBar.onConfirm();
                };
            }

            const newSnackBars = prevState.snackBars.concat({
                id: nextId,
                ...snack,
            });

            return {
                ...prevState,
                snackBars: newSnackBars,
                currentSnackbarId: nextId,
            };
        });

        if (callback) {
            callback();
        }
    }

    /**
     * Removes the snack bar from the appState on close
     *
     * @param {integer|null} id The ID of the snack bar to remove
     */
    closeSnackBar = (id = null) => {
        this.setState((prevState) => {
            const snackIdToClose = (id || prevState.snackBars.currentSnackbarId);

            const newSnackbars = prevState.snackBars.filter((snackBar) => {
                return snackBar.id !== snackIdToClose;
            });

            return {
                ...prevState,
                snackBars: newSnackbars,
                currentSnackbarId: 0,
            };
        });
    };

    /**
     * Sets the announcements in appState
     *
     * @param {array} newAnnouncements The list of announcements
     */
    setAnnouncements (newAnnouncements) {
        this.setState((prevState) => {
            let announcements = Object.assign(prevState.announcements, newAnnouncements);

            return {
                ...prevState,
                announcements,
            };
        });
    }

    /**
     * Sets the list of payslips
     *
     * @param {array} payslips The payslip list from the API
     *
     * @return {void}
     */
    setPayslips (payslips) {
        const latestPayslip = _(payslips)
            .sortBy((payslip) => moment(payslip.paydate, "YYYY-MM-DD").unix())
            .last();

        this.setState((prevState) => {
            return {
                ...prevState,
                payslips,
                latestPayslip,
            };
        });
    }

    /**
     * Sets the give payroll information for the employee
     *
     * @param {object} payroll The payroll information from the API
     *
     * @return {void}
     */
    setPayroll (payroll) {
        this.setState((prevState) => {
            return {
                ...prevState,
                payroll,
            };
        });
    }

    /**
     * Sets the list of messages
     *
     * @param {array} messages The messages list from the API
     *
     * @return {void}
     */
    setMessages (messages) {
        this.setState((prevState) => {
            return {
                ...prevState,
                messages,
            };
        });
    }

    /**
     * Sets the state based on whether its the users first log in of the session
     *
     * @return {void}
     */
    setFirstLoad () {
        this.setState((prevState) => {
            return {
                ...prevState,
                firstLoad: false,
            };
        });
    }

    /**
     * Sets the state based on whether the user is using IE or not
     *
     * @return {void}
     */
    setToggleIE () {
        if (navigator.userAgent.indexOf('MSIE') !== -1
        || navigator.appVersion.indexOf('Trident/') > -1){
            /* Microsoft Internet Explorer detected in. */
            this.setState((prevState) => {
                return {
                    ...prevState,
                    toggleIE: true,
                };
            });

        }
    }

    /**
     * Sets app settings
     *
     * @param {object} appSetting from api
     *
     * @return {void}
     */
    setAppSettings (appSetting) {
        this.setState((prevState) => {
            return {
                ...prevState,
                appSetting: {
                    ...appSettingDefaultProps,
                    ...appSetting,
                },
            };
        });
    }

    /**
     * Sets benefits
     *
     * @param {object} benefits from api
     *
     * @return {void}
     */
    setBenefits (benefits) {
        this.setState((prevState) => {
            return {
                ...prevState,
                benefits,
            };
        });
    }

    /**
     * Blocks navigation, asking user to confirm action
     */
    blockNavigation () {
        this.setState((prevState) => {
            return {
                ...prevState,
                navigationBlocked: true,
            };
        });
    }

    /**
     * Unblocks navigation, allowing user to continue
     */
    unblockNavigation () {
        this.setState((prevState) => {
            return {
                ...prevState,
                navigationBlocked: false,
            };
        });
    }

    /**
     * Sets the survey details
     *
     * @param {object} survey The survey details
     */
    setSurvey (survey) {
        this.setState((prevState) => {
            return {
                ...prevState,
                survey,
            };
        });
    }

}

export default new App();
