import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './ArcPath.module.scss';

export default class ArcPath extends Component {

    static propTypes = {
        item: PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        }),
        total: PropTypes.number,
        angle: PropTypes.number,
        width: PropTypes.number,
        innerRadius: PropTypes.number,
        outerRadius: PropTypes.number,
        onClick: PropTypes.func.isRequired,
        fill: PropTypes.string,
        strokeColour: PropTypes.string,
        animationStart: PropTypes.string,
    };

    static defaultProps = {
        item: {
            label: '',
            value: 100,
        },
        total: 100,
        angle: 0,
        width: 150,
        innerRadius: 0.70,
        outerRadius: 0.90,
        fill: '#eeeeee',
        strokeColour: '959595',
        animationStart: '',
    };

    /**
     * Calculate coordinates of given arc
     *
     * @param {number} half Half the total width of the chart
     * @param {number} radius Radius of the arc
     * @param {number} startAngle Starting angle for the arc
     * @param {number} endAngle Ending angle for the arc
     * @return {const} The co-ordinates
     */
    coordinates (half, radius, startAngle, endAngle) {
        const startAngleDegrees = (Math.PI * startAngle) / 180;
        const x1 = half + ((half * radius) * Math.cos(startAngleDegrees));
        const y1 = half + ((half * radius) * Math.sin(startAngleDegrees));
        const endAngleDegrees = (Math.PI * endAngle) / 180;
        const x2 = half + ((half * radius) * Math.cos(endAngleDegrees));
        const y2 = half + ((half * radius) * Math.sin(endAngleDegrees));

        return {
            x1,
            y1,
            x2,
            y2,
        };
    }

    /**
     * Creates an SVG arc object for an SVG path object
     *
     * @param {number} width Total width of the chart
     * @param {number} radius Radius of the arc
     * @param {string} largeArcFlag Flag for angles over 180 degrees
     * @param {number} x X coordinate for arc
     * @param {number} y Y coordinate for arc
     * @return {string} The arc object
     */
    arc (width, radius, largeArcFlag, x, y) {
        const z = (width / 2) * radius;

        return `A${z}, ${z} 0 ${largeArcFlag} ${x}, ${y}`;
    }

    /**
     * Creates the SVG path object for an item.
     *
     * @param {number} activeAngle Value of current active angle.
     * @param {number} startAngle Degree at which the angle calculation should begin.
     * @param {number} width Total width of the chart.
     * @param {number} innerRadius Inner circle's radius.
     * @param {number} outerRadius Outer circle's radius.
     * @return {string} The Co-ordinate string for the SVG
     */
    path (activeAngle, startAngle, width, innerRadius, outerRadius) {
        const endAngle = startAngle + activeAngle;

        const largeArcFlagOuter = activeAngle > 180 ? '1 1' : '0 1';
        const largeArcFlagInner = activeAngle > 180 ? '1 0' : '0 0';
        const half = width / 2;
        const outerCoords = this.coordinates(half, outerRadius, startAngle, endAngle);
        const innerCoords = this.coordinates(half, innerRadius, startAngle, endAngle);

        const outerArc = this.arc(
            width, outerRadius, largeArcFlagOuter,
            outerCoords.x2, outerCoords.y2,
        );
        const innerArc = this.arc(
            width, innerRadius, largeArcFlagInner,
            innerCoords.x1, innerCoords.y1,
        );

        return (
            `M${outerCoords.x1},${outerCoords.y1}
            ${outerArc}
            L${innerCoords.x2},${innerCoords.y2}
            ${innerArc} z`
        );
    }

    /**
     * Renders each arc path for the SVG
     *
     * @param {number} value Value of incoming item
     * @param {number} total Cumulative value of all items
     * @param {number} startAngle Degree at which the angle calculation should begin
     * @param {number} width Total width of the chart
     * @param {number} innerRadius Inner circle's radius
     * @param {number} outerRadius Outer circle's radius
     * @return {object} The path for the SVG
     */
    render () {
        const {
            width, angle, total, fill, item, strokeColour,
            innerRadius, outerRadius, onClick,
        } = this.props;
        const { value } = item;

        if (!value) {
            return null;
        }

        const numberCheck = (Number.isNaN(value / total) || ((total / value) === 1));
        const activeAngle = numberCheck ? 359.99 : (value / total) * 360;
        const d = this.path(activeAngle, angle, width, innerRadius, outerRadius);
        const startAnimation = this.props.animationStart ? styles.path : styles.pathReverse;
        return (
            <>
                <path
                    onClick={(event) => onClick(event, item)}
                    d={d}
                    stroke={"#ffffff"}
                    strokeWidth={'16px'}
                    fill={'none'}
                />
                <path
                    onClick={(event) => onClick(event, item)}
                    d={d}
                    stroke={strokeColour}
                    strokeWidth={'7.5px'}
                    fill={fill}
                />
                <path
                    className={startAnimation}
                    d={"M15,82 a 68,68 0 1, 0 136, 0 a 68,68 0 1, 0 -136,0"}
                    stroke={"#ffffff"}
                    strokeWidth={'50px'}
                    fill={"none"}
                />
            </>
        );
    }

}
