import React from "react";
import PropTypes from "prop-types";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { MyDetailsCard } from "@dataplan/react-components/dist/components/ui/cards/my_details_card";
import { UserAvatar } from "@dataplan/react-components/dist/components/ui/user_avatar";
import MyPayrollDetails from "./MyPayrollDetails";
import api from "lib/api";
import appState from "state/App";
import userState from "state/User";
import { PageFrame } from "components/";
import { attachStates } from "utils/ReduxUtils";
import { contrastingColourList, getAppName } from 'lib/';
import { fullPayslipDefaultProps } from 'lib/prop-schemas/payslipsSchema';

import styles from "./MyDetails.module.scss";

class MyDetails extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the details page
     *
     * @param {object} props Details page properties
     */
    constructor (props) {
        super(props);
        this.pageName = "MyDetails";

        this.state = {
            fullPayslip: fullPayslipDefaultProps,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageDetails("MyDetails", [
            {
                "text": "Home",
                "url": "/",
            },
            {
                "text": "MyDetails",
                "url": "/my-details",
            },
        ]);

        appState.setPageName(this.pageName);
        document.title = this.pageName;
        this.loadFullPayslip();
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Gets the department details
     *
     * @return {ReactElement} The details
     */
    getDepartmentDetails () {
        const jobTitle = this.createJobTitle(userState.post_titles); // eslint-disable-line camelcase
        const { department, company } = this.props.userState;

        return (jobTitle || department || company || null);
    }

    /**
     * Creates a job title based on employee's listed jobs
     *
     * @param {Array} postTitles The employee's listed jobs
     *
     * @return {string|null} The job title
     */
    createJobTitle (postTitles) {
        if (postTitles && postTitles.length > 0) {
            return postTitles.reduce((acc, val) => {
                return `${acc}, ${val}`;
            });
        }

        return null;
    }

    /**
     * Loads the full payslip data from the API
     *
     * @return {void}
     */
    loadFullPayslip () {
        const { latestPayslip } = this.props.appState;

        if (!latestPayslip) {
            return;
        }

        api.get(`/payslip/${latestPayslip.id}`)
            .then((response) => {
                this.setState({
                    fullPayslip: response.data,
                });
            });
    }

    /**
     * Renders the details page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { forename, surname } = this.props.userState;
        const { brandColour } = this.props.appState;

        return (
            <PageFrame>
                <MyDetailsCard>
                    <div className={styles.myDetails}>
                        <div className={styles.myDetailsHeader}>
                            <div className={styles.profileIcon}>
                                <UserAvatar
                                    forename={forename}
                                    surname={surname}
                                    color={brandColour}
                                    textColour={getMostContrastingColour(brandColour, contrastingColourList)}
                                    size={50}
                                />
                            </div>
                            <div className={styles.myDetailsTitleBox}>
                                <h1 className={styles.myDetailsTitle}>
                                    {forename} {surname}
                                </h1>
                                <h2 className={styles.myDetailsDepartment}>
                                    {this.getDepartmentDetails()}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <MyPayrollDetails fullPayslip={this.state.fullPayslip} />
                </MyDetailsCard>
            </PageFrame>
        );
    }

}

export default (attachStates([appState, userState], MyDetails));
