import React from "react";
import PropTypes from "prop-types";

import styles from "./DescriptionList.module.scss";

export class DescriptionList extends React.Component {

    static propTypes = {
        details: PropTypes.arrayOf(PropTypes.shape({
            term: PropTypes.string,
            details: PropTypes.string,
        })).isRequired,
    };

    /**
     * Renders the date component
     *
     * @return {ReactElement} The date container
     */
    render () {
        const { details } = this.props;

        const list = details.map(({ term, description }) => {
            return (
                <div className={styles.rowContainer} key={term + description}>
                    <div className={styles.termList}>
                        <span className={styles.term} key={term}>
                            {term}
                        </span>
                    </div>
                    <div className={styles.descriptionList}>
                        <span className={styles.description} key={term}>
                            {description}
                        </span>
                    </div>
                </div>
            );
        });

        return (
            <div className={styles.listContent}>
                {list}
            </div>
        );
    }

}

export default DescriptionList;
