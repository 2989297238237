import React from 'react';
import PropTypes from 'prop-types';
import appState from 'state/App';
import classNames from 'classnames';

import styles from './AnimateTextUnderline.module.scss';

class AnimateTextUnderline extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        children: PropTypes.any.isRequired,
        classList: PropTypes.object,
    };

    static defaultProps = {
        classList: {
            wrapper: "",
            underline: "",
            underlineFill: "",
        },
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { accentColour } = this.props.appState;
        const { wrapper, underline, underlineFill } = this.props.classList;

        return (
            <div className={classNames([styles.textWrapper, [wrapper]])}>
                {this.props.children}
                <div
                    className={classNames([styles.underline, [underline]])}
                    aria-hidden={true}
                >
                    <div
                        className={classNames([styles.underlineFill, [underlineFill]])}
                        style={{backgroundColor: accentColour}}
                    />
                </div>
            </div>
        );
    }

}

export default appState.attachState(AnimateTextUnderline);
