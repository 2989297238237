import reduxReset from "redux-reset";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";

import Raven from "raven-js";
import createRavenMiddleware from "raven-for-redux";
import { stateManagers, stateReducers } from "./state-reducers";

import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from 'connected-react-router';

import _ from 'lodash';

export const history = createBrowserHistory();

export const reducers = combineReducers({
    router: connectRouter(history),
    ...stateReducers,
});

const enhancers = [];

enhancers.push(applyMiddleware(
    routerMiddleware(history),
    createRavenMiddleware(Raven),
));
enhancers.push(reduxReset());

/* eslint-disable no-underscore-dangle */
// This adds a hook for the redux DevTool extension if it"s installed
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}
/* eslint-enable */

const preLoadedState = stateManagers.reduce((states, stateManager) => {
    states[stateManager.stateKeyName] = stateManager.getLoadedState();

    return states;
}, {});

const store = createStore(reducers, preLoadedState, compose(...enhancers));

_.forOwn(stateManagers, (stateManager) => {
    stateManager.setStore(store);
});

store.subscribe(() => {
    const currentStoreState = store.getState();

    for (let [key, state] of Object.entries(currentStoreState)) {
        sessionStorage.setItem(key, JSON.stringify(state));
    }
});

export { store };
