import api from "lib/api";
import getContrastRatio from "get-contrast-ratio";

export default class Branding {

    /**
     * @param {StateAccessor} appState The appState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the branding data
     *
     * @return {Promise} A promise that resolves with the branding data
     */
    load () {
        return api.get("/branding")
            .then((branding) => this.set(branding));
    }

    /**
     * Checks the colour against white for a contrast ratio
     *
     * @param {string} colour The colour to be checked
     * @return {string} The ratio of the contrast
     */
    getColourContrast = (colour) => {
        return (getContrastRatio(colour, '#fff') > 1.20) ? colour : '#757575';
    };

    /**
     * Handles the branding data loaded from the API
     *
     * @param {object} branding The axios response
     *
     * @return {void}
     */
    set (branding) {
        const brandColour = branding.data.brand_colour || "#272C48";
        const accentColour = branding.data.accent_colour || "#BD3A1C";
        const logoData = branding.data.brand_logo_data || null;
        const colouredNav = branding.data.coloured_navigation || false;
        const contrastedBrandColour = this.getColourContrast(brandColour);
        const contrastedAccentColour = this.getColourContrast(accentColour);

        this.appState.setBrandingData(
            contrastedBrandColour,
            contrastedAccentColour,
            logoData,
            colouredNav,
            branding.data.sso_enabled,
        );
    }

}
