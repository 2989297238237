import api from "lib/api";

export default class DrawingBoardDocuments {

    /**
     * @param {StateAccessor} appState The appState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the drawing board documents data
     *
     * @return {Promise} A promise that resolves with the documents data
     */
    load = () => api.get("/drawing_board")
        .then(({ data }) => this.appState.setDrawingBoardDocuments(data));

}
