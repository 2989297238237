import React, { Component } from 'react';
import PropTypes from 'prop-types';
import appState from "state/App";

import Arcs from './Arcs';

import styles from './DonutChart.module.scss';
import _ from "lodash";

class DonutChart extends Component {

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        })).isRequired,
        height: PropTypes.number,
        startAngle: PropTypes.number,
        innerRadius: PropTypes.number,
        outerRadius: PropTypes.number,
        callBack: PropTypes.func.isRequired,
        selected: PropTypes.object,
        animationStart: PropTypes.string,
    };

    static defaultProps = {
        height: 165,
        startAngle: 90,
        innerRadius: 0.70,
        outerRadius: 0.90,
        selected: {},
        animationStart: '',
    };

    /**
     * Creates an instance of the component
     *
     * @param {object} props Props passed from parent component
     */
    constructor (props) {
        super(props);

        this.resizeThrottle = _.throttle(this.handleResize, 100);

        this.state = {
            width: this.getWidth(),
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        window.addEventListener("resize", this.resizeThrottle);
    };

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        window.removeEventListener("resize", this.resizeThrottle);
    };

    /**
     * Sets width for graph on resize
     */
    handleResize = () => {
        const width = this.getWidth();

        this.setState((prevState) => {
            return {
                width,
            };
        });
    };

    /**
     * Gets the width based on screen size
     *
     * @return {number} the screen size
     */
    getWidth () {
        return (window.screen.width < 600) ? 140 : 165;
    }

    /**
     * Calculates the total of all the items' value
     *
     * @param {array} items An array of items
     * @return {object} The total of the values
     */
    calculateTotal (items) {
        return items.reduce((sum, currItem) => sum + currItem.value, 0);
    }

    /**
     * Handles the clicking of each arc
     *
     * @param {element} event The event to prevent clicking the wrong object and closing the card
     * @param {array} item The item object selected
     * @return {void}
     */
    handleClick = (event, item) => {
        event.stopPropagation();

        if (this.props.callBack) {
            this.props.callBack(event, item);
        }
    };

    /**
     * This removes the onClick from the whole SVG including the whole in the middle of the chart
     *
     * @param {element} event The event to prevent clicking ther wrong object and closing the card
     * @return {void}
     */
    removeClick (event) {
        event.stopPropagation();
    }

    /**
     * Renders SVG of the donut graph as a whole
     *
     * @return {ReactElement} The SVG for the graph
     */
    render () {
        const {
            startAngle, height, data,
            innerRadius, outerRadius, animationStart,
        } = this.props;
        const { width } = this.state;

        const checkData = data.length ? data : [{
            label: '',
            value: 100,
        }];

        const total = this.calculateTotal(checkData);
        return (
            <>
                <svg
                    className={styles.donutChart}
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    onClick={(event) => this.removeClick(event)}
                >
                    <Arcs
                        data={checkData}
                        width={width}
                        onClick={this.handleClick}
                        selected={this.props.selected}
                        startAngle={startAngle}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        total={total}
                        animationStart={animationStart}
                    />
                </svg>
            </>
        );
    }

}

export default appState.attachState(DonutChart);
