import PropTypes from "prop-types";

const payrollPropTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    company_id: PropTypes.number.isRequired, // eslint-disable-line camelcase
    company_number: PropTypes.string.isRequired, // eslint-disable-line camelcase
    uses_eva: PropTypes.bool.isRequired, // eslint-disable-line camelcase
    mandatory_mfa: PropTypes.bool.isRequired, // eslint-disable-line camelcase
    introduction_popups: PropTypes.bool.isRequired, // eslint-disable-line camelcase
};

export {
    payrollPropTypes,
};
