import React from 'react';
import { PageFrame } from 'components';
import MobileAppCard from 'components/MobileAppCard';
import { AnimationContainer } from '@dataplan/react-components/dist/components/ui/animation';

const ePayslipsApp = () => {
    return (
        <AnimationContainer
            appearTimeout={200}
            enterTimeout={1000}
            exitTimeout={100}
            animationStyle={"animationContainer"}
        >
            <PageFrame>
                <MobileAppCard />
            </PageFrame>
        </AnimationContainer>
    );

};

export default ePayslipsApp;
