import React from 'react';
import PropTypes from "prop-types";
import { singleMessagePropTypes, singleMessageDefaultProps } from "lib/prop-schemas/messagesSchema";
import { withRouter } from "react-router-dom";

import Attachment from "lib/downloaders/Attachment";
import {
    PrimaryButton,
    SecondaryButton,
} from "@dataplan/react-components/dist/components/forms/controls";
import { AttachmentIcon } from "@dataplan/react-components/dist/components/icons";

import styles from "./SingleMessageSharedStyles.module.scss";

class MessageView extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        message: singleMessagePropTypes.message,
        toggleReplyState: singleMessagePropTypes.toggleReplyState,
        buttonStyles: singleMessagePropTypes.buttonStyles,
    };

    static defaultProps = {
        message: singleMessageDefaultProps.message,
        toggleReplyState: singleMessageDefaultProps.toggleReplyState,
        buttonStyles: singleMessageDefaultProps.buttonStyles,
    };

    /**
     * Renders the label/value pair for whom the message is from
     *
     * @param {?string} sender The name of the sender
     *
     * @return {?ReactElement} The sender name with label
     */
    renderMessageFrom = (sender) => {
        if (!sender) {
            return null;
        }

        return (
            <div className={styles.fromContainer}>
                <div className={styles.sectionTitle}>From</div>
                <div>{sender}</div>
            </div>
        );
    };

    /**
     * Renders the message body with label
     *
     * @param {?string} message The message body
     *
     * @return {?ReactElement} The messaage body with label
     */
    renderMessageBody = (message) => {
        if (!message) {
            return null;
        }

        return (
            <div className={styles.messageContainer}>
                <div className={styles.sectionTitle}>Message</div>
                <div className={styles.messageBody}>{message}</div>
            </div>
        );
    };

    /**
     * Render the button text dependant on who the message is sent to
     *
     * @param {string} recipient Who is receiving the message
     *
     * @return {string} The text to show on the button
     */
    renderPrimaryButtonText = (recipient) => {
        return (recipient === 'employee')
            ? "Reply"
            : "Forward";
    };

    /**
     * Render message attachment links
     *
     * @param {array} attachments The list of attachments for the message
     *
     * @return {?ReactElement} The HTML collection of link buttons
     */
    renderAttachments = (attachments) => {
        if (attachments.length < 1) {
            return null;
        }

        return (
            <div className={styles.attachmentSection}>
                {attachments.map((attachment) => {
                    const attachmentHandler = new Attachment(attachment, {
                        fileName: attachment.filename,
                    });

                    return (
                        <div key={attachment.id} className={styles.attachment}>
                            <AttachmentIcon className={styles.icon} />
                            <button
                                type="button"
                                className={styles.downloadButton}
                                onClick={() => attachmentHandler.download()}
                            >
                                {attachment.filename}
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { message, toggleReplyState, buttonStyles} = this.props;

        return (
            <>
                <div className={styles.messageContent}>
                    {this.renderMessageFrom(message.from)}
                    {this.renderMessageBody(message.message)}
                </div>
                <div className={styles.buttonContainer}>
                    <div className={styles.primaryButtonContainer}>
                        <PrimaryButton
                            style={buttonStyles}
                            onClick={toggleReplyState}
                            text={this.renderPrimaryButtonText(message.recipient)}
                        />
                    </div>
                    <div className={styles.secondaryButtonContainer}>
                        <SecondaryButton
                            onClick={() => this.props.history.push('/messages')}
                            text="Back"
                        />
                    </div>
                </div>
                {this.renderAttachments(message.attachments)}
            </>
        );
    }

}

export default withRouter(MessageView);
