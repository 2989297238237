import moment from 'moment';
import handleFile from './handleFile';

export default class P60s {

    /**
     * Constructor class for handling documents
     *
     * @param {object} file File data
     * @param {object} config The config for the download
     */
    constructor (file, config = {}) {
        Object.assign(this, file);
        this.fileApi = `${file._links.pdf.href}?token=${this.access_token}`; // eslint-disable-line no-underscore-dangle

        this.config = {
            fileDateFormat: 'YYYY-MM-DD',
            dateDisplayFormat: 'DD-MM-YYYY',
            newCondition: {
                unitOfTime: 'day',
                time: 45,
            },
        };

        Object.assign(this.config, config);

        this.dateTime = file.datetime_added;
    }

    /**
     * Helper function to download a given file. Triggers a download event in IE or opens the
     * file in a new tab in other browsers
     *
     * @return {void}
     */
    download = () => handleFile(this.fileApi);

    /**
     * Setter for the date time added for a given file and parses it to
     * month day (IE: APR 19). The file format and date display format
     * can be altered by changing the config
     *
     * @param {string} date The date the file was added
     *
     * @return {void} Write only
     */
    set dateTime (date) {
        const { fileDateFormat } = this.config;

        this.datetimeAdded = moment(date, fileDateFormat);
    }

    /**
     * Getter for the dateTimeAdded
     *
     * @return {string} Returns the parsed date
     */
    get dateTime () {
        const { dateDisplayFormat } = this.config;

        return this.datetimeAdded.format(dateDisplayFormat);
    }

}
