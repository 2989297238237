import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Tooltip } from "@dataplan/react-components/dist/components/ui/tooltip";
import AppHeaderButton from "./AppHeaderButton.js";
import { NewCount } from "components/";

import styles from "./NotificationsButton.module.scss";

/**
 * Renders the app header notifications button component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const NotificationsButton = ({ className, forwardedRef, onClick }) => {
    const { firstLoad, setFirstLoad } = useSelector(({ appState }) => appState);
    const buttonClasses = classNames(styles.notificationsButton, className);

    const newCountAnimation = classNames(styles.newCount, {
        [styles.newCountAnimation]: firstLoad,
    });

    return (
        <AppHeaderButton
            aria-label="Open Notifications menu"
            className={buttonClasses}
            onClick={onClick}
            ref={forwardedRef}
        >
            <Tooltip text="Notifications" position="bottom">
                <NewCount
                    className={newCountAnimation}
                    onAnimationEnd={setFirstLoad}
                    type="notifications"
                />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 24" height="25px" width="25px">
                    <path
                        data-name="Path 1237"
                        // eslint-disable-next-line max-len
                        d="M9.531 24.5a2.52 2.52 0 002.513-2.513H7.018A2.512 2.512 0 009.531 24.5zm7.538-7.538v-6.283c0-3.857-2.061-7.086-5.654-7.941v-.853a1.885 1.885 0 10-3.769 0v.854C4.04 3.593 1.992 6.81 1.992 10.68v6.282l-1.62 1.62a1.257 1.257 0 00.879 2.148h16.547a1.26 1.26 0 00.892-2.148z"
                        fill="#858585"
                    />
                </svg>
            </Tooltip>
        </AppHeaderButton>
    );
};

NotificationsButton.propTypes = {
    className: PropTypes.string,
    forwardedRef: PropTypes.any,
    onClick: PropTypes.func,
};

NotificationsButton.defaultProps = {
    className: null,
    forwardedRef: null,
    onClick: _.noop,
};

export default React.forwardRef((props, ref) => <NotificationsButton forwardedRef={ref} {...props} />);
