import React from "react";
import PropTypes from "prop-types";
import appState from "state/App";
import { apiUrl } from "lib/api";
import { getAppName } from "lib/";

import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import {
    PageHeader,
    PageFrame,
    DocumentsFrame,
    PdfDrawer,
} from "components/";

class P11ds extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.pageName = "P11Ds";

        this.state = {
            viewedP11d: null,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Toggle drawer visibility
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                showPreview: !prevState.showPreview,
            };
        });
    };

    /**
     * Set P11d in state
     *
     * @param {object} viewedP11d The P11d to set in state
     *
     * @return {void} Changes state of page
     */
    viewP11d = (viewedP11d) => {
        this.setState({
            viewedP11d,
        }, () => {
            // Set timeout so that drawer click outside occurs first
            window.setTimeout(this.toggleDrawer, 0);
        });
    };

    /**
     * Renders the P11ds page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { accentColour, documents: { P11d } } = this.props.appState;
        const { viewedP11d, showPreview } = this.state;

        const P11dPrintUrl = (viewedP11d) ? `${apiUrl}${viewedP11d.url}&disposition=inline` : null;
        const P11dUrl = (viewedP11d) ? `${apiUrl}/file/P11d/${viewedP11d.id}/data` : null;

        const headerTitle = (viewedP11d) ? viewedP11d.description : "P11D document";

        return (
            <PageFrame>
                <PdfDrawer
                    accentColour={accentColour}
                    enablePrint={true}
                    pageName={headerTitle}
                    url={P11dUrl}
                    printUrl={P11dPrintUrl}
                    visible={showPreview}
                />
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <PageHeader title={this.pageName} />
                </AnimationContainer>
                <DocumentsFrame
                    animationDelay={200}
                    documents={P11d}
                    onClick={this.viewP11d}
                />
            </PageFrame>
        );
    }

}

export default appState.attachState(P11ds);
