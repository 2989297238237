import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import { attachStates } from "utils/ReduxUtils";
import appState from "state/App";
import userState from "state/User";
import OutsideClickHandler from "react-outside-click-handler";
import SideMenuItem from "components/SideMenuItem";
import SideMenuExternal from "components/SideMenuExternal";
import _ from "lodash";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import styles from "./SideMenu.module.scss";
import defaultLogo from "./assets/img/epayslips.png";

class SideMenu extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        buttonRef: PropTypes.object.isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.menuListRef = React.createRef();
        this.menuWrapperRef = React.createRef();
        this.logoLinkRef = React.createRef();

        this.state = {
            menuHeight: 0,
        };
    }

    /**
     * Called when a key is pressed
     *
     * @param {Event} event The event
     *
     * @return {void}
     */
    handleKeyUp = (event) => {
        if ((event.code && event.code === "Escape") || event.keyCode === 27) {
            appState.setMenuOpen(false);
        }
    };

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.setMenuHeight();
        document.addEventListener("keyup", this.handleKeyUp);
        window.addEventListener("orientationchange", this.setMenuHeight);
        window.addEventListener("resize", this.setMenuHeight);
    }

    /**
     * Called just before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.removeEventListener("keyup", this.handleKeyUp);
        window.removeEventListener("orientationchange", this.setMenuHeight);
        window.removeEventListener("resize", this.setMenuHeight);
        clearAllBodyScrollLocks();
    }

    /**
     * Called when the component receives new props
     *
     * @param {object} prevProps The components previous properties
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        const { menuOpen } = this.props.appState;

        if ((!prevProps.appState.menuOpen && menuOpen) || !prevProps.appState.loaded) {
            this.setMenuHeight();
        }

        if (menuOpen) {
            disableBodyScroll(this.menuListRef.current);
        } else {
            enableBodyScroll(this.menuListRef.current);
        }
    }

    /**
     * Calculate height of menu based on viewport height
     * Done in JS due to iOS being awesome with it's 2 menu bars
     */
    setMenuHeight = () => {
        const logo = this.logoLinkRef.current;
        const nav = this.menuWrapperRef.current;
        const navStyles = window.getComputedStyle(nav);

        const logoHeight = logo.offsetHeight + logo.offsetTop;
        const navPadding = parseInt(navStyles.getPropertyValue('margin-top').replace("px", ""), 10);

        this.setState({
            menuHeight: (window.innerHeight - logoHeight - (navPadding * 2)),
        });
    };

    /**
     * Called when a click happens outside of the menu
     *
     * @param {Event} event The click event
     */
    handleClickOutside = (event) => {
        const { buttonRef } = this.props;

        if (event.target !== buttonRef.current) {
            appState.setMenuOpen(false);
        }
    };


    /**
     * Renders the company logo if configured
     *
     * @return {ReactElement} The logo or null
     */
    renderBrandLogo () {
        const { logoData } = this.props.appState;
        const imgSrc = (logoData)
            ? `data:image/png;base64,${logoData}`
            : defaultLogo;

        return (
            <Link to="/" innerRef={this.logoLinkRef} className={styles.logoLink}>
                <img
                    className={styles.logo}
                    src={imgSrc}
                    alt="Company Logo"
                />
            </Link>
        );
    }

    /**
     * Gets additional styles that need to be applied to the menu
     *
     * @return {object} CSS properties to be set
     */
    getThemeStyles () {
        const menuBackground = (this.props.appState.colouredNav) ? this.props.appState.brandColour : "#fff";

        return {
            backgroundColor: menuBackground,
        };
    }

    /**
     * Get a list of links and properties to render the side menu
     *
     * @return {array} The list of links
     */
    getLinkList () {
        const { forms, messages, p45s, payslips, ssoEnabled } = this.props.appState;

        const {
            eP60,
            P11d,
            personal,
            personalcompany,
            company,
            pension,
            "drawing_board_documents": drawingBoardDocuments,
        } = this.props.appState.documents;

        const personalCompanyDocs = [...personal, ...personalcompany, ...company, ...drawingBoardDocuments];
        const allInbox = messages.filter((allMessages) => allMessages.recipient === "employee");

        return [
            {
                text: "Home",
                to: "/",
                params: {
                    exact: true,
                },
            },
            {
                text: "Payslips",
                to: "/payslips",
                conditional: !_.isEmpty(payslips),
                newCount: payslips.filter((allPayslip) => allPayslip.marked_as_read === false).length,
            },
            {
                text: "P60s",
                to: "/p60s",
                conditional: !_.isEmpty(eP60),
            },
            {
                text: "P45s",
                to: "/p45s",
                conditional: !_.isEmpty(p45s),
            },
            {
                text: "P11Ds",
                to: "/p11ds",
                conditional: !_.isEmpty(P11d),
            },
            {
                text: "Messages",
                to: "/messages",
                conditional: this.props.appState.messagesEnabled,
                params: {
                    isActive: (match, location) => (match || location.pathname.substr(0, 10) === "/messages/"),
                },
                newCount: allInbox.filter((message) => !message.opened).length,
            },
            {
                text: "Documents",
                to: "/documents",
                conditional: !_.isEmpty(personalCompanyDocs),
            },
            {
                text: "Pensions",
                to: "/pensions",
                conditional: !_.isEmpty(pension),
            },
            {
                text: "Forms",
                to: "/forms",
                conditional: !_.isEmpty(forms),
            },
            {
                text: "My Account",
                to: "/my-account",
                conditional: !ssoEnabled,
            },
        ];
    }

    /**
     * Renders the menu
     *
     * @return {ReactElement} The menu
     */
    render () {
        const { menuOpen, benefits } = this.props.appState;
        const linkList = this.getLinkList();

        const classList = classNames({
            [styles.container]: true,
            [styles.open]: menuOpen,
            [styles.menuLoaded]: this.props.appState.loaded && !this.props.userState.loggedIn,
        });

        const benefitsMenu = (benefits.enabled)
            ? <SideMenuExternal target="_blank" href={benefits.url} label={benefits.label} />
            : null;

        return (
            <OutsideClickHandler
                onOutsideClick={this.handleClickOutside}
                disabled={!menuOpen}
                display="block"
            >
                <div
                    className={classList}
                    style={this.getThemeStyles()}
                    aria-label="side menu"
                    role="navigation"
                >
                    {this.renderBrandLogo()}
                    <nav className={styles.menuContainer} id="navigation" ref={this.menuWrapperRef}>
                        <ul
                            className={styles.menuList}
                            ref={this.menuListRef}
                            style={{ height: this.state.menuHeight }}
                        >
                            {linkList.map((link) => {
                                return (
                                    <SideMenuItem
                                        key={link.text}
                                        label={link.text}
                                        to={link.to}
                                        conditional={link.conditional}
                                        params={link.params}
                                    />
                                );
                            })}
                            {benefitsMenu}
                        </ul>
                    </nav>
                </div>
            </OutsideClickHandler>
        );
    }

}

export default withRouter(attachStates([appState, userState], SideMenu));
