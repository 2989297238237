import React from 'react';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import PropTypes from "prop-types";
import { SecurityCard } from "@dataplan/react-components/dist/components/ui/cards/security_card";
import AccountRecoveryForm from "./AccountRecoveryForm";

const AccountRecoveryCard = ({ isWelcomePage }) => (
    <AnimationContainer
        appearTimeout={200}
        enterTimeout={1000}
        exitTimeout={100}
        animationStyle={"animationContainer"}
    >
        <SecurityCard bodyContent={<AccountRecoveryForm enableRemind isWelcomePage={isWelcomePage} />} />
    </AnimationContainer>
);

AccountRecoveryCard.propTypes = {
    isWelcomePage: PropTypes.bool,
};

AccountRecoveryCard.defaultProps = {
    isWelcomePage: false,
};

export default AccountRecoveryCard;
