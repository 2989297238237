import userState from "state/User";
import appState from "state/App";
import { history } from "../redux-store";
import Preloaders from './preloaders/Preloaders';

/**
 * Handles login process upon successful login attempt
 *
 * @param {object} params Any additional params
 *
 * @returns {Promise} The promise of loading all preloaders
 */
export const handleLoginPhase1 = (params = {}) => {
    const preloaders = new Preloaders(appState, userState, params);

    return preloaders.loadAll()
        .then(() => {
            appState.setLoaded();
        });
};

/**
 * Handles login process upon login animation complete and user ready to enter app
 */
export const handleLoginPhase2 = () => {
    const { new_epayslips_onboarding_seen: onboardingSeen, mfa_totp: mfaSetup } = userState.store.getState().userState;
    const { payroll } = appState.store.getState().appState;

    const mfaRequired = (!mfaSetup && payroll.mandatory_mfa);
    const showWelcome = ((!onboardingSeen || mfaRequired) && payroll.introduction_popups);
    const location = (!showWelcome) ? "/" : "/welcome";

    userState.setUserEnteredApp();
    history.push(location);
};
