import React from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import appState from "state/App";
import _ from "lodash";
import api from 'lib/api';
import { DataTable } from '@dataplan/react-components/dist/components/ui/data_table';
import { filterObjectEmptyValues } from 'lib/';

import styles from "./DevicesStyles.module.scss";

class Devices extends React.Component {

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            devices: [],
            hasTrusted: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Invoked before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Handles the api call to get the devices
     */
    handleApiCall () {
        api.get("/auth/devices", { component: this })
            .then(({ data }) => {
                this.mapDevicesToState(data);
            }).catch((apiError) => {
                if (!axios.isCancel(apiError)) {
                    appState.addNotification({
                        text: "Unable to load the devices table",
                        type: "error",
                        duration: 0,
                    });
                }
            });
    }

    /**
     * Maps the company array for use in the data table
     *
     * @param {object} devices the companies from the api
     * @return {array} mapped array
     */
    mapDevices (devices) {
        return devices.map((device) => {
            const loginLog = device.last_login.login_log;
            const geoCityName = loginLog?.geoip_city_name;
            const geoCountryName = loginLog?.geoip_country_name;

            const geoText = _.isEmpty((geoCityName || geoCountryName))
                ? "No location provided"
                : `${geoCityName}, ${geoCountryName}`;

            return filterObjectEmptyValues({
                Name: {
                    text: device.friendly_name,
                },
                LastLoggedIn: {
                    text: (loginLog?.time)
                        ? moment.utc(loginLog.time).tz("Europe/London").format('Do MMM YYYY HH:mm')
                        : "",
                },
                Location: {
                    text: geoText,
                },
                Trusted: {
                    component: () => this.getTrusted(device.trusted),
                },
            });
        });
    }

    /**
     * Gets if a devices is trusted or not and returns an element if trusted
     *
     * @param {boolean} trusted if a device is trusted or not
     * @returns {ReactElement} The trusted element or null
     */
    getTrusted = (trusted) => {
        return (trusted) && (
            <div className={styles.container}>
                <div className={styles.trusted} />
                <div>
                    Trusted
                </div>
            </div>
        );
    };

    /**
     * Makes an object of the attributes for the data table
     *
     * @return {object} attributes
     */
    getTableAttributes () {
        const { hasTrusted } = this.state;

        const docTableHeadings = {
            Name: "Name",
            LastLoggedIn: "Last logged in",
            Location: "Location",
            Trusted: hasTrusted ? " " : "",
        };

        const colOrder = [
            "Name",
            "LastLoggedIn",
            "Location",
            hasTrusted ? "Trusted" : "",
        ];

        return {
            docTableHeadings,
            colOrder,
        };
    }

    /**
     * Saves the mapped companies to the saved state
     *
     * @param {array} devices An array of companies
     */
    mapDevicesToState (devices) {
        const hasTrusted = devices.filter(({ trusted }) => trusted).length > 0;

        this.setState({
            devices: this.mapDevices(devices),
            dataLoaded: true,
            hasTrusted,
        });
    }

    /**
     * Renders the Device tab
     *
     * @return {ReactElement} The component
     */
    render () {
        const { docTableHeadings, colOrder } = this.getTableAttributes();

        return (
            <DataTable
                key="Devices"
                colOrder={colOrder}
                data={this.state.devices}
                dataLoaded={this.state.dataLoaded}
                headings={docTableHeadings}
                sortable
                scrollableBody
            />
        );
    }

}

export default Devices;
