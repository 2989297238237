import _ from 'lodash';
import api from "./api";

export default class DeviceTokenManager {

    /**
     * DeviceTokenManager constructor
     */
    constructor () {
        this.userType = 'employee';
        this.deviceKey = `device_token_new_epayslips`;
    }

    /**
     * Gets the device cookie value
     *
     * @return {any} Nothing
     */
    getToken () {
        return localStorage.getItem(this.deviceKey);
    }

    /**
     * Sets the given token in local storage
     *
     * @param {string} token The device token
     *
     * @return {void} Nothing
     */
    setToken (token) {
        localStorage.setItem(this.deviceKey, token);
    }

    /**
     * Removes the current device token from storage
     *
     * @return {void} Removes token
     */
    removeToken = () => {
        localStorage.removeItem(this.deviceKey);
    };

    /**
     * Logs the device
     *
     * @param {boolean} isTrusted sets the device as trusted
     * @param {function} onComplete The callback to make on request completion
     *
     * @return {void} Nothing
     */
    logDevice (isTrusted = false, onComplete = _.noop) {
        if (!isTrusted) {
            this.removeToken();
            onComplete();

            return;
        }

        const data = {
            "device_token": this.getToken(),
        };

        api.post('/auth/device/log', data)
            .then((response) => {
                this.setToken(response.data.token);
                this.setTrustedDevice(response.data.token, onComplete);
            });
    }

    /**
     * Sets the device to be trusted
     *
     * @param {string} token The device token
     * @param {function} onComplete The callback to make on completion
     *
     * @return {void} Patches device
     */
    setTrustedDevice = (token, onComplete) => {
        api.patch('auth/device/trust', {
            token,
        }).then(onComplete);
    };

}
