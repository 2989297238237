import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { PayslipCard } from "@dataplan/react-components/dist/components/ui/cards/payslip_card";
import stylesActionMenu from "@dataplan/react-components/dist/components/ui/cards/actions/CardActionMenu.module.scss";
import { PayslipHeader } from '../';
import PayslipCoverBody from './PayslipCoverBody.js';

import styles from "./PayslipCover.module.scss";

const { actionFillHover } = stylesActionMenu;

/**
 * Renders the payslip cover
 *
 * @return {ReactElement} The payslip cover
 */
const PayslipCover = ({ history }) => {
    const { latestPayslip } = useSelector(({ appState }) => appState);
    const handleCardClick = () => history.push(`/payslip/${latestPayslip.id}`);

    return (
        <PayslipCard
            clickHandler={handleCardClick}
            innerClasses={styles.hover}
            wrapperStyles={{ actionFillHover }}
        >
            <PayslipHeader paydate={latestPayslip.paydate} />
            <PayslipCoverBody />
        </PayslipCard>
    );
};

PayslipCover.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(PayslipCover);
