import api from 'lib/api';

export default class LegacyMessages {

    /**
     * @param {StateAccessor} appState The appState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the message data
     *
     * @return {Promise} A promise that resolves with the message data
     */
    load () {
        return api.get("/legacy_messages/enabled")
            .then((messagesEnabled) => this.set(messagesEnabled));
    }

    /**
     * Handles the status of the message suite loaded from the API
     *
     * @param {object} messagesEnabled The axios response
     *
     * @return {void}
     */
    set (messagesEnabled) {
        const status = messagesEnabled.data.messages_enabled;

        this.appState.setMessagesEnabled(status);
    }

}
