import _ from "lodash";
import api from "lib/api";

export default class Documents {

    /**
     * @param {StateAccessor} appState The appState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the documents data
     *
     * @return {Promise} A promise that resolves with the documents data
     */
    load () {
        return api.get("/documents?generate_access_token=true")
            .then((documents) => this.set(documents));
    }

    /**
     * Handles the document data loaded from the API
     *
     * @param {array} documents The document data for the current user
     *
     * @return {void}
     */
    set (documents) {
        const mappedDocuments = _.omit(_.groupBy(documents.data, 'type'), 'eP60');

        this.appState.setDocuments(mappedDocuments);
    }

}
