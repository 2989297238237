import React from 'react';
import payslipsGraphic from 'components/assets/img/payslips-icon.png';
import text from './WelcomeMessage.json';

import styles from './WelcomeMessage.module.scss';

/**
 * Renders the welcome message content components
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const WelcomeMessageContent = () => (
    <>
        <div className={styles.header}>
            {text.header}
        </div>
        <div className={styles.body} >
            {text.body.map((line) => (
                <span key={line}>
                    {line}
                </span>
            ))}
            <img
                alt="payslipsGraphic"
                className={styles.graphic}
                src={payslipsGraphic}
            />
        </div>
    </>
);

export default WelcomeMessageContent;
