import api from "lib/api";

export default class Payslips {

    /**
     * @param {StateAccessor} appState The global app state
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the payslips
     *
     * @return {Promise} A promise that resolves with the data
     */
    load () {
        return api.get("/payslips?generate_access_token=true").then((response) => {
            this.appState.setPayslips(response.data);
        });
    }

}
