import React from 'react';
import PropTypes from 'prop-types';

import { history } from "../../../redux-store";

import { ErrorPageButton } from '.';

class HomeButton extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: null,
    };

    /**
     * Handles the button onClick event
     */
    handleClick = () => {
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }

        history.replace('/');
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <ErrorPageButton onClick={this.handleClick}>
                Home
            </ErrorPageButton>
        );
    }

}

export default HomeButton;
