import React, { Component } from 'react';
import PropTypes from 'prop-types';
import appState from "state/App";

import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';
import ArcPath from './ArcPath';
import { ACCENT_PRIMARY } from "shared/Colours";

class Arcs extends Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        data: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        })).isRequired,
        selected: PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        }),
        total: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        innerRadius: PropTypes.number.isRequired,
        outerRadius: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        startAngle: PropTypes.number,
        animationStart: PropTypes.string,
    };

    static defaultProps = {
        startAngle: 90,
        selected: {},
        animationStart: '',
    };

    /**
     * Renders each part of the arc for the SVG
     *
     * @return {object} The arcs of the SVG
     */
    render () {
        const { accentColour } = this.props.appState;
        const {
            startAngle, data, onClick, total, width,
            selected, innerRadius, outerRadius, animationStart,
        } = this.props;
        let angle = startAngle;

        return (
            <g>
                { data.map((item) => {
                    const selectedItem = (selected.label === item.label);
                    const colourCheck = getMostContrastingColour(accentColour, contrastingColourList);
                    let selectedColour = accentColour;

                    if (colourCheck === '#000') {
                        selectedColour = ACCENT_PRIMARY;
                    }

                    const fillColour = selectedItem ? selectedColour : '#eeeeee';
                    const arcPath = (
                        <ArcPath
                            width={width}
                            item={item}
                            key={item.label}
                            innerRadius={innerRadius}
                            outerRadius={outerRadius}
                            fill={fillColour}
                            angle={angle}
                            total={total}
                            onClick={onClick}
                            strokeColour={fillColour}
                            animationStart={animationStart}
                        />
                    );

                    angle += (item.value / total) * 360;

                    return arcPath;
                }) }
            </g>
        );
    }

}

export default appState.attachState(Arcs);
