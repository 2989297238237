import React from 'react';
import PropTypes from "prop-types";
import QRCode from 'react-qr-code';
import IntroCard from "../IntroCard.js";
import {useSelector} from "react-redux";
import { getMostContrastingColour } from '@dataplan/react-components/dist/lib/index.js';
import { PrimaryButton } from '@dataplan/react-components/dist/components/forms/controls/index.js';
import { contrastingColourList } from 'lib/settings.js';
import AppleBadge from '../../assets/icon/AppleBadge.svg';
import AndroidBadge from '../../assets/icon/AndroidBadge.png';

import styles from './MobileApp.module.scss';

/**
 * Renders the authentication intro component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const MobileAppIntro = ({ navigate }) => {
    const { brandColour: backgroundColor } = useSelector(({ appState }) => appState);

    const buttonStyles = {
        backgroundColor,
        color: getMostContrastingColour(backgroundColor, contrastingColourList),
    };

    return (
        <IntroCard showLogout noLeftMargin>
            <div className={styles.header}>
                ePayslips Mobile App
            </div>
            <div className={styles.body}>
                <div>
                    Did you know, you can access your ePayslips account on your mobile device?
                    Simply search for ePayslips in your devices app store, or use the QR Codes below.
                </div>
                <div className={styles.container}>
                    <div className={styles.badgeQRContainer}>
                        <QRCode
                            value="https://play.google.com/store/apps/details?id=uk.co.epayslips.mobile&hl=en&gl=US"
                            className={styles.qrCode}
                        />
                        <a
                            href="https://play.google.com/store/apps/details?id=uk.co.epayslips.mobile&hl=en&gl=US"
                        >
                            <img
                                className={styles.badge}
                                src={AndroidBadge}
                                alt="Get it on Google Play"
                            />
                        </a>
                    </div>
                    <div className={styles.badgeQRContainer}>
                        <QRCode
                            value="https://apps.apple.com/gb/app/epayslips-mobile/id1539137458"
                            className={styles.qrCode}
                        />
                        <a
                            href="https://apps.apple.com/gb/app/epayslips-mobile/id1539137458"
                        >
                            <img
                                className={styles.badge}
                                src={AppleBadge}
                                alt="Download it on the app store"
                            />
                        </a>
                    </div>
                </div>
                <PrimaryButton
                    className={styles.startButton}
                    onClick={() => navigate(1)}
                    style={buttonStyles}
                    type="submit"
                    text={"Continue"}
                />
            </div>
        </IntroCard>
    );
};

MobileAppIntro.propTypes = {
    navigate: PropTypes.func.isRequired,
};

export default MobileAppIntro;
