import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import {
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
} from "@dataplan/react-components/dist/components/ui/table";
import { AttachmentIcon } from "@dataplan/react-components/dist/components/icons";

import styles from './MessageTable.module.scss';

class MessageTable extends React.Component {

    static propTypes = {
        messages: PropTypes.array,
        isSentMessages: PropTypes.bool,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        messages: [],
        isSentMessages: false,
    };

    /**
     * Renders a row for each message
     *
     * @param {array} messages The array of messages to render
     *
     * @return {ReactElement[]} An array of table row elements
     */
    renderMessageRows = (messages) => {
        const { isSentMessages, history } = this.props;

        return messages.map((message) => {
            const rowClass = classNames(styles.messageRow, {
                [styles.unread]: (!message.opened && !isSentMessages),
            });
            const subjectClass = !isSentMessages ? styles.cellSubject : styles.cellSentSubject;
            const dateObject = moment(message.time_sent, "YYYY-MM-DDTHH:mm:ss+Z");

            return (
                <TableRow key={message.id} className={rowClass} onClick={() => history.push(`/messages/${message.id}`)}>
                    <TableCell className={subjectClass}>
                        {message.subject}
                    </TableCell>
                    {!isSentMessages && <TableCell className={styles.cellFrom}>{message.from}</TableCell>}
                    <TableCell className={styles.cellDate}>{dateObject.format("DD MMM YYYY HH:mm")}</TableCell>
                    <TableCell className={styles.cellAttachment}>
                        {this.renderAttachmentCell(message.attachments)}
                    </TableCell>
                </TableRow>
            );
        });
    };

    /**
     * Renders the cell showing attachment count
     *
     * @param {number} attachmentCount The attachment count for the message
     *
     * @return {?ReactElement} The cell contents
     */
    renderAttachmentCell = (attachmentCount) => {
        if (attachmentCount < 1) {
            return '';
        }

        return (
            <div className={styles.attachmentCell}>
                <AttachmentIcon className={styles.icon} />
                {attachmentCount}
            </div>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { messages, isSentMessages } = this.props;

        return (
            <div className={styles.tableContainer}>
                <Table className={styles.messageTable}>
                    <TableHeader className={styles.tableHeader}>
                        <th className={styles.tableHeadings}>Subject</th>
                        {!isSentMessages && <th className={styles.tableHeadings}>From</th>}
                        <th className={styles.tableHeadings}>Date</th>
                        <th className={styles.tableHeadings} />
                    </TableHeader>
                    <TableBody>
                        {this.renderMessageRows(messages)}
                    </TableBody>
                </Table>
            </div>
        );
    }

}

export default withRouter(MessageTable);
