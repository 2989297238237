import PropTypes from 'prop-types';

const benefits = {
    enabled: PropTypes.bool,
    label: PropTypes.string,
    url: PropTypes.string,
};

const benefitDefaultProps = {
    enabled: false,
    label: "",
    url: "",
};

const benefitsPropTypes = PropTypes.shape(benefits);

export { benefitsPropTypes, benefitDefaultProps };
