import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { attachStates } from "utils/ReduxUtils";
import { withRouter } from "react-router-dom";
import appState from 'state/App';
import userState from 'state/User';

import {
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
} from "@dataplan/react-components/dist/components/ui/table";
import {
    CheckboxInput,
    PrimaryButton,
} from "@dataplan/react-components/dist/components/forms/controls";
import { getMostContrastingColour, checkPasswordStrength } from "@dataplan/react-components/dist/lib";
import { ButtonMenu } from "@dataplan/react-components/dist/components/ui/floating_menu";
import { AnimateTextUnderline } from "components/";
import { Drawer } from "@dataplan/react-components/dist/components/ui/drawer";
import { ArrowIcon } from "@dataplan/react-components/dist/components/icons";

import api from "lib/api";
import { contrastingColourList } from 'lib/';
import handleFile from "../../../lib/downloaders/handleFile";
import { SendBulkPayslips } from "../../../components/payslip-actions/";

import styles from './BulkDownloadTable.module.scss';

class BulkDownloadTable extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
        payslips: PropTypes.array,
        forwardedRef: PropTypes.any,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
        forwardedRef: null,
        payslips: [],
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.nativeDrawer = React.createRef();

        this.state = {
            selectedPayslips: [],
            passwordInput: '',
            passwordStrength: {
                isLong: false,
                lowercase: false,
                uppercase: false,
            },
        };
    }

    /**
     * Checks the new passwords strength
     *
     * @return {void}
     */
    handleStrengthTest = () => {
        const { passwordInput } = this.state;
        const passwordStrength = checkPasswordStrength(passwordInput);

        this.setState({
            passwordStrength,
        });
    };

    /**
     * Render the button text with underline element
     *
     * @param {string} text The text of the button
     * @return {ReactElement} The button text
     */
    renderButtonText (text) {
        const classList = {
            underlineFill: styles.underlineFill,
        };

        return (
            <AnimateTextUnderline classList={classList}>
                {text}
            </AnimateTextUnderline>
        );
    }

    /**
     * Renders the component
     *
     * @param {string} text The text of the button
     * @param {Function} clickHandler The function to invoke on click
     * @return {ReactElement} The component
     */
    renderButton (text, clickHandler) {
        return (
            <button
                type="button"
                className={styles.button}
                ref={this.props.forwardedRef}
                onClick={clickHandler}
            >
                {this.renderButtonText(text)}
            </button>
        );
    }

    /**
     * Renders the component
     *
     * @param {number} selectedPayslipID The text of the button
     * @return {void}
     */
    setPayslip = (selectedPayslipID) => {
        const { selectedPayslips } = this.state;

        const isChecked = selectedPayslips.indexOf(selectedPayslipID) >= 0;

        let currentSelection;

        if (!isChecked) {
            currentSelection = [...selectedPayslips];
            currentSelection.push(selectedPayslipID);
        } else {
            currentSelection = selectedPayslips.filter((id) => id !== selectedPayslipID);
        }

        this.setState({
            selectedPayslips: currentSelection,
        });
    };

    /**
     * Renders a row for each message
     *
     * @param {array} payslips The array of messages to render
     *
     * @return {ReactElement[]} An array of table row elements
     */
    renderPayslipBulkRows = (payslips) => {
        const { selectedPayslips } = this.state;
        const { accentColour } = this.props.appState;
        const isMobileDevice = window.matchMedia("(max-width: 600px)").matches;

        return payslips.map((payslip) => {
            const dateObject = moment(payslip.paydate, "YYYY-MM-DDTHH:mm:ss+Z");
            const checked = selectedPayslips.indexOf(payslip.id) >= 0;
            const payslipNet = (payslip.net).toFixed(2);
            const payslipGross = (payslip.gross).toFixed(2);
            // eslint-disable-next-line camelcase
            const payslipDeductions = (payslip.total_deductions).toFixed(2);

            const handleCheckboxChange = () => this.setPayslip(payslip.id);

            return (
                <TableRow key={payslip.id} className={styles.messageRow}>
                    <TableCell >
                        <CheckboxInput
                            value={payslip.id}
                            name={"check"}
                            backgroundColour={accentColour}
                            checked={checked}
                            onChange={handleCheckboxChange}
                        />
                    </TableCell>
                    <TableCell className={styles.cellDate}>{dateObject.format("DD MMM YYYY")}</TableCell>
                    {!isMobileDevice && (<TableCell className={styles.cellGross}>{payslipGross}</TableCell>)}
                    {!isMobileDevice && (<TableCell className={styles.cellDeductions}>{payslipDeductions}</TableCell>)}
                    <TableCell className={styles.cellNet}>{payslipNet}</TableCell>
                </TableRow>
            );
        });
    };

    /**
     * Handles the change of checkbox
     *
     * @param {event} event The checked checkbox
     *
     * @return {void}
     */
    handleChange = (event) => {
        const allSelected = event.target.checked;
        const { payslips } = this.props;

        this.setState({
            selectedPayslips: (allSelected)
                ? payslips.map(({ id }) => id)
                : [],
        });
    };

    /**
     * Handles the change of checkbox
     *
     * @return {ReactElement} The count of selected payslips
     */
    bulkSelected = () => {
        const { accentColour } = this.props.appState;
        const selectedCount = this.state.selectedPayslips.length;
        const bulkSelectColour = (selectedCount) ? accentColour : '#969696';

        return (
            <div className={styles.selectedCount}
                style={{
                    background: bulkSelectColour,
                    borderColor: bulkSelectColour,
                    color: getMostContrastingColour(bulkSelectColour, contrastingColourList),
                }}
            >
                {`${selectedCount} Selected`}
            </div>
        );
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handlePasswordInput = (event) => {
        const passwordInput = event.target.value;

        this.setState({
            passwordInput,
        }, this.handleStrengthTest);
    };

    /**
     * Handle user confirmation of navigating away
     *
     * @return {void}
     */
    handleDownload = () => {
        this.downloadFile();
        this.handleSnackClose();
    };

    /**
     * Handle user cancelling navigation
     */
    handleSnackClose = () => {
        const { currentSnackbarId } = this.props.appState;

        appState.closeSnackBar(currentSnackbarId);
    };

    /**
     * Render the snack bar to prompt the user
     */
    renderSnackBar = () => {
        appState.addSnackBar({
            type: 'warn',
            message: "This file may contain sensitive information. Because this file may contain personal"
                + " data about yourself, you should keep it secure and take precautions when storing, "
                + "sharing or uploading it to any other services.",
            onConfirm: this.downloadFile,
            confirmText: "Download",
            onCancel: this.handleSnackClose,
            cancelText: "Cancel",
            showCancelButton: false,
        });
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    downloadFile = () => {
        const { username } = this.props.userState;

        api.get('/payslips/pdf/download', {
            responseType: 'arraybuffer',
            params: {
                ids: this.state.selectedPayslips,
                password: this.state.passwordInput,
            },
        })
            .then((response) => {
                const payslipPdf = new Blob([response.data], { type: 'application/pdf' });
                const name = `${username}_payslips.pdf`;

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(payslipPdf, name);
                } else {
                    const url = window.URL.createObjectURL(payslipPdf);

                    handleFile(url, {
                        download: name,
                    });
                }

                appState.addNotification({
                    type: "success",
                    text: [
                        "Payslips downloaded.",
                    ],
                    duration: 5,
                });

                this.props.history.push('/payslips');
            });
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    renderInput = () => {
        return (
            <input
                type={"password"}
                id={"pdfPassword"}
                name={"pdfPassword"}
                autoComplete={"new-password"}
                value={this.state.passwordInput}
                onChange={this.handlePasswordInput}
                className={styles.input}
            />
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The card
     */
    renderDownloadCard = () => {
        const { accentColour } = this.props.appState;
        const { selectedPayslips, passwordStrength } = this.state;
        const { uppercase, lowercase, isLong } = passwordStrength;
        const strongPassword = (uppercase && lowercase && isLong);

        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        const secondaryContent = (
            <PrimaryButton
                type="button"
                aria-label="downloads bulk payslip"
                disabled={!strongPassword}
                className={styles.bulkDownloadButton}
                text="Download"
                style={(strongPassword) ? buttonStyles : null}
                onClick={this.renderSnackBar}
            />
        );

        const mobileDevice = window.matchMedia("(max-width: 600px)").matches;

        return (selectedPayslips.length) ? (
            <ButtonMenu
                accentColour={accentColour}
                button={(clickHandler, parentRef) => {
                    return (
                        <div
                            role="button"
                            tabIndex={0}
                            aria-label="download button"
                            className={styles.button}
                            ref={parentRef}
                            onClick={clickHandler}
                            onKeyDown={clickHandler}
                        >
                            {this.renderButton("Download")}
                        </div>
                    );
                }}
                className={styles.downloadCard}
                footerText={"Cancel"}
                headerText={"Set password:"}
                showCloseButton={true}
                menuFullscreen={mobileDevice}
                keepBodyOpen={true}
                buttonMenuStyle={{
                    textAlign: 'left',
                    border: 'none',
                    padding: '35px 10px 25px',
                }}
                footerStyles={{
                    textAlign: 'left',
                    border: 'none',
                    padding: '0',
                }}
                closeStyles={{
                    right: '20px',
                    left: '10px',
                }}
                secondaryContent={secondaryContent}
            >
                <div
                    className={styles.passwordDescription}
                    style={{border: 'none'}}
                >
                    This must be at least 8 characters and include Uppercase and Lowercase
                </div>
                {this.renderInput()}
            </ButtonMenu>
        ) : null;
    };

    /**
     * Toggle drawer visibility
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = () => {
        const drawer = this.nativeDrawer.current;

        if (drawer) {
            drawer.toggleDrawer();
        }
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The drawer with form
     */
    renderBulkSendDrawer = () => {
        const isPayslipSelected = this.state.selectedPayslips.length;
        const selectedPayslip = this.props.payslips.find((payslip) => payslip.id === this.state.selectedPayslips[0]);
        const formattedPayslipTitle = isPayslipSelected ? moment(selectedPayslip.paydate).format("MMMM Do YYYY") : null;

        if (!isPayslipSelected) {
            return null;
        }

        return (
            <>
                <div
                    className={styles.button}
                    aria-label="send button"
                >
                    {this.renderButton('Send', this.toggleDrawer)}
                </div>
                <Drawer
                    title="Bulk Download"
                    className={styles.drawer}
                    targetNode={document.body}
                    ref={this.nativeDrawer}
                    header={null}
                    topAction={{
                        icon: <ArrowIcon className={styles.arrowIconClasses} />,
                        action: this.toggleDrawer,
                    }}
                    footerActions={null}
                    onClickOutside={this.toggleDrawer}
                >
                    <section className={styles.drawerFormContent}>
                        <h2>Payslips from {formattedPayslipTitle}</h2>
                        <SendBulkPayslips
                            selectedPayslipIds={this.state.selectedPayslips}
                            closeDrawer={this.toggleDrawer}
                        />
                        <div
                            className={styles.backButton}
                            aria-label="close form"
                        >
                            {this.renderButton('Back', this.toggleDrawer)}
                        </div>
                    </section>
                </Drawer>
            </>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { payslips } = this.props;
        const { accentColour } = this.props.appState;
        const { selectedPayslips } = this.state;
        const isMobileDevice = window.matchMedia("(max-width: 600px)").matches;

        return (
            <>
                <div
                    className={styles.bulkActions}
                >
                    <div className={styles.bulkSelected}>
                        {this.bulkSelected()}
                    </div>
                    <div className={styles.downloadButton}>
                        {this.renderDownloadCard()}
                    </div>
                    <div
                        className={styles.sendButton}
                    >
                        {this.renderBulkSendDrawer()}
                    </div>
                </div>
                <div className={styles.tableContainer}>
                    <Table className={styles.payslipTable}>
                        <TableHeader>
                            <th className={styles.tableHeadings}>
                                <CheckboxInput
                                    onChange={this.handleChange}
                                    value={"allPayslips"}
                                    name={"check"}
                                    backgroundColour={accentColour}
                                    checked={(payslips.length === selectedPayslips.length)}
                                />
                            </th>
                            <th className={styles.tableHeadings}>
                                Paydate
                            </th>
                            {!isMobileDevice && (<th className={styles.tableHeadings}>Gross</th>)}
                            {!isMobileDevice && (<th className={styles.tableHeadings}>Deductions</th>)}
                            <th className={styles.tableHeadings}>Net</th>
                        </TableHeader>
                        <TableBody>
                            {this.renderPayslipBulkRows(payslips)}
                        </TableBody>
                    </Table>
                </div>
            </>
        );
    }

}

export default withRouter(attachStates([appState, userState], BulkDownloadTable));

