import React from "react";
import PropTypes from "prop-types";
import appStateMethods from "state/App";

import styles from "./SkipLink.module.scss";

const config = {
    main: {
        onClick: () => appStateMethods.setMenuOpen(false),
        text: "Skip to content",
    },
    navigation: {
        onClick: () => appStateMethods.setMenuOpen(true),
        text: "Skip to navigation",
    },
};

/**
 * Renders the skip link component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const SkipLink = ({ type }) => {
    const { text, onClick } = config[type];

    return (
        <a
            aria-label={text}
            className={styles.skipLink}
            href={`#${type}`}
            onClick={onClick}
            role="menuitem"
            tabIndex="0"
        >
            {text}
        </a>
    );
};

SkipLink.propTypes = {
    type: PropTypes.string.isRequired,
};

export default SkipLink;
