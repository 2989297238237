import React from 'react';
import PropTypes from "prop-types";
import appState from "state/App";
import { withRouter } from "react-router-dom";
import api from "lib/api";
import axios from 'axios';

import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList, getAppName } from 'lib/';
import {
    MessageView,
    MessageReply,
} from ".";
import { DatedPageLayout } from '@dataplan/react-components/dist/components/ui/page_layout';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";


class MessageSingle extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        match: PropTypes.object.isRequired,
    };

    /**
     * Creates an instance of the single message page
     *
     * @param {object} props The component properties
     *
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.pageName = "Messages";

        this.state = {
            isReplying: false,
            message: {
                id: null,
                from: '',
                recipient: '',
                subject: '',
                message: '',
                timeSent: '',
                opened: false,
                attachments: [],
            },
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageDetails("Messages", [
            {
                "text": "Home",
                "url": "/",
            },
            {
                "text": "Messages",
                "url": "/messages",
            },
            {
                "text": "View Message",
                "url": null,
            },
        ]);

        this.getMessage(parseInt(this.props.match.params.message_id, 10));
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Gets the message content from the API
     *
     * @param {number} messageId The ID of the message we want to view
     *
     * @return {void}
     */
    getMessage = (messageId) => {
        axios.all([
            api.get(`/legacy_message/${messageId}`),
            api.get(`/legacy_message/${messageId}/attachments`),
        ]).then(axios.spread((messageObj, attachments) => {
            const {
                id,
                from,
                recipient,
                subject,
                message,
                time_sent:
                timeSent,
                opened,
            } = messageObj.data;

            this.setState({
                message: {
                    id,
                    from,
                    recipient,
                    subject,
                    message,
                    timeSent,
                    opened,
                    attachments: attachments.data,
                },
            }, () => {
                if (recipient === 'employee') {
                    this.markMessageRead(messageId, opened);
                }
                document.title = subject;
            });
        }));
    };

    /**
     * Marks the message as read once it has been retrieved from the API and loaded into state
     *
     * @param {number} messageId The ID of the message we have loaded
     * @param {bool} opened If the message has already been read
     *
     * @return {void}
     */
    markMessageRead = (messageId, opened) => {
        if (opened) {
            return;
        }

        api.put(`/legacy_message/${messageId}`)
            .then(() => {
                const appMessages = this.props.appState.messages;
                const message = appMessages.find((element) => element.id === messageId);

                if (message) {
                    // eslint-disable-next-line camelcase
                    message.opened = true;
                }
                appState.setMessages(appMessages);
            });
    };

    /**
     * Callback to switch between message view and reply components
     *
     * @return {void}
     */
    toggleReplyState = () => {
        this.setState((prevState) => {
            return {
                isReplying: !prevState.isReplying,
            };
        });
    };

    /**
     * Renders the page depending on state (view or reply)
     *
     * @return {ReactElement} The component decided by state
     */
    renderPageContent = () => {
        const { isReplying } = this.state;
        const { accentColour } = this.props.appState;

        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        return (isReplying)
            ? (
                <MessageReply
                    message={this.state.message}
                    toggleReplyState={this.toggleReplyState}
                    buttonStyles={buttonStyles}
                />
            )
            : (
                <MessageView
                    message={this.state.message}
                    toggleReplyState={this.toggleReplyState}
                    buttonStyles={buttonStyles}
                />
            );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { subject, timeSent } = this.state.message;

        return (
            <AnimationContainer
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <DatedPageLayout text={subject} date={timeSent}>
                    {this.renderPageContent()}
                </DatedPageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(MessageSingle));
