import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import api from "lib/api";
import userStateMethods from "state/User";
import { PrimaryButton } from '@dataplan/react-components/dist/components/forms';
import text from './AccountSetupComplete.json';
import AccountSetupCompleteContent from "./AccountSetupCompleteContent.js";
import IntroCard from "../IntroCard.js";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';

import styles from './AccountSetupComplete.module.scss';

/**
 * Renders the welcome message component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const AccountSetupComplete = ({ history }) => {
    const { appState, userState } = useSelector((state) => state);
    const { brandColour: backgroundColor } = appState;

    /**
     * Notifies the api that onboarding has been seen
     *
     * @return {void}
     */
    const setOnboardingSeen = () => {
        api.patch('/user/seen_onboarding', {
            user: userState,
        }).then(({ data }) => {
            userStateMethods.setUserDetails(data);
        });
    };

    /**
     * Directs the user to the home
     *
     * @return {void}
     */
    const onSubmit = () => {
        setOnboardingSeen();
        history.push("/");
    };

    const buttonStyles = {
        backgroundColor,
        color: getMostContrastingColour(backgroundColor, contrastingColourList),
    };

    return (
        <IntroCard>
            <AccountSetupCompleteContent />
            <PrimaryButton
                className={styles.startButton}
                onClick={onSubmit}
                style={buttonStyles}
                type="submit"
                text={text.button}
            />
        </IntroCard>
    );
};

AccountSetupComplete.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(AccountSetupComplete);
