import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { AnimationContainer } from "@dataplan/react-components/dist/components";
import { KeyInfo } from "components/";
import ShowMoreButton from "./ShowMoreButton.js";
import { history } from "../../redux-store";

import styles from "./Home.module.scss";

/**
 * Renders the key info section
 *
 * @return {ReactElement} The key info section
 */
const KeyInfoSection = ({ fullPayslip, fullPayslipLoaded }) => {
    const { firstLoad } = useSelector(({ appState }) => appState);
    const appearTimeout = (firstLoad) ? 800 : 500;

    return (
        <div className={styles.footerSection}>
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={appearTimeout}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <h2 className={styles.sectionHeader}>
                    Key Info
                </h2>
                <div className={styles.keyInfoContainer}>
                    <KeyInfo
                        headerClass={styles.sectionHeader}
                        documentClass={styles.keyInfoContainer}
                        payslip={fullPayslip}
                        fullPayslipLoaded={fullPayslipLoaded}
                    />
                </div>
            </AnimationContainer>
            <ShowMoreButton text={"View"} onClick={() => history.push("/my-details")} />
        </div>
    );
};

KeyInfoSection.propTypes = {
    fullPayslip: PropTypes.object,
    fullPayslipLoaded: PropTypes.bool,
};

KeyInfoSection.defaultProps = {
    fullPayslip: null,
    fullPayslipLoaded: false,
};

export default KeyInfoSection;
