import React from "react";
import PropTypes from "prop-types";
import TipologyAccountRecovery from "@dataplan/react-components/dist/components/ui/login_form/TipologyAccountRecovery";
import { getTestLabel } from "@dataplan/react-components/dist/lib";
import { attachStates } from "utils/ReduxUtils";
import userState from "state/User";
import appState from "state/App";
import { PageFrame } from "components";

import styles from "./AccountRecovery.module.scss";

class AccountRecovery extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    /**
     * Renders the account recovery card
     *
     * @returns {React.Component} The account recovery card
     */
    render () {
        const isTipology = window.ENV.PLATFORM === "tipology";

        return (
            <div className={styles.container}>
                <div className={styles.loginForm} data-cy={getTestLabel('loginForm')}>
                    <PageFrame>
                        {
                            isTipology ? (
                                <TipologyAccountRecovery backButtonClick={() => this.props.history.push('/login')} />
                            ) : (null)
                        }
                    </PageFrame>
                </div>
            </div>
        );
    }

}

export default attachStates([appState, userState], AccountRecovery);
