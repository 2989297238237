import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { AnimationContainer } from "@dataplan/react-components/dist/components";
import { Announcements } from "components/";
import ShowMoreButton from "./ShowMoreButton.js";
import { history } from "../../redux-store";

import styles from "./Home.module.scss";

/**
 * Renders the notifications section
 *
 * @return {ReactElement} The notifications section
 */
const NotificationsSection = ({ maxNotifications }) => {
    const { announcements, firstLoad } = useSelector(({ appState }) => appState);
    const appearTimeout = (firstLoad) ? 1100 : 800;
    const isMoreNotifications = (announcements.length > maxNotifications);

    return (
        <div className={styles.footerSection}>
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={appearTimeout}
                enterTimeout={1000}
                exitTimeout={100}
                notifications={true}
            >
                <h2 className={styles.sectionHeader}>
                    Notifications
                </h2>
                <div className={styles.notificationsContainer}>
                    <Announcements
                        isAnimated={true}
                        animationDelay={appearTimeout}
                        announcements={announcements}
                        quantity={maxNotifications}
                    />
                </div>
            </AnimationContainer>
            {(isMoreNotifications
                && <ShowMoreButton text={"Show more"} onClick={() => history.push("/notifications")} />)}
        </div>
    );
};

NotificationsSection.propTypes = {
    maxNotifications: PropTypes.number,
};

NotificationsSection.defaultProps = {
    maxNotifications: 3,
};

export default NotificationsSection;
