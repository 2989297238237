import api from "lib/api";

export default class Forms {

    /**
     * @param {StateAccessor} appState The appState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the form data
     *
     * @return {Promise} A promise that resolves with the form data
     */
    load () {
        return api.get("/forms")
            .then(({data}) => this.set(data));
    }

    /**
     * Handles the form data loaded from the API
     *
     * @param {array} forms The form data for the current user
     *
     * @return {void}
     */
    set (forms) {
        this.appState.setForms(forms);
    }

}
