import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./AppHeaderButton.module.scss";
import { ArrowDropDownIcon } from "@dataplan/react-components/dist/components/icons";

/**
 * Renders an app header button component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const AppHeaderButton = ({ arrowIcon, className, children, forwardedRef, ...props }) => {
    const buttonClasses = classNames(
        styles.button,
        className,
        {
            [styles.wide]: arrowIcon,
        },
    );

    return (
        <button
            className={buttonClasses}
            ref={forwardedRef}
            type="button"
            {...props}
        >
            {children}
            {arrowIcon && <ArrowDropDownIcon width={24} height={24} className={styles.arrowIcon} />}
        </button>
    );
};

AppHeaderButton.propTypes = {
    arrowIcon: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any,
    forwardedRef: PropTypes.any,
};

AppHeaderButton.defaultProps = {
    arrowIcon: false,
    className: null,
    children: null,
    forwardedRef: null,
};

// eslint-disable-next-line react/no-multi-comp
export default React.forwardRef((props, ref) => (
    <AppHeaderButton forwardedRef={ref} {...props} />
));
