import _ from 'lodash';
import api from "../api";

/**
 * Helper class for consistently opening files, using the browsers default method
 *
 * @param {string} url The file data
 * @param {object} attributes The anchor tag attributes to be included
 * @param {ref} targetNode The anchor tag attributes to be included
 *
 * @return {void}
 */
const handleFile = (url, attributes, targetNode = document.body) => {
    // preflight check to make sure user is still logged in - interceptor logs user out if token has expired
    api.get('/auth')
        .then(() => {
            const link = document.createElement('a');

            const setAttributes = {
                href: url,
                ...attributes,
            };

            if (setAttributes.target === '_blank') {
                setAttributes.rel = 'noopener noreferrer';
            }

            _.forOwn(setAttributes, (value, key) => {
                link.setAttribute(key, value);
            });

            targetNode.appendChild(link);
            link.click();
            targetNode.removeChild(link);

            window.URL.revokeObjectURL(url);
        });
};

export default handleFile;
