import api from "lib/api";

export default class Messages {

    /**
     * @param {StateAccessor} appState The global app state
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the payslips
     *
     * @return {Promise} A promise that resolves with the data
     */
    load () {
        return api.get('/legacy_messages', {
            params: {
                "show_attachment_count": true,
            },
        }).then((response) => {
            this.appState.setMessages(response.data);
        });
    }

}
