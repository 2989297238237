import StateAccessor from './StateAccessor';
import PropTypes from 'prop-types';
import { isArray } from "lodash-es";

class User extends StateAccessor {

    /**
     * Creates an instance of the app state container
     */
    constructor () {
        super("User");
    }

    /**
     * Gets the intial state
     *
     * @return {object} The state object
     */
    getInitialState () {
        return {
            loggedIn: false,
            forename: "",
            surname: "",
            email: "",
            mobileNumber: "",
            username: "",
            company: "",
            payrollRef: null,
            address: {},
            ni: "",
        };
    }

    /**
     * Gets the propTypes description of the state
     *
     * @return {object} The description
     */
    getPropTypes () {
        return {
            loggedIn: PropTypes.bool,
            forename: PropTypes.string,
            surname: PropTypes.string,
            email: PropTypes.string,
            mobileNumber: PropTypes.string,
            username: PropTypes.string,
            company: PropTypes.string,
            payrollRef: PropTypes.number,
            address: PropTypes.object,
            ni: PropTypes.string,
        };
    }

    /**
     * Sets the current user details
     *
     */
    setUserEnteredApp () {
        this.setState((prevState) => {
            return {
                ...prevState,
                loggedIn: true,
            };
        });
    }

    /**
     * Sets the profile details of the current user
     *
     * @param {object} profile The users profile data
     *
     * @return {void}
     */
    setUserDetails (profile) {
        const {
            forename,
            surname,
            email,
            mobile_number: mobileNumber,
            username,
            company,
            payroll_ref: payrollRef,
            ni,
            new_epayslips_onboarding_seen, // eslint-disable-line camelcase
            mfa_totp, // eslint-disable-line camelcase
            last_login, // eslint-disable-line camelcase
            evc_enabled, // eslint-disable-line camelcase
            evc_request_deletion, // eslint-disable-line camelcase
            evc_sar_enabled, // eslint-disable-line camelcase
        } = profile;

        const address = (isArray(profile.address) && profile.address.length === 0)
            ? {}
            : profile.address;

        this.setState((prevState) => {
            return {
                ...prevState,
                address,
                forename,
                surname,
                email,
                mobileNumber,
                username,
                company,
                payrollRef,
                ni,
                new_epayslips_onboarding_seen, // eslint-disable-line camelcase
                mfa_totp, // eslint-disable-line camelcase
                last_login, // eslint-disable-line camelcase
                evc_enabled, // eslint-disable-line camelcase
                evc_request_deletion, // eslint-disable-line camelcase
                evc_sar_enabled, // eslint-disable-line camelcase
            };
        });
    }

    /**
     * Sets the user loggedIn state
     *
     * @return {void}
     */
    setLoggedOut () {
        this.setState((prevState) => {
            return {
                ...prevState,
                loggedIn: false,
            };
        });
    }

}

export default new User();
