import "react-app-polyfill/ie11";
import "iterators-polyfill";
import "core-js/features/array/find";
import "core-js/features/object/values";
import "core-js/features/object/entries";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import App from "./App";
import { MatomoProvider, createInstance } from "@mitresthen/matomo-tracker-react";
import { FocusHandler } from "components/";
import ErrorBoundary from "./utils/ErrorBoundary";
import { Provider } from "react-redux";
import { store, history } from "./redux-store";
import { ConnectedRouter } from "connected-react-router";
import { isUnauthError } from "lib/api";

import "normalize.css/normalize.css";

const development = window.ENV.ENVIRONMENT === "development";

if (!development) {
    Sentry.init({
        dsn: "https://a34064f527204ba0b2f5e2ee11163a8b@crash.datacode.dev/21",
        beforeSend: (event, hint) => (
            (isUnauthError(hint.originalException)) ? null : event
        ),
    });
}

const instance = createInstance({
    urlBase: "https://analytics.datacode.dev/",
    siteId: 7,
    disabled: development,
    heartBeat: {
        seconds: 10,
    },
});

ReactDOM.render(
    <MatomoProvider value={instance}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <FocusHandler>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </FocusHandler>
            </ConnectedRouter>
        </Provider>
    </MatomoProvider>,
    document.getElementById("root"),
);
