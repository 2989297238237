import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import _ from "lodash";
import AccordionHeader from "./AccordionHeader.js";

import styles from "./Accordion.module.scss";

class Accordion extends React.Component {

    static propTypes = {
        arrowIcon: PropTypes.bool,
        children: PropTypes.any,
        className: PropTypes.string,
        headerContent: PropTypes.any,
        headerHeight: PropTypes.string,
        title: PropTypes.string,
        openUpwards: PropTypes.bool,
        switchTab: PropTypes.func,
    };

    static defaultProps = {
        arrowIcon: true,
        children: null,
        className: null,
        headerContent: null,
        headerHeight: '75px',
        title: null,
        openUpwards: false,
        switchTab: null,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.resizeThrottle = _.throttle(this.handleResize, 100);
        this.bodyRef = React.createRef();

        this.state = {
            open: false,
            bodyHeight: '0px',
        };
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount = () => {
        this.setBodyHeight();

        window.addEventListener('resize', this.resizeThrottle);
    };

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount = () => {
        window.removeEventListener('resize', this.resizeThrottle);
    };

    /**
     * Called when the component receives new props
     *
     * @param {object} prevProps The components previous properties
     *
     * @return {void}
     */
    componentDidUpdate (prevProps) {
        if (this.props.children !== prevProps.children) {
            this.setBodyHeight();
        }
    }

    /**
     * Checks if the body has resized
     *
     * @return {void}
     */
    handleResize = () => {
        const { open } = this.state;

        if (open) {
            this.setBodyHeight();
        }
    };

    /**
     * Sets the height of the body in state
     */
    setBodyHeight = () => {
        const body = this.bodyRef.current;

        if (body) {
            const bodyStyleHeight = body.style.height;
            body.style.height = 'auto';
            const bodyHeight = `${body.getBoundingClientRect().height}px`;
            body.style.height = bodyStyleHeight;

            if (bodyHeight !== this.state.bodyHeight) {
                this.setState({
                    bodyHeight,
                });
            }
        }
    };

    /**
     * Toggles / sets if the accordion is open
     *
     * @param {boolean} setOpen Optional argument to set open or not
     */
    toggleOpen = (setOpen) => {
        const vid = document.getElementById(this.props.title);

        if (vid) {
            vid.pause();
        }

        this.setState((prevState) => {
            const open = (_.isBoolean(setOpen))
                ? setOpen
                : !prevState.open;

            return {
                open,
            };
        });
    };

    /**
     * Renders the accordion header
     *
     * @return {object} The accordion header
     */
    renderHeader = () => {
        const {
            arrowIcon,
            title,
            headerContent,
            headerHeight,
            switchTab,
        } = this.props;
        const { open } = this.state;

        let onClick;
        if (switchTab) {
            const tab = (open) ? null : { title, ref: this };
            onClick = () => switchTab(tab);
        } else {
            onClick = this.toggleOpen;
        }

        return (
            <AccordionHeader
                enableArrowIcon={arrowIcon}
                height={headerHeight}
                isOpen={open}
                onClick={onClick}
                title={title}
            >
                {headerContent}
            </AccordionHeader>
        );
    };

    /**
     * Renders the accordion body
     *
     * @return {object} The accordion body
     */
    renderBody = () => {
        const { children } = this.props;
        const { open, bodyHeight } = this.state;
        const height = (open) ? bodyHeight : '0px';

        return (
            <div
                className={styles.body}
                ref={this.bodyRef}
                style={{ height }}
            >
                {children}
            </div>
        );
    };

    /**
     * Renders the accordion
     *
     * @return {ReactElement} The accordion
     */
    render () {
        const { className, openUpwards } = this.props;

        const accordionClasses = classNames(className, styles.accordion, {
            [styles.openUpwards]: openUpwards,
        });

        return (
            <div className={accordionClasses} >
                {this.renderHeader()}
                {this.renderBody()}
            </div>
        );
    }

}

export default Accordion;
