import React from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import _ from "lodash";

import IntroCard from "../IntroCard.js";
import AccountRecoveryForm from "../../AccountRecoveryForm";
import securityGraphic from "./security-24px.svg";
import api from "../../../lib/api";
import { createErrorToasts, refreshUserDetails } from "lib/";

import styles from './SecurityIntro.module.scss';

/**
 * Renders the security intro component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const SecurityIntro = ({ navigate }) => {

    /**
     * Helper: security form setup allows email or mobile changes so
     * we need to check it has been changed to prevent a needless request
     *
     * @param {object} email The email object from the form input
     * @param {string} password The password input by the user
     *
     * @return {Promise|null} The request to execute
     */
    const saveEmailChanges = (email, password) => {
        const { value, changed } = email;

        if (!changed) {
            return null;
        }

        return api.patch('/security/email', {
            password,
            email: value,
            "email_confirm": value,
        });
    };

    /**
     * Helper: security form setup allows email or mobile changes so
     * we need to check it has been changed to prevent a needless request
     *
     * @param {object} mobile The mobile object from the form input
     * @param {string} password The password input by the user
     *
     * @return {Promise|null} The request to execute
     */
    const saveMobile = (mobile, password) => {
        const { value, changed } = mobile;

        if (!changed) {
            return null;
        }

        return api.patch('/security/mobile_number', {
            password,
            "mobile_number": value,
            "mobile_number_confirm": value,
        });
    };

    /**
     * Gets the appropriate requests to execute based on the form input (filters out values not changed)
     * and then submits the changes to the API. Upon completion will navigate to the next security step
     *
     * @param {object} mobile The email object
     * @param {object} email The email object
     * @param {string} password The password input by the user
     *
     * @return {void} Updates user details changes and continues to next step
     */
    const saveSecurityChanges = (mobile, email, password) => {
        const detailChangeRequests = _.filter([
            saveEmailChanges(email, password),
            saveMobile(mobile, password),
        ]);

        axios.all(detailChangeRequests)
            .then(() => refreshUserDetails().then(() => navigate(1)))
            .catch((apiError) => {
                createErrorToasts(apiError.response.data);
            });
    };

    return (
        <IntroCard onSkip={() => navigate(1)}>
            <div className={styles.header}>
                Security
            </div>
            <div className={styles.body}>
                <AccountRecoveryForm onSubmit={saveSecurityChanges} isWelcomePage={true} />
                <img
                    alt="securityGraphic"
                    className={styles.graphic}
                    src={securityGraphic}
                />
            </div>
        </IntroCard>
    );
};

SecurityIntro.propTypes = {
    navigate: PropTypes.func.isRequired,
};

export default SecurityIntro;
