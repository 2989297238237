import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./CalendarDate.module.scss";

/**
 * Renders a text line
 *
 * @param {string} text The text to display
 * @param {string} className The class to add to the container
 *
 * @return {ReactElement} The text element
 */
const renderTextLine = (text, className) => (
    <div
        className={className}
        mode="single"
        role="presentation"
    >
        {text}
    </div>
);

/**
 * Renders the date component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The date component
 */
const CalendarDate = ({ className, colour, date }) => ( // eslint-disable-line react/no-multi-comp
    <time
        dateTime={date.format("YYYY-MM-DDTHH:mm:ssZ")}
        className={classNames(styles.container, className)}
        style={{ color: colour }}
    >
        {renderTextLine(date.format("MMM"), styles.month)}
        {renderTextLine(date.format("DD"), styles.day)}
        {renderTextLine(date.format("YYYY"), styles.year)}
    </time>
);

CalendarDate.propTypes = {
    className: PropTypes.string,
    colour: PropTypes.string,
    date: PropTypes.object.isRequired,
};

CalendarDate.defaultProps = {
    className: null,
    colour: "#fff",
};

export default CalendarDate;
