import React from "react";
import styles from './EmptyAnnouncement.module.scss';

class EmptyAnnouncement extends React.Component {

    /**
     * Renders the empty notifications when there is no notifications
     *
     * @return {void}
     */
    render () {
        return (
            <>
                <div className={styles.card}>
                    <div className={styles.notification}>No Notifications</div>
                </div>
                <div className={styles.card}>
                    <div className={styles.notification} />
                </div>
                <div className={styles.card}>
                    <div className={styles.notification} />
                </div>
            </>
        );
    }

}

export default EmptyAnnouncement;
