import api from 'lib/api';
import DeviceTokenManager from "../DeviceTokenManager";

export default class Profile {

    /**
     * @param {StateAccessor} userState The userState
     * @param {object} params A mixture of options
     */
    constructor (userState, params = {}) {
        this.userState = userState;
        this.params = params;
    }

    /**
     * Handles loading of the profile data
     *
     * @return {Promise} A promise that resolves with the profile data
     */
    load () {
        const { isTrusted = false } = this.params;
        const loginCount = localStorage.getItem("loginCount");
        const newLoginCount = loginCount ? parseInt(loginCount, 10) + 1 : 1;

        localStorage.setItem("loginCount", newLoginCount);

        return api.get("/user/profile")
            .then((profile) => {
                const deviceTokenManager = new DeviceTokenManager();

                deviceTokenManager.logDevice(isTrusted, () => {
                    this.set(profile);
                });
            });
    }

    /**
     * Handles the profile data loaded from the API
     *
     * @param {object} profile The axios response
     *
     * @return {void}
     */
    set (profile) {
        this.userState.setUserDetails(profile.data);
    }

}
