import api, { apiUrl } from "../api";
import handleFile from './handleFile';

import { history } from "../../redux-store";

class Attachment {

    /**
     * Constructor class for handling attachments
     *
     * @param {object} attachment Attachment data
     */
    constructor (attachment) {
        this.id = attachment.id;
        this.fileName = attachment.filename;
        this.accessToken = attachment.access_token;
    }

    /**
     * Helper function to download a given file. Triggers a download event in IE or opens the
     * file in a new tab in other browsers
     *
     * @return {void}
     */
    download () {
        const url = `/raw/attachment/${this.id}?token=${this.accessToken}`;

        api.get(url)
            .then(() => {
                handleFile(`${apiUrl}${url}`);
            })
            .catch(() => {
                history.push('/logout');
            });
    }

}

export default Attachment;
