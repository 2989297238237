import React from "react";
import { PayslipCard } from "@dataplan/react-components/dist/components/ui/cards/payslip_card";

import styles from "./EmptyPayslip.module.scss";

/**
 * Renders the payslip component
 *
 * @return {ReactElement} The component
 */
const EmptyPayslip = () => (
    <PayslipCard>
        <div className={styles.payslipCover}>
            <div className={styles.payslipAlert}>Your payslips will show here</div>
        </div>
    </PayslipCard>
);

export default EmptyPayslip;
