import React from "react";
import PropTypes from 'prop-types';
import appState from "state/App";
import { getAppName } from "lib/";

import { FilterTab } from '@dataplan/react-components/dist/components/ui/tabs';
import { TertiaryButton } from '@dataplan/react-components/dist/components/forms/controls';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import {
    Announcements,
    CardsFrame,
    PageFrame,
    PageHeader,
} from "components/";

import styles from './Notifications.module.scss';

class Notifications extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.pageName = "Notifications";
        this.pageQuantity = 7;

        this.state = {
            announcements: this.props.appState.announcements.slice().reverse(),
            maxDisplayed: this.pageQuantity,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Handles filtering of announcements when tab is changed
     *
     * @param {integer} tabIndex The tab index
     *
     * @return {void}
     */
    tabChangeHandler = (tabIndex) => {
        let announcements = this.props.appState.announcements.slice().reverse();

        if (tabIndex && tabIndex <= 2) {
            announcements = [
                announcements,
                announcements.filter((ann) => ann.notice_read),
                announcements.filter((ann) => !ann.notice_read),
            ][tabIndex];
        }

        this.setState({
            announcements: [],
            maxDisplayed: this.pageQuantity,
        }, () => {
            this.setState({
                announcements,
            });
        });
    };

    /**
     * Conditional render the tab content based on document collection
     *
     * @return {ReactElement} The filter tabs
     */
    renderTabs () {
        const { accentColour } = this.props.appState;
        const tabContent = [
            {text: 'All'},
            {text: 'Read'},
            {text: 'Unread'},
        ];

        return (
            <div className={styles.filterBar}>
                <FilterTab
                    tabContent={tabContent}
                    accentColour={accentColour}
                    onTabChange={this.tabChangeHandler}
                    defaultColor={"#696969"}
                    selectedColor={"#444444"}
                />
            </div>
        );
    }

    /**
     * Render show more button if there are any announcements not displayed
     *
     * @return {ReactElement} The show more button
     */
    renderShowMoreButton () {
        const { announcements, maxDisplayed } = this.state;
        const { accentColour } = this.props.appState;

        if (announcements.length > maxDisplayed) {
            return (
                <TertiaryButton
                    accent={accentColour}
                    text="Show more"
                    onClick={this.showMoreNotifications}
                />
            );
        }

        return null;
    }

    /**
     * Increases amount of notifications that are displayed on the page
     */
    showMoreNotifications = () => {
        this.setState((prevState) => {
            return {
                maxDisplayed: (prevState.maxDisplayed + this.pageQuantity),
            };
        });
    };

    /**
     * Renders the forms page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { announcements, maxDisplayed } = this.state;

        return (
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <PageFrame>
                    <PageHeader
                        title={this.pageName}
                        wrapperClass={styles.pageHeader}
                        headingClass={styles.heading}
                    >
                        {this.renderTabs()}
                    </PageHeader>
                    <CardsFrame className={styles.notificationsContainer}>
                        <Announcements
                            announcements={announcements}
                            isAnimated={true}
                            pageQuantity={this.pageQuantity}
                            quantity={maxDisplayed}
                        />
                        {this.renderShowMoreButton()}
                    </CardsFrame>
                </PageFrame>
            </AnimationContainer>
        );
    }

}

export default appState.attachState(Notifications);
