import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import appState from "state/App";
import userState from "state/User";
import { attachStates } from "utils/ReduxUtils";
import { ToggleSwitchRow } from '@dataplan/react-components/dist/components/forms';
import { TertiaryButton } from '@dataplan/react-components/dist/components/forms/controls';
import { ArrowDropDownIcon } from '@dataplan/react-components/dist/components/icons';

import api from "lib/api";
import { createErrorToasts } from "lib/";

import styles from './Preferences.module.scss';

class UserPreferences extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the change username page
     *
     * @param {object} props Change username page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            userPreferenceInputs: props.appState.appSetting,
            dropdownOpen: false,
        };
    }

    /**
     * Renders the toggle switch
     *
     * @return {ReactElement} The toggle switch component
     */
    renderToggleSwitch () {
        const { userPreferenceInputs } = this.state;
        const { accentColour } = this.props.appState;

        return (
            <div className={styles.toggleContainer}>
                <ToggleSwitchRow
                    accentColour={accentColour}
                    handleChange={(value) => this.handlePayslipCoverInput("payslip_privacy", value)}
                    label={"Payslip Privacy"}
                    key={"payslip_privacy"} // eslint-disable-line camelcase
                    initialValue={userPreferenceInputs.payslip_privacy} // eslint-disable-line camelcase
                />
            </div>
        );
    }

    /**
     * Called when a value of the toggle switch is changed
     *
     * @param {string} appSettingRef which toggle has been changed
     * @param {bool} value the value of the toggle
     *
     * @return {void}
     */
    handlePayslipCoverInput = (appSettingRef, value) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                userPreferenceInputs: {
                    ...prevState.userPreferenceInputs,
                    [appSettingRef]: value,
                },
            };
        }, () => {
            this.handleSubmit(this.state.userPreferenceInputs);
        });
    };

    /**
     * Handles the saving of changes
     *
     * @param {object} preference which toggle has been changed
     *
     * @return {void}
     */
    handleSubmit = (preference) => {
        api.put('app_settings/epayslips', {
            settings: preference,
        })
            .then(({ data }) => {
                appState.setAppSettings(data);
            }).catch((apiError) => {
                createErrorToasts(apiError.response.data);
            });
    };

    /**
     * Toggles / sets if the drop down is open
     *
     * @return {void}
     */
    toggleDropdownOpen = () => {
        this.setState((prevState) => {
            return {
                dropdownOpen: !prevState.dropdownOpen,
            };
        });
    };

    /**
     * Renders the more info description
     *
     * @return {ReactElement} The more info description
     */
    renderMoreInfoDescription () {
        const { dropdownOpen } = this.state;

        const descriptionClasses = classNames(styles.text, {
            [styles.hidden]: !dropdownOpen,
        });

        return (
            <div className={descriptionClasses}>
                {`This setting will hide pay-related information from the home screen, keeping your details
                private. You can see your pay information by clicking on the Latest Payslips 'View Pay'
                link or via the Payslips page.`}
            </div>
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { accentColour } = this.props.appState;
        const arrowStyles = (this.state.dropdownOpen) ? styles.arrowOpen : styles.arrowClosed;

        return (
            <>
                {this.renderToggleSwitch()}
                <TertiaryButton
                    accent={accentColour}
                    onClick={() => this.toggleDropdownOpen()}
                    text="What is Payslip Privacy?"
                    aria-label="More info"
                />
                <ArrowDropDownIcon width={24} height={24} className={arrowStyles} />
                {this.renderMoreInfoDescription()}
            </>
        );
    }

}

export default attachStates([appState, userState], UserPreferences);
