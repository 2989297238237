import React from "react";
import { useSelector } from "react-redux";
import { AnimationContainer } from "@dataplan/react-components/dist/components";
import { EmptyPayslip, PayslipCover, PayslipStatic } from "components/";

import styles from "./Home.module.scss";

/**
 * Renders the latest payslip section
 *
 * @return {ReactElement} The latest payslip section
 */
const LatestPayslipSection = () => {
    const { appSetting, firstLoad, latestPayslip, payslips } = useSelector(({ appState }) => appState);
    const appearTimeout = (firstLoad) ? 500 : 200;
    const isEmpty = (payslips.length === 0);
    const isPrivate = appSetting.payslip_privacy;
    let content;

    if (isEmpty) {
        content = <EmptyPayslip />;
    } else if (isPrivate) {
        content = <PayslipCover />;
    } else {
        content = <PayslipStatic payslip={latestPayslip} />;
    }

    return (
        <div className={styles.payslipSection}>
            <AnimationContainer
                animationStyle="animationContainer"
                appearTimeout={appearTimeout}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <h1 className={styles.sectionHeader}>Latest Payslip</h1>
                {content}
            </AnimationContainer>
        </div>
    );
};

export default LatestPayslipSection;
