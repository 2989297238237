import PropTypes from 'prop-types';

const singleMessagePropTypes = {
    message: PropTypes.shape({
        id: PropTypes.number,
        from: PropTypes.string,
        recipient: PropTypes.string,
        subject: PropTypes.string,
        message: PropTypes.string,
        timeSent: PropTypes.string,
        opened: PropTypes.bool,
        attachments: PropTypes.array,
    }).isRequired,
    toggleReplyState: PropTypes.func.isRequired,
    buttonStyles: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
    }),
};

const singleMessageDefaultProps = {
    message: {},
    toggleReplyState: null,
    buttonStyles: {
        backgroundColor: '#eee',
        color: '#FFF',
    },
};

const messagesPropTypes = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    from: PropTypes.string,
    recipient: PropTypes.string,
    subject: PropTypes.string,
    timeSent: PropTypes.string,
    opened: PropTypes.bool,
    attachments: PropTypes.number,
}));

const messagesDefaultProps = [];

export { singleMessagePropTypes, singleMessageDefaultProps, messagesPropTypes, messagesDefaultProps };
