import React from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import appState from "state/App";
import { TertiaryButton, PrimaryButton } from "@dataplan/react-components/dist/components/forms/controls";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList, getAppName } from 'lib/';
import { ArrowDropDownIcon } from "@dataplan/react-components/dist/components/icons";

import styles from './AuthenticationForm.module.scss';

class AuthenticationForm extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        enableSkip: PropTypes.bool,
        history: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        enableSkip: false,
    };

    /**
     * Creates a new instance of the component
     *
     * @param {object} props Input props
     * @return {void}
     */
    constructor (props) {
        super(props);
        this.emailRef = React.createRef();
        this.numberRef = React.createRef();

        this.state = {
            open: false,
        };
    }

    /**
     * Toggles / sets if the drop down is open
     *
     * @param {boolean} setOpen Optional argument to set open or not
     */
    toggleOpen = (setOpen) => {
        this.setState((prevState) => {
            const open = (_.isBoolean(setOpen))
                ? setOpen
                : !prevState.open;

            return {
                open,
            };
        });
    };

    /**
     * Renders the turn on button
     *
     * @return {ReactElement} The turn on button
     */
    renderTurnOnButton () {
        const { onSubmit } = this.props;
        const { brandColour } = this.props.appState;

        const buttonStyles = {
            backgroundColor: brandColour,
            color: getMostContrastingColour(brandColour, contrastingColourList),
        };

        return (
            <PrimaryButton
                style={buttonStyles}
                text="Turn On"
                type="submit"
                onClick={onSubmit}
            />
        );
    }

    /**
     * Renders the more info description
     *
     * @return {ReactElement} The more info description
     */
    renderMoreInfoDescription () {
        const { open } = this.state;
        const description = "Multi-factor authentication (MFA) is used to ensure that users are "
            + "who they say they are by requiring that they provide at least two pieces of evidence "
            + "to prove their identity when logging into the system. Whenever you sign in to your "
            + `${getAppName()} account, you'll enter your password as usual followed by a code sent by an `
            + "authentication app on your mobile.";
        const descriptionStyle = (open) ? styles.tertText : styles.tertTextHidden;

        return <div className={descriptionStyle}>{description}</div>;
    }

    /**
     * Renders the more info component
     *
     * @return {ReactElement} The more info component
     */
    renderMoreInfo () {
        const { open } = this.state;
        const { accentColour } = this.props.appState;
        const arrowStyles = (open) ? styles.arrowOpen : styles.arrowClosed;

        return (
            <>
                <TertiaryButton
                    accent={accentColour}
                    onClick={this.toggleOpen}
                    text="How does MFA work?"
                    aria-label="More info"
                />
                <ArrowDropDownIcon width={24} height={24} className={arrowStyles} />
                {this.renderMoreInfoDescription()}
            </>
        );
    }

    /**
     * Renders the buttons in the footer
     *
     * @return {ReactElement} The buttons in the footer
     */
    renderFooterButtons () {
        const { accentColour } = this.props.appState;

        return (
            <div className={styles.footerButtons}>
                <TertiaryButton
                    accent={accentColour}
                    onClick={() => this.props.history.push('/')}
                    text="Skip"
                    aria-label="Skip"
                    className={styles.skipButton}
                />
            </div>
        );
    }

    /**
     * Renders the MFA message depending on the payroll mandatory status
     *
     * @return {string} MFA notification
     */
    renderMFAMessage () {
        const { payroll } = this.props.appState;

        const message = [
            `Multi-Factor Authentication increases the security of your ${getAppName()} account.`,
        ];

        if (payroll.mandatory_mfa) {
            message.unshift("Your employer has mandated that you set up Multi-Factor Authentication (MFA).");
        }

        return message.join(" ");
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { enableSkip } = this.props;

        return (
            <>
                <h2 className={styles.cardText}>
                    {this.renderMFAMessage()}
                </h2>
                <div className={styles.secondaryContent}>
                    {this.renderMoreInfo()}
                </div>
                <div className={styles.tertiaryContent}>
                    {this.renderTurnOnButton()}
                </div>
                {enableSkip && (
                    <div>
                        {this.renderFooterButtons()}
                    </div>
                )}
            </>
        );
    }

}

export default withRouter(appState.attachState(AuthenticationForm));
