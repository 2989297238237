import api from 'lib/api';

export default class Notifications {

    /**
     * @param {StateAccessor} appState The appState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the announcements data
     *
     * @return {Promise} A promise that resolves with the announcements data
     */
    load () {
        return api.get('/announcements')
            .then((announcements) => this.set(announcements))
            .catch((error) => {
                // If employee is a leaver, API returns forbidden
                // since they aren't allowed to see announcements
                if (error.response && error.response.status === 403) {
                    this.set({});
                } else {
                    throw new Error(error.message);
                }
            });
    }

    /**
     * Handles the announcements data loaded from the API
     *
     * @param {object} announcements The axios response
     *
     * @return {void}
     */
    set (announcements) {
        this.appState.setAnnouncements(announcements.data);
    }

}
