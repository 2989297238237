import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import BackButton from "./BackButton.js";
import HelpButton from "./HelpButton.js";
import AppButton from "./AppButton.js";
import NotificationsMenu from "./NotificationsMenu.js";
import ProfileMenu from "./ProfileMenu.js";
import SideMenuButton from "./SideMenuButton.js";

import styles from "./AppHeader.module.scss";

/**
 * Renders the main app header component
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const AppHeader = ({ sideMenuButtonRef }) => {
    const { appState, userState } = useSelector((state) => state);
    const { loaded, ssoEnabled } = appState;
    const { loggedIn } = userState;

    const headerBarClass = classNames(styles.container, {
        [styles.barLoaded]: (loaded && !loggedIn),
    });

    return (
        <header className={headerBarClass} aria-label="Header">
            <SideMenuButton ref={sideMenuButtonRef} />
            <BackButton />
            <div className={styles.headerIconMenus}>
                <NotificationsMenu />
                <ProfileMenu />
                {!ssoEnabled ? (
                    <AppButton />
                ) : null}
                <HelpButton />
            </div>
        </header>
    );
};

AppHeader.propTypes = {
    sideMenuButtonRef: PropTypes.object.isRequired,
};

export default AppHeader;
