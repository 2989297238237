import React from 'react';
import PropTypes from 'prop-types';
import appState from "state/App";
import api from "lib/api";
import { createErrorToasts, contrastingColourList } from "lib/";
import _ from 'lodash';

import {
    DefaultForm,
    TextInputRow,
    PasswordInputRow,
    PrimaryButton,
} from '@dataplan/react-components/dist/components/forms';
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import { checkValidity, getMostContrastingColour } from '@dataplan/react-components/dist/lib';

import styles from "./SendPayslip.module.scss";

class SendBulkPayslips extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        closeDrawer: PropTypes.func.isRequired,
        selectedPayslipIds: PropTypes.array.isRequired,
        sendFormVisible: PropTypes.bool,
    };

    static defaultProps = {
        sendFormVisible: false,
    };

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.initialState = {
            email: {
                value: "",
                hasChanged: false,
                isValid: false,
            },
            confirmEmail: {
                value: "",
                hasChanged: false,
                isValid: false,
            },
            password: {
                value: "",
                hasChanged: false,
                isValid: false,
            },
            isSending: false,
        };

        this.state = this.initialState;
        this.emailInputRef = React.createRef();
        this.emailConfirmRef = React.createRef();
    }

    /**
     * Handles updating the component
     *
     * @param {object} prevProps The previous props
     */
    componentDidUpdate = (prevProps) => {
        const { sendFormVisible } = this.props;
        const emailInput = this.emailInputRef.current;

        // When the send form is opened, the focus will jump to the email input field
        if (emailInput && sendFormVisible && !prevProps.sendFormVisible) {
            emailInput.focus();
        }
    };

    /**
     * Resets component state to initial state
     *
     * @return {void}
     */
    resetState = () => {
        this.setState(this.initialState);
    };

    /**
     * Called on an input's onChange event
     *
     * @param {event} event The onChange event
     * @param {string} key The value to change
     *
     * @return {void} Modifies state
     */
    handleInputChange = (event, key) => {
        const value = event.target.value;

        const isValid = ((key === "email") || (key === "confirmEmail"))
            ? checkValidity(value, 'email').isValid
            : (value.length > 0);

        this.setState({
            [key]: {
                value,
                hasChanged: true,
                isValid,
            },
        });
    };

    /**
     * Check if the form can be submitted with correct values
     *
     * @return {boolean} If the form can submit or not
     */
    checkCanSubmit () {
        const { email, confirmEmail, password } = this.state;

        return _.every([
            email.hasChanged,
            email.isValid,
            confirmEmail.hasChanged,
            confirmEmail.isValid,
            confirmEmail.value === email.value,
            password.hasChanged,
            password.isValid,
        ]);
    }

    /**
     * Call the API to send the payslips to the email address provided
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    sendPayslips = (event) => {
        event.preventDefault();

        const { selectedPayslipIds, closeDrawer } = this.props;
        const { email, password } = this.state;

        this.setState({ isSending: true });

        api.put("/payslips/email", {
            email: email.value,
            password: password.value,
            // eslint-disable-next-line camelcase
            payslip_ids: selectedPayslipIds,
        }).then(() => {
            appState.addNotification({
                text: "Payslips sent.",
                type: "success",
                duration: 5,
            });

            this.setState(this.initialState, closeDrawer);
        }).catch((error) => {
            this.setState(
                { isSending: false },
                () => createErrorToasts(error?.response?.data ?? "Failed to send payslip"),
            );
        });
    };

    /**
     * Render the email input row
     *
     * @return {ReactElement} The email input row
     */
    renderEmailInput () {
        const { value, hasChanged, isValid } = this.state.email;
        const emailError = (!isValid && hasChanged) ? "Please enter a valid email address" : "";

        return (
            <TextInputRow
                name="emailAddress"
                label="Email Address"
                autoComplete="email"
                value={value}
                errorText={emailError}
                onChange={(event) => this.handleInputChange(event, "email")}
                rowClassName={styles.inputRow}
                forwardedRef={this.emailInputRef}
            />
        );
    }

    /**
     * Render the email confirmation input row
     *
     * @return {ReactElement} The email confirmation input row
     */
    renderEmailConfirmation () {
        const { value, hasChanged, isValid } = this.state.confirmEmail;

        const emailError = (hasChanged && (!isValid || (value !== this.state.email.value)))
            ? "Email addresses must match" : "";

        return (
            <TextInputRow
                name="emailAddress"
                label="Confirm Email Address"
                autoComplete="email"
                value={value}
                errorText={emailError}
                onChange={(event) => this.handleInputChange(event, "confirmEmail")}
                rowClassName={styles.inputRow}
                forwardedRef={this.emailConfirmRef}
            />
        );
    }

    /**
     * Render the password input row
     *
     * @return {ReactElement} The password input row
     */
    renderPasswordInput () {
        const { value, hasChanged, isValid } = this.state.password;
        const passwordError = (!isValid && hasChanged) ? "Password is required" : "";

        return (
            <PasswordInputRow
                name="password"
                label="Set PDF Password"
                autoComplete="new-password"
                value={value}
                errorText={passwordError}
                onChange={(event) => this.handleInputChange(event, "password")}
                rowClassName={styles.inputRow}
            />
        );
    }

    /**
     * Render the form submit button
     *
     * @return {ReactElement} The button
     */
    renderSubmitButton () {
        const { isSending } = this.state;

        if (isSending) {
            return <LoadingSpinner label="Sending" />;
        }

        const { accentColour } = this.props.appState;
        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };
        const canSubmit = this.checkCanSubmit();

        return (
            <PrimaryButton
                type="submit"
                style={(canSubmit) ? buttonStyles : null}
                disabled={!canSubmit}
                text="Send Payslips"
            />
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <DefaultForm onSubmit={this.sendPayslips}>
                <div className={styles.container}>
                    {this.renderEmailInput()}
                    {this.renderEmailConfirmation()}
                    {this.renderPasswordInput()}
                    {this.renderSubmitButton()}
                </div>
            </DefaultForm>
        );
    }

}

export default appState.attachState(SendBulkPayslips);
