import React from 'react';
import PropTypes from 'prop-types';

import { history } from "../../../redux-store";

import { ErrorPageButton } from '.';

class PrevPageButton extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: null,
    };

    /**
     * Handles the button onClick event
     *
     * @param {event} event The onClick event
     *
     * @return {void}
     */
    handleClick = (event) => {
        event.preventDefault();

        if (this.props.onClick) {
            this.props.onClick(event);
        }

        history.goBack();
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <ErrorPageButton onClick={(event) => this.handleClick(event)}>
                Previous Page
            </ErrorPageButton>
        );
    }

}

export default PrevPageButton;
