import axios from 'axios';
import clearSessionData from './clearSessionData';

/* eslint-disable no-undef */
const apiUrl = window.ENV.API_BASE_URL;
/* eslint-enable */

const api = axios.create({
    baseURL: apiUrl,
});

const isUnauthError = (error) => error.response && error.response.status === 401;

// Get cancel token for component
const getCancelToken = (component) => {
    if (!component.cancelToken) {
        // Set cancelToken in component
        component.cancelToken = new axios.CancelToken((cancel) => {
            // Set cancelRequests method of component
            component.cancelRequests = cancel;
        });
    }

    return component.cancelToken;
};

// Add the API auth token to any requests
api.interceptors.request.use(({ component, ...requestConfig }) => {
    requestConfig.headers["X-Token"] = sessionStorage.getItem('apiToken');

    if (component) {
        requestConfig.cancelToken = getCancelToken(component);
    }

    return requestConfig;
}, (error) => {
    return Promise.reject(error);
});

// Catch any unauthorised responses and log the user out
api.interceptors.response.use(null, (error) => {
    if (isUnauthError(error)) {
        clearSessionData();
    }

    return Promise.reject(error);
});

export default api;
export { apiUrl, isUnauthError };
