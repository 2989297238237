import React from 'react';
import PropTypes from 'prop-types';
import userState from "state/User";
import appState from "state/App";
import classNames from 'classnames';
import { attachStates } from "utils/ReduxUtils";
import { ToggleSwitchRow } from '@dataplan/react-components/dist/components/forms';
import { PrimaryButton, TertiaryButton } from '@dataplan/react-components/dist/components/forms/controls';
import { ArrowDropDownIcon } from '@dataplan/react-components/dist/components/icons';
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList, createErrorToasts, refreshUserDetails } from 'lib/';
import { PdfDrawer } from 'components';
import EvcDrawer from './EvcDrawer';
import api, { apiUrl } from "lib/api";

import styles from './EvcSettings.module.scss';

class EVCSettings extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the change username page
     *
     * @param {object} props Change username page properties
     */
    constructor (props) {
        super(props);

        this.settingsDrawer = React.createRef();
        this.deleteDrawer = React.createRef();
        this.sarDrawer = React.createRef();

        this.state = {
            dropdownOpen: false,
            evcEnabled: props.userState.evc_enabled,
            reset: false,
            SARUrl: null,
            SARDrawerVisible: false,
        };
    }

    /**
     * Sets the component default state
     *
     * @return {void}
     */
    resetInitialState = () => {
        const {
            evc_enabled: evcEnabled,
        } = this.props.userState;

        this.setState({
            dropdownOpen: false,
            evcEnabled,
        });
    };

    /**
     * Renders the more info description
     *
     * @return {ReactElement} The more info description
     */
    renderMoreInfoDescription () {
        const { dropdownOpen } = this.state;

        const descriptionClasses = classNames(styles.text, {
            [styles.hidden]: !dropdownOpen,
        });

        return (
            <>
                <div className={descriptionClasses}>
                    The Employee Verification service simplifies the process of applying for loans, mortgages
                    and tenancy agreements by providing a secure online means to instantly verify employment
                    and income information.
                </div>
                <div className={descriptionClasses}>
                    This service removes the manual process of gathering PDF payslips and other employment
                    documents to provide to lenders and other third parties thereby saving time for you
                    and all other parties involved in the verification process.
                </div>
            </>
        );
    }

    /**
     * Toggles / sets if the drop down is open
     *
     * @return {void}
     */
    toggleDropdownOpen = () => {
        this.setState((prevState) => {
            return {
                dropdownOpen: !prevState.dropdownOpen,
            };
        });
    };

    /**
     * Toggle drawer visibility
     *
     * @param {ref} drawerType the type of drawer to open
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = (drawerType) => {
        const drawer = drawerType.current;

        if (drawer) {
            this.setState((prevState) => ({
                evcEnabled: !prevState.evcEnabled,
                reset: false,
            }));

            drawer.toggleDrawer();
        }
    };

    /**
    * Handles the restting of the toggle
     *
     * @param {bool} value whether the toggle should be reset
     *
     * @return {void}
     */
    handleReset = (value) => {
        this.setState({
            reset: value,
        });

        if (value) {
            this.resetInitialState();
        }
    };

    /**
     * Handles the saving of changes
     *
     * @param {string} password the password
     *
     * @return {void}
     */
    handleEVCSettingsChange = async (password) => {

        await api.patch('/employee', {
            password,
            "evc_enabled": this.state.evcEnabled,
        })
            .then(() => {
                appState.addNotification({
                    text: "EVC settings changed successfully.",
                    type: "success",
                    duration: 5,
                });
                refreshUserDetails().then(() => this.handleReset());
            }).catch((apiError) => {
                createErrorToasts(apiError.response.data);
            });
    };

    /**
     * Handles the deletion call for evc
     *
     * @param {string} password the password
     *
     * @return {void}
     */
    handleEvcDeletion = async (password) => {
        await api.delete('/employee/evc', {
            data: {
                password,
            },
        })
            .then(() => {
                appState.addNotification({
                    text: "EVC data deleted successfully.",
                    type: "success",
                    duration: 5,
                });

                refreshUserDetails().then(this.resetInitialState);
            }).catch((apiError) => {
                createErrorToasts(apiError.response.data);
            });
    };

    /**
     * Handles the api call to do an EVC SAR request
     */
    handleEVCASARRequest = () => {
        const employeeId = this.props.appState.latestPayslip.employee.id;

        this.setState({
            SARUrl: `${apiUrl}/employee/${employeeId}/evc_sar_data/pdf`,
        });

        this.toggleDrawer(this.sarDrawer);
        this.setState({SARDrawerVisible: true});
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { dropdownOpen, evcEnabled, reset } = this.state;
        const { accentColour } = this.props.appState;
        const arrowStyles = (dropdownOpen) ? styles.arrowOpen : styles.arrowClosed;

        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        return (
            <>
                <div className={styles.toggleContainer}>
                    <ToggleSwitchRow
                        accentColour={accentColour}
                        handleChange={() => this.toggleDrawer(this.settingsDrawer)}
                        rowClassName={styles.toggle}
                        label={"EVC enabled"}
                        key={"evc_enabled"} // eslint-disable-line camelcase
                        initialValue={evcEnabled}
                        reset={reset}
                    />
                    <TertiaryButton
                        accent={accentColour}
                        onClick={() => this.toggleDropdownOpen()}
                        text="What is EVC?"
                        aria-label="More info"
                    />
                    <ArrowDropDownIcon width={24} height={24} className={arrowStyles} />
                    {this.renderMoreInfoDescription()}
                    <div className={styles.subTitle}>
                        Your Data
                    </div>
                    <div className={styles.text}>
                        Data protection legislation gives you the right to see personal information that
                        EVC holds about you. To view this information, you can make a Subject Access Request.
                    </div>
                    <PrimaryButton
                        style={buttonStyles}
                        className={styles.evcButton}
                        key="evc_request_deletion" // eslint-disable-line camelcase
                        onClick={() => this.toggleDrawer(this.deleteDrawer)}
                        text="Delete my data"
                    />
                    <div className={styles.text}>
                        Make a Subject Access Request.
                    </div>
                    <PrimaryButton
                        style={buttonStyles}
                        className={styles.evcButton}
                        key="evc_sar" // eslint-disable-line camelcase
                        onClick={() => this.handleEVCASARRequest()}
                        text="View SAR request"
                    />
                </div>
                <EvcDrawer
                    ref={this.settingsDrawer}
                    pageName={"EVC settings"}
                    reset={this.handleReset}
                    buttonColour={accentColour}
                    apiCall={this.handleEVCSettingsChange}
                />
                <EvcDrawer
                    ref={this.deleteDrawer}
                    pageName={"EVC deletion"}
                    reset={this.handleReset}
                    buttonColour={accentColour}
                    apiCall={this.handleEvcDeletion}
                />
                <PdfDrawer
                    accentColour={accentColour}
                    pageName={"EVC SAR"}
                    forwardedRef={this.sarDrawer}
                    url={this.state.SARUrl}
                    visible={this.state.SARDrawerVisible}
                    // will need a printUrl prop adding when available
                />
            </>
        );
    }

}

export default attachStates([appState, userState], EVCSettings);
