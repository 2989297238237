import React from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import { AnimationContainer, TertiaryButton } from "@dataplan/react-components/dist/components";
import appStateProp from "state/App";
import { attachStates } from "utils/ReduxUtils";

import styles from "./Home.module.scss";

/**
 * Renders the notifications section
 *
 * @return {ReactElement} The notifications section
 */
const ShowMoreButton = ({ appState, text, onClick }) => {
    const { accentColour, firstLoad } = appState;
    const appearTimeout = (firstLoad) ? 2000 : 1700;

    return (
        <div className={styles.notificationsActions}>
            <AnimationContainer
                animationStyle="animationContainerShowMore"
                appearTimeout={appearTimeout}
                enterTimeout={1000}
                exitTimeout={100}
            >
                <TertiaryButton
                    accent={accentColour}
                    onClick={onClick}
                    text={text}
                    aria-label={`${text} notifications`}
                />
            </AnimationContainer>
        </div>
    );
};

ShowMoreButton.propTypes = {
    appState: PropTypes.shape(appStateProp.getPropTypes()).isRequired,
    text: PropTypes.string,
    onClick: PropTypes.func,
};

ShowMoreButton.defaultProps = {
    text: "",
    onClick: _.noop,
};

export default attachStates([appStateProp], ShowMoreButton);
