import AppSetting from "./AppSetting";
import Benefits from "./Benefits";
import Branding from "./Branding";
import Documents from "./Documents";
import DrawingBoardDocuments from "./DrawingBoardDocuments";
import Forms from "./Forms";
import LegacyMessages from "./LegacyMessages";
import Messages from "./Messages";
import Notifications from "./Notifications";
import P45s from "./P45";
import P60s from "./P60s";
import Payroll from "./Payroll";
import Payslips from "./Payslips";
import Profile from "./Profile";
import Survey from "./Survey";

export default class Preloaders {

    /**
     * Prepare the preloaders
     *
     * @param {StateAccessor} appState The appState
     * @param {StateAccessor} userState The userState
     * @param {object} params Additional params
     */
    constructor (appState, userState, params) {
        this.appState = appState;
        this.userState = userState;

        this.preloaders = [
            new Profile(userState, params),
            new Survey(appState),
            new Payroll(appState),
            new Branding(appState),
            new Documents(appState),
            new P45s(appState),
            new P60s(appState),
            new Notifications(appState),
            new LegacyMessages(appState),
            new Payslips(appState),
            new Messages(appState),
            new Forms(appState),
            new AppSetting(appState),
            new Benefits(appState),
            new DrawingBoardDocuments(appState),
        ];
    }

    /**
     * Preload all data into the state
     *
     * @return {Promise} A promise that resolves when all preloading is done
     */
    loadAll () {
        let loaders = this.preloaders.map((preloader) => preloader.load());

        return Promise.all(loaders);
    }

}
