import React from "react";
import appState from "state/App";
import { Switch, withRouter } from "react-router-dom";
import PrivateRoute from "utils/PrivateRoute";

import { PageFrame } from "components/";
import {
    MessageList,
    MessageSingle,
    MessageCompose,
} from ".";

class Messages extends React.Component {

    /**
     * Renders the messages page
     *
     * @return {ReactElement} The page
     */
    render () {
        return (
            <PageFrame maxWidth={1200}>
                <Switch>
                    <PrivateRoute path="/messages/compose" component={MessageCompose} />
                    <PrivateRoute path="/messages/:message_id" component={MessageSingle} />
                    <PrivateRoute path="/messages" component={MessageList} />
                </Switch>
            </PageFrame>
        );
    }

}

export default withRouter(appState.attachState(Messages));
