/**
 * Determines if the survey has been seen on the current device
 *
 * @param {object} survey The survey settings
 *
 * @return {boolean} Survey not been seen
 */
const surveyNotBeenSeen = (survey) => (
    localStorage.getItem("recentSurvey") !== survey.url
    || localStorage.getItem("feedbackSeen") !== "true"
);

/**
 * Determines if ePayslips has an active survey
 *
 * @param {object} survey The current survey details
 *
 * @return {boolean} Has an active survey
 */
const hasActiveEpayslipsSurvey = (survey) => (
    survey?.active
    && window.ENV.PLATFORM === "epayslips"
);

export { surveyNotBeenSeen, hasActiveEpayslipsSurvey };
