import api from 'lib/api';
import moment from "moment";
import { isEmpty } from "lodash";

export default class Survey {

    /**
     * Survey constructor
     *
     * @param {StateAccessor} appState The userState
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the survey data
     *
     * @return {Promise} A promise that resolves with the survey data
     */
    load () {
        return api.get("/surveys/epayslips")
            .then(this.set);
    }

    /**
     * Handles the survey data loaded from the API
     *
     * @param {object} response The axios response
     *
     * @return {void}
     */
    set = ({ data }) => {
        if (!data.epayslips) {
            return;
        }

        const { start_date: startDate, end_date: endDate, url, message } = data.epayslips;
        const requiredValues = [startDate, endDate, url, message];
        const existingValues = [startDate, endDate, url, message].filter((value) => !isEmpty(value));

        if (requiredValues.length !== existingValues.length) {
            return;
        }

        const surveyStartDate = moment(startDate);
        const surveyEndDate = moment(endDate);

        this.appState.setSurvey({
            url,
            message,
            startDate: surveyStartDate,
            endDate: surveyEndDate,
            active: moment().isBetween(surveyStartDate, surveyEndDate),
        });
    };

}
