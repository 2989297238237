import React from 'react';
import PropTypes from "prop-types";
import appState from 'state/App';
import { withRouter } from "react-router-dom";
import { singleMessagePropTypes, singleMessageDefaultProps } from "lib/prop-schemas/messagesSchema";
import api from "lib/api";
import createErrorToasts from 'lib/createErrorToasts';

import {
    DefaultForm,
    TextAreaRow,
    PrimaryButton,
    SecondaryButton,
} from "@dataplan/react-components/dist/components/forms";

import styles from "./SingleMessageSharedStyles.module.scss";

class MessageReply extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        message: singleMessagePropTypes.message,
        toggleReplyState: singleMessagePropTypes.toggleReplyState,
        buttonStyles: singleMessagePropTypes.buttonStyles,
    };

    static defaultProps = {
        message: singleMessageDefaultProps.message,
        toggleReplyState: singleMessageDefaultProps.toggleReplyState,
        buttonStyles: singleMessageDefaultProps.buttonStyles,
    };

    /**
     * Creates an instance of the message reply page
     *
     * @param {object} props The component properties
     *
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.replyTextArea = React.createRef();

        this.state = {
            subject: '',
            reply: '',
            hasChanged: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.setReply();
    }

    /**
     * Sets the conent of the reply based on the original message on load
     *
     * @return {void}
     */
    setReply = () => {
        const { message } = this.props;
        let replyString = "\r\n\r\n";
        replyString += `------ From: ${message.from} ------\r\n\r\n`;
        replyString += message.message;

        this.setState({
            subject: message.subject,
            reply: replyString,
        }, () => {
            this.replyTextArea.current.focus();
        });
    };

    /**
     * Event handler for changes to the reply textarea
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handleReplyChange = (event) => {
        const reply = event.target.value;

        this.setState({
            reply,
            hasChanged: true,
        }, () => {
            appState.blockNavigation();
        });
    };

    /**
     * Event handler for the form submission to send the reply
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        event.preventDefault();
        appState.unblockNavigation();

        const { subject, reply, hasChanged } = this.state;

        if (!hasChanged) {
            return;
        }

        api.post('/legacy_message', {
            subject,
            message: reply,
        })
            .then(() => {
                appState.addNotification({
                    text: "Message sent successfully.",
                    type: "success",
                    duration: 5,
                });
                this.props.history.push('/messages');
            })
            .catch((apiError) => {
                createErrorToasts(apiError.response.data);
            });
    };

    /**
     * Renders the label/value pair for whom the message is from
     *
     * @param {?string} sender The name of the sender
     *
     * @return {?ReactElement} The sender name with label
     */
    renderMessageFrom = (sender) => {
        if (!sender) {
            return null;
        }

        return (
            <div className={styles.fromContainer}>
                <div className={styles.sectionTitle}>From</div>
                <div>{sender}</div>
            </div>
        );
    };

    /**
     * Renders the reply text area with label
     *
     * @param {object} message The message
     *
     * @return {?ReactElement} The reply text area with label
     */
    renderReplyTextArea = () => {
        const { reply } = this.state;

        if (!reply) {
            return null;
        }

        return (
            <div className={styles.messageContainer}>
                <div className={styles.sectionTitle}>Message</div>
                <TextAreaRow
                    name="replyTextArea"
                    value={reply}
                    onChange={this.handleReplyChange}
                    ref={this.replyTextArea}
                    inputClassName={styles.replyTextArea}
                />
            </div>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { message, toggleReplyState, buttonStyles } = this.props;
        const { hasChanged } = this.state;

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <div className={styles.messageContent}>
                    {this.renderMessageFrom(message.from)}
                    {this.renderReplyTextArea(message)}
                </div>

                <div className={styles.buttonContainer}>
                    <div className={styles.primaryButtonContainer}>
                        <PrimaryButton
                            style={(hasChanged) ? buttonStyles : null}
                            type="submit"
                            disabled={!hasChanged}
                            text="Send"
                        />
                    </div>
                    <div className={styles.secondaryButtonContainer}>
                        <SecondaryButton
                            onClick={toggleReplyState}
                            text="Cancel"
                        />
                    </div>
                </div>
            </DefaultForm>
        );
    }

}

export default withRouter(MessageReply);
