import _ from 'lodash';

/**
 * Loops through an object and remove the key/value pairs if the value
 * is equal to null
 * (Used in data table data)
 *
 * @param {object} map The object to filter
 *
 * @return {object} The filtered object
 */
export const filterObjectEmptyValues = (map) => {
    return _.reduce(map, (result, value, key) => {
        if (!_.isNull(value)) {
            result[key] = value;
        }

        return result;
    }, {});
};
