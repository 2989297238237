import React from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import appState from "state/App";
import userState from "state/User";
import { attachStates } from "utils/ReduxUtils";
import api from "lib/api";
import {
    createErrorToasts,
    contrastingColourList,
    refreshUserDetails,
} from "lib/";
import {
    getMostContrastingColour,
    checkValidity,
} from "@dataplan/react-components/dist/lib";

import {
    DefaultForm,
    TextInputRow,
    PrimaryButton,
} from '@dataplan/react-components/dist/components/forms';

import styles from './SetupSharedStyles.module.scss';
import DeviceTokenManager from "../../lib/DeviceTokenManager";

class AuthenticatorForm extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        disable: PropTypes.bool,
        submitAction: PropTypes.func,
    };

    static defaultProps = {
        disable: false,
        submitAction: _.noop,
    };

    /**
     * Creates an instance of the change username page
     *
     * @param {object} props Change username page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            authCode: '',
            error: {
                isValid: true,
                invalidInputs: [],
            },
        };
    }

    /**
     * Sets the component default state
     *
     * @return {void}
     */
    resetInitialState = () => {
        this.setState({
            authCode: '',
            error: {
                isValid: true,
                invalidInputs: [],
            },
        });
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     *
     * @return {void}
     */
    handleSetupCodeInput = (event) => {
        const authCode = event.target.value;

        this.setState({
            authCode,
        }, () => {
            this.handleValidation();
        });
    };

    /**
     * Checks the password field in completed and the setup code is valid
     *
     * @return {void}
     */
    handleValidation = () => {
        const { authCode } = this.state;
        const codeValidation = checkValidity(authCode, 'setupCode');

        this.setState({
            error: {
                isValid: (codeValidation.isValid),
                invalidInputs: [
                    !codeValidation.isValid ? 'authCode' : null,
                ].filter((input) => input !== null),
            },
        });
    };

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        event.preventDefault();

        const { authCode, error } = this.state;
        const postAddress = (this.props.disable) ? "disable" : "setup/verify";
        const errorMessage = (this.props.disable) ? "Deactivation" : "Setup";

        if (!error.isValid) {
            return;
        }

        api.post(`auth/mfa/totp/${postAddress}`, {
            "totp": authCode,
        }).then(() => {
            appState.addNotification({
                text: `Multi-Factor Authentication ${errorMessage} Completed`,
                type: "success",
                duration: 5,
            });

            this.props.submitAction();

            const deviceTokenHandler = new DeviceTokenManager();
            deviceTokenHandler.removeToken();

            refreshUserDetails().then(this.resetInitialState);
        }).catch((apiError) => {
            createErrorToasts(apiError.response.data);
        });
    };

    /**
     * Renders the input row for the setup code
     *
     * @return {ReactElement} the setup code row
     */
    renderCodeField () {
        const { disable } = this.props;
        const label = (disable) ? "Authorization" : "Setup";

        return (
            <TextInputRow
                name={`${label} Code`}
                label={`${label} Code`}
                required={true}
                placeholder="00-00-00"
                onChange={this.handleSetupCodeInput}
                inputClassName={styles.textInput}
            />
        );
    }

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { error } = this.state;
        const { accentColour } = this.props.appState;
        const canSubmit = (error.isValid);

        const label = (this.props.disable) ? "Disable" : "Setup";

        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                {this.renderCodeField()}
                <PrimaryButton
                    type="submit"
                    text={label}
                    disabled={!canSubmit}
                    style={(canSubmit) ? buttonStyles : null}
                    className={styles.button}
                />
            </DefaultForm>
        );
    }

}

export default attachStates([appState, userState], AuthenticatorForm);
