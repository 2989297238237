import React from 'react';
import PropTypes from 'prop-types';
import api from 'lib/api';
import appState from 'state/App';
import createErrorToasts from 'lib/createErrorToasts';
import {
    getMostContrastingColour,
    checkValidity,
    checkPasswordStrength,
} from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from "lib/";

import {
    DefaultForm,
    PasswordInputRow,
    PrimaryButton,
    ValidationCollection,
    ValidationIcon,
} from '@dataplan/react-components/dist/components/forms';

import styles from './ChangePassword.module.scss';
import sharedStyles from '../../../shared/SharedStyles.module.scss';

class ChangePassword extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
     * Creates an instance of the change password page
     *
     * @param {object} props Change password page properties
     */
    constructor (props) {
        super(props);

        this.state = {
            passwords: {
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            },
            hasChanged: false,
            error: {
                isValid: true,
                invalidInputs: [],
            },
            passwordStrength: {
                isStrong: false,
                isLong: false,
                lowercase: false,
                uppercase: false,
                number: false,
                symbol: false,
            },
        };
    }

    /**
     * Called just after the component has been added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleStrengthTest();
    }

    /**
     * Sets the component default state
     *
     * @return {void}
     */
    resetInitialState = () => {
        this.setState({
            passwords: {
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            },
            hasChanged: false,
            error: {
                isValid: true,
                invalidInputs: [],
            },
            passwordStrength: {
                isStrong: false,
                isLong: false,
                lowercase: false,
                uppercase: false,
                number: false,
                symbol: false,
            },
        });
    };

    /**
     * Called when the value of one of the inputs has changes
     *
     * @param {event} event The onChange event
     * @param {string} field Which password field has changed
     *
     * @return {void}
     */
    handlePasswordInput = (event, field) => {
        const password = event.target.value;

        this.setState((prevState) => {
            return {
                passwords: {
                    ...prevState.passwords,
                    [field]: password,
                },
                hasChanged: true,
            };
        }, () => {
            appState.blockNavigation();
            this.handleValidation();
        });
    };

    /**
     * Checks the password field has been completed and the new password match
     *
     * @return {void}
     */
    handleValidation = () => {
        const { passwords } = this.state;
        const passwordEntered = checkValidity(passwords.currentPassword, 'passwordEntered');
        const validateNewPassword = checkValidity([passwords.newPassword, passwords.newPasswordConfirm], 'newPassword');

        this.setState({
            error: {
                isValid: (passwordEntered.isValid && validateNewPassword.isValid),
                invalidInputs: [
                    !passwordEntered.isValid ? 'currentPassword' : null,
                    !validateNewPassword.isValid ? 'newPassword' : null,
                    !validateNewPassword.isValid ? 'newPasswordConfirm' : null,
                ].filter((input) => input !== null),
            },
        }, () => {
            this.handleStrengthTest();
        });
    };

    /**
     * Checks the new passwords strength
     *
     * @return {void}
     */
    handleStrengthTest = () => {
        const { passwords } = this.state;
        const passwordStrength = checkPasswordStrength(passwords.newPassword);

        this.setState({
            passwordStrength,
        });
    };

    /**
     * Handles the saving of changes once validated
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        appState.unblockNavigation();
        event.preventDefault();

        const { passwords, hasChanged, error, passwordStrength } = this.state;

        if (!hasChanged || !error.isValid || !passwordStrength.isStrong) {
            return;
        }

        api.patch('/security/password', {
            "old_password": passwords.currentPassword,
            "password": passwords.newPassword,
            "password_confirm": passwords.newPasswordConfirm,
        }).then(() => {
            appState.addNotification({
                text: "Password successfully updated.",
                type: "success",
                duration: 5,
            });
            this.resetInitialState();
        }).catch((apiError) => {
            createErrorToasts(apiError.response.data);
        });
    };

    /**
     * Renders a password input field
     *
     * @param {string} field Which password field to render
     * @param {string} display The textual label to display
     * @param {string} autoCompleteValue The autoComplete label
     * @param {?string} errorMessage The error message to display
     * @param {bool} showPasswordToggle Should we display the view password toggle
     *
     * @return {ReactElement} The password input component
     */
    renderPasswordField = (field, display, autoCompleteValue, errorMessage = null, showPasswordToggle = true) => {
        const { invalidInputs } = this.state.error;
        const hasError = (invalidInputs.indexOf(field) >= 0);
        const errorText = (hasError && errorMessage)
            ? errorMessage
            : null;

        return (
            <PasswordInputRow
                name={field}
                label={display}
                autoComplete={autoCompleteValue}
                onChange={(event) => this.handlePasswordInput(event, field)}
                value={this.state.passwords[field]}
                inputClassName={(hasError) ? sharedStyles.error : ''}
                showPasswordToggle={showPasswordToggle}
                errorText={errorText}
            />
        );
    };

    /**
     * Renders the visual display for the password strength checker
     *
     * @return {ReactElement} The password strength checker visual
     */
    renderPasswordStrengthCheck = () => {
        const { passwordStrength } = this.state;

        return (
            <ValidationCollection
                label="Password must be 8 or more characters, and contain all of the following:"
                className={styles.validationCollection}
            >
                <ValidationIcon label="8 or more characters long" isValid={passwordStrength.isLong} />
                <ValidationIcon label="Lowercase letter" isValid={passwordStrength.lowercase} />
                <ValidationIcon label="Uppercase letter" isValid={passwordStrength.uppercase} />
                <ValidationIcon label="Number" isValid={passwordStrength.number} />
                <ValidationIcon label="Symbol" isValid={passwordStrength.symbol} />
            </ValidationCollection>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        const { hasChanged, error, passwordStrength } = this.state;
        const { accentColour } = this.props.appState;
        const canSubmit = (hasChanged && error.isValid && passwordStrength.isStrong);
        const buttonStyles = {
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        return (
            <DefaultForm onSubmit={this.handleSubmit}>
                <input hidden={true} autoComplete="username" />
                <div>
                    {this.renderPasswordField(
                        "currentPassword",
                        "Current password",
                        "current-password",
                        "Password is required to make changes to your account",
                    )}
                    {this.renderPasswordField(
                        "newPassword",
                        "New password",
                        "new-password",
                    )}
                    {this.renderPasswordStrengthCheck()}
                    {this.renderPasswordField(
                        "newPasswordConfirm",
                        "Confirm new password",
                        "new-password",
                        "Passwords must match",
                        false,
                    )}
                </div>
                <PrimaryButton
                    type="submit"
                    text="save"
                    disabled={!canSubmit}
                    style={(canSubmit) ? buttonStyles : null}
                    className={sharedStyles.button}
                />
            </DefaultForm>
        );
    }

}

export default appState.attachState(ChangePassword);
