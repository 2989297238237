import PropTypes from "prop-types";

const navBlockPropTypes = {
    confirmedNavigation: PropTypes.bool,
    nextLocation: PropTypes.string,
    showSnackBar: PropTypes.bool,
};

const navBlockDefaultProps = {
    confirmedNavigation: false,
    nextLocation: null,
    showSnackBar: false,
};

export {navBlockDefaultProps, navBlockPropTypes};
