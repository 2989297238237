/**
 *
 * @param {array} states An array of states to attach to a given component
 * @param {Component} component The component to attach the states to
 *
 * @return {function} Returns the connected component states
 */
const attachStates = (states, component) => {
    let attachComponent = component;

    states.forEach((state) => {
        attachComponent = state.attachState(attachComponent);
    });

    return attachComponent;
};

export { attachStates };
