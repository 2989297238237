import React from 'react';
import QRCode from 'react-qr-code';
import AndroidBadge from "./assets/icon/AndroidBadge.png";
import AppleBadge from "./assets/icon/AppleBadge.svg";

import styles from './MobileAppCard.module.scss';

/**
 * Renders the mobile app card
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const MobileAppCard = () => {
    return (
        <>
            <div className={styles.header}>
                ePayslips Mobile App
            </div>
            <div className={styles.body}>
                <div className={styles.bodyText}>
                    Did you know, you can access your ePayslips account on your mobile device?
                    Simply search for ePayslips in your devices app store, or use the QR Codes below.
                </div>
                <div className={styles.badgesContainer}>
                    <div className={styles.badgeQRContainer}>
                        <QRCode
                            value="https://play.google.com/store/apps/details?id=uk.co.epayslips.mobile&hl=en&gl=US"
                            className={styles.qrCode}
                        />
                        <a
                            href="https://play.google.com/store/apps/details?id=uk.co.epayslips.mobile&hl=en&gl=US"
                        >
                            <img
                                className={styles.androidBadge}
                                src={AndroidBadge}
                                alt="Get it on Google Play"
                            />
                        </a>
                    </div>
                    <div className={styles.badgeQRContainer}>
                        <QRCode
                            value="https://apps.apple.com/gb/app/epayslips-mobile/id1539137458"
                            className={styles.qrCode}
                        />
                        <a
                            href="https://apps.apple.com/gb/app/epayslips-mobile/id1539137458"
                        >
                            <img
                                className={styles.appleBadge}
                                src={AppleBadge}
                                alt="Download it on the app store"
                            />
                        </a>
                    </div>
                </div>

            </div>
        </>
    );
};

export default MobileAppCard;
