import PropTypes from 'prop-types';

const defaultP45Types = {
    id: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    datetime_added: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    type: PropTypes.string.isRequired,
};

const p45PropTypes = PropTypes.arrayOf(PropTypes.shape(defaultP45Types));
const p45DefaultProps = [];

export { p45PropTypes, p45DefaultProps, defaultP45Types };
