import React from "react";
import appState from "state/App";
import PropTypes from 'prop-types';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { getAppName } from "lib/";

import {
    DocumentsFrame,
    PageFrame,
    PageHeader,
    PdfDrawer,
} from "components/";

class P60s extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
    };

    /**
    * Creates an instance of the component
    *
    * @param {object} props Component properties
    */
    constructor (props) {
        super(props);

        this.pageName = "P60s";
        this.nativeDrawer = React.createRef();

        this.state = {
            viewedP60: null,
            showPreview: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageName(this.pageName);
        document.title = this.pageName;
    }

    /**
     * Called when the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        document.title = getAppName();
    }

    /**
     * Toggle drawer visibility
     *
     * @return {void} Changes state of drawer
     */
    toggleDrawer = () => {
        this.setState((prevState) => {
            return {
                showPreview: !prevState.showPreview,
            };
        });
    };

    /**
     * Set P60 in state
     *
     * @param {object} viewedP60 The P60 to set in state
     *
     * @return {void} Changes state of page
     */
    viewP60 = (viewedP60) => {
        this.setState({
            viewedP60,
        }, () => {
            // Set timeout so that drawer click outside occurs first
            window.setTimeout(this.toggleDrawer, 0);
        });
    };

    /**
     * Renders the P60s page
     *
     * @return {ReactElement} The page
     */
    render () {
        const { accentColour, documents: { eP60 } } = this.props.appState;
        const { viewedP60 } = this.state;

        const P60PrintUrl = (viewedP60)
            ? `${viewedP60.url}&disposition=inline&include_background=true`
            : null;

        const P60Url = (viewedP60) ? `${viewedP60.url}&include_background=true` : null;
        const headerTitle = (viewedP60) ? viewedP60.description : "eP60 document";

        return (
            <PageFrame>
                <PdfDrawer
                    accentColour={accentColour}
                    enablePrint={true}
                    pageName={headerTitle}
                    forwardedRef={this.nativeDrawer}
                    url={P60Url}
                    printUrl={P60PrintUrl}
                    visible={this.state.showPreview}
                />
                <AnimationContainer
                    animationStyle="animationContainer"
                    appearTimeout={200}
                    enterTimeout={1000}
                    exitTimeout={100}
                >
                    <PageHeader title={this.pageName} />
                </AnimationContainer>
                <DocumentsFrame
                    animationDelay={200}
                    documents={eP60}
                    onClick={this.viewP60}
                />
            </PageFrame>
        );
    }

}

export default appState.attachState(P60s);
