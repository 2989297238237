import React from 'react';
import PropTypes from 'prop-types';

import { history } from "../../../redux-store";
import { doFullLogout } from 'lib/clearSessionData';

import { ErrorPageButton } from '.';

class LogoutButton extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: null,
    };

    /**
     * Handles the button onClick event
     */
    handleClick = () => {
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }

        // do logout
        doFullLogout();

        history.replace('/');
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <ErrorPageButton onClick={this.handleClick}>
                Logout
            </ErrorPageButton>
        );
    }

}

export default LogoutButton;
