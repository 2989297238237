import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import _ from "lodash";
import { ArrowDropDownIcon } from "@dataplan/react-components/dist/components/icons";
import styles from "./Accordion.module.scss";

/**
 * Renders the accordion header
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The accordion header
 */
const AccordionHeader = ({
    children,
    className,
    enableArrowIcon,
    enableTabbing,
    height,
    isOpen,
    onClick,
    title,
}) => {
    const buttonStyles = classNames(styles.header, className);
    const tabIndex = (enableTabbing) ? "0" : "-1";

    const dropDownStyles = classNames(styles.icon, {
        [styles.open]: isOpen,
    });

    return (
        <button
            aria-expanded={isOpen}
            aria-label={title}
            className={buttonStyles}
            onClick={onClick}
            style={{ height }}
            tabIndex={tabIndex}
            type="button"
        >
            {children}
            {enableArrowIcon && <ArrowDropDownIcon className={dropDownStyles} />}
        </button>
    );
};

AccordionHeader.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    enableArrowIcon: PropTypes.bool,
    enableTabbing: PropTypes.bool,
    height: PropTypes.string,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
};

AccordionHeader.defaultProps = {
    children: null,
    className: null,
    enableArrowIcon: true,
    enableTabbing: true,
    height: '75px',
    isOpen: false,
    onClick: _.noop,
    title: null,
};

export default AccordionHeader;
