import React from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "@dataplan/react-components/dist/components/ui/loading_spinner";
import { payslipPropTypes } from 'lib/prop-schemas/payslipsSchema';
import { PayslipAccordionHeader } from '../';
import PayslipElementList from './PayslipElementList.js';
import { AccordionList } from "components/";

import styles from "../PayslipSharedStyles.module.scss";

/**
 * Renders the payslip body or loading spinner
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The body
 */
const PayslipBody = ({ detailsOpen, payslip, payslipLoaded, previousPayslip, ...props }) => {
    const { additions, deductions, gross, total_deductions: totalDeductions } = payslip;

    if (!payslipLoaded) {
        return (
            <div className={styles.loadingSpinner}>
                <LoadingSpinner label="Loading Payslip" />
            </div>
        );
    }

    /**
     * Gets the accordion config
     *
     * @param {string} title Title for the accordion
     * @param {number} total Total value
     * @param {object} elements Key-value list of payslip elements
     * @param {string} key The label for the previous payslips object
     *
     * @return {object} The list or null if the details are hidden
     */
    const getPrimaryAccordionConfig = (title, total, elements, key) => ({
        title,
        headerContent: (
            <PayslipAccordionHeader
                detailsOpen={detailsOpen}
                title={title}
                total={total}
            />
        ),
        bodyContent: (
            <PayslipElementList
                previousPayslip={previousPayslip ? previousPayslip[key] : null}
                animationStart={detailsOpen}
                element={elements}
                title={title}
            />
        ),
    });

    return (
        <>
            <AccordionList
                className={styles.accordionList}
                headerHeight="75px"
                list={[
                    getPrimaryAccordionConfig("Gross", gross, additions, "additions"),
                    getPrimaryAccordionConfig("Deductions", totalDeductions, deductions, "deductions"),
                ]}
                {...props}
            />
            <PayslipAccordionHeader
                className={styles.net}
                title="Net Pay"
                total={payslip.net}
            />
        </>
    );
};

PayslipBody.propTypes = {
    canNavigate: PropTypes.object,
    detailsOpen: PropTypes.string,
    navigatePayslips: PropTypes.func,
    payslip: PropTypes.shape(payslipPropTypes).isRequired,
    payslipLoaded: PropTypes.bool,
    previousPayslip: PropTypes.object,
};

PayslipBody.defaultProps = {
    canNavigate: { left: false, right: false },
    detailsOpen: null,
    navigatePayslips: null,
    payslipLoaded: false,
    previousPayslip: null,
};

export default PayslipBody;
