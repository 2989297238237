import React from 'react';
import PropTypes from 'prop-types';
import { AccordionList } from 'components/';

import styles from './AccordionSection.module.scss';

/**
 * Returns an accordion list with a header if provided
 * @param {object} props component props
 * @param {boolean} arrowIcon true if arrow is to be shown
 * @param {string} heading header text
 * @param {array} accordionList the accordion list config
 *
 * @returns {React.Component} Accordion Section Component
 */
const AccordionSection = React.memo(({ arrowIcon, heading, accordionList }) => {
    /**
     * Retruns a h2 tag with the prop text
     *
     * @returns {React.Element} Header tag
     */
    const renderHeader = () => (<h2 className={styles.title}>{heading}</h2>);

    return (
        <>
            {heading && renderHeader()}
            <AccordionList
                arrowIcon={arrowIcon}
                className={styles.accordionList}
                headerHeight="55px"
                list={accordionList}
            />
        </>
    );
});

AccordionSection.propTypes = {
    accordionList: PropTypes.array,
    arrowIcon: PropTypes.bool,
    heading: PropTypes.string,
};

AccordionSection.defaultProps = {
    accordionList: [],
    arrowIcon: true,
    heading: "",
};

export default AccordionSection;
