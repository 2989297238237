import React from 'react';
import { useSelector } from "react-redux";
import { history } from "../../redux-store";
import FeedbackCardHeader from "./FeedbackCardHeader.js";
import { AnimationContainer } from "@dataplan/react-components/dist/components";
import { PrimaryButton, SecondaryButton } from '@dataplan/react-components/dist/components/forms/controls';
import { FeedbackIcon } from "@dataplan/react-components/dist/components/icons";
import { contrastingColourList, getMostContrastingColour } from '@dataplan/react-components/dist/lib';

import styles from './FeedbackCard.module.scss';

/**
 * Renders the intro card component
 *
 * @return {ReactElement} The component
 */
const FeedbackCard = () => {
    const { brandColour, survey } = useSelector(({ appState }) => appState);

    const buttonStyles = {
        backgroundColor: brandColour,
        color: getMostContrastingColour(brandColour, contrastingColourList),
    };

    const feedbackSeen = () => {
        localStorage.setItem("recentSurvey", survey.url);
        localStorage.setItem("feedbackSeen", true);
        history.replace('/');
    };

    const giveFeedback = () => {
        window.open(survey.url);
        localStorage.setItem("recentSurvey", survey.url);
        localStorage.setItem("feedbackSeen", true);
        history.replace('/');
    };

    return (
        <div className={styles.mask}>
            <AnimationContainer
                appearTimeout={200}
                className={styles.container}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <div className={styles.card}>
                    <FeedbackCardHeader />
                    <div className={styles.body}>
                        <div className={styles.bodyText}>
                            <span className={styles.text}>
                                {survey.message}
                            </span>
                            <div className={styles.icon}>
                                <FeedbackIcon fill={'#929292'} width={120} height={120} />
                            </div>
                        </div>
                        <div className={styles.bottomButton}>
                            <SecondaryButton
                                className={styles.buttons}
                                aria-label="No thanks"
                                onClick={feedbackSeen}
                                text="No thanks"
                            />
                            <PrimaryButton
                                style={buttonStyles}
                                className={styles.buttons}
                                aria-label="Give feedback"
                                onClick={giveFeedback}
                                text="Give feedback"
                            />
                        </div>
                    </div>
                </div>
            </AnimationContainer>
        </div>
    );
};

export default FeedbackCard;
