import React from "react";
import { useSelector } from "react-redux";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { AnimateTextUnderline } from "components/";
import { contrastingColourList } from 'lib/';

import styles from "./PayslipCover.module.scss";

/**
 * Renders the payslip cover body
 *
 * @return {ReactElement} The payslip cover body
 */
const PayslipCoverBody = () => {
    const { brandColour } = useSelector(({ appState }) => appState);

    const underlineClasses = {
        underlineFill: styles.underlineFill,
    };

    return (
        <div
            className={styles.payslipCover}
            style={{
                backgroundColor: brandColour,
                color: getMostContrastingColour(brandColour, contrastingColourList),
            }}
        >
            <div aria-hidden="true" className={styles.payslipAlert}>Your latest payslip is ready</div>
            <div className={styles.viewPayslip}>
                <AnimateTextUnderline classList={underlineClasses}>
                    <button
                        type="button"
                        tabIndex="0"
                        className={styles.viewPayButton}
                    >
                        view pay
                    </button>
                </AnimateTextUnderline>
            </div>
        </div>
    );
};

export default PayslipCoverBody;
