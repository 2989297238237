import api from "lib/api";

export default class Benefits {

    /**
     * @param {StateAccessor} appState The global app state
     */
    constructor (appState) {
        this.appState = appState;
    }

    /**
     * Handles loading of the benefits
     *
     * @return {Promise} A promise that resolves with the data
     */
    load () {
        return api.get("/benefits/external").then((response) => {
            this.appState.setBenefits(response.data);
        });
    }

}
