import React from 'react';
import PropTypes from 'prop-types';
import appState from 'state/App';
import { withRouter } from "react-router-dom";
import api from "lib/api";
import createErrorToasts from 'lib/createErrorToasts';
import classNames from 'classnames';

import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';
import { PageLayout } from "@dataplan/react-components/dist/components/ui/page_layout";
import {
    DefaultForm,
    TextInputRow,
    TextAreaRow,
    PrimaryButton,
    SecondaryButton,
} from '@dataplan/react-components/dist/components/forms';
import { AnimationContainer } from "@dataplan/react-components/dist/components/ui/animation";
import { PageHeader } from "components/";

import styles from './MessageCompose.module.scss';

class MessageCompose extends React.Component {

    static propTypes = {
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    };

    /**
     * Creates an instance of the message compose page
     *
     * @param {object} props The component properties
     *
     * @return {void}
     */
    constructor (props) {
        super(props);

        this.subjectTextInput = React.createRef();

        this.state = {
            subject: '',
            message: '',
            hasChanged: false,
            error: {
                isValid: true,
                invalidInputs: {},
            },
            submitting: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        appState.setPageDetails("Compose New Message", [
            {
                "text": "Home",
                "url": "/",
            },
            {
                "text": "Messages",
                "url": "/messages",
            },
            {
                "text": "Compose",
                "url": null,
            },
        ]);

        this.subjectTextInput.current.focus();
    }

    /**
     * Event handler for changes to the form fields
     *
     * @param {event} event The onChange event
     * @param {string} field Which field we are changing
     *
     * @return {void}
     */
    handleInputChange = (event, field) => {
        const value = event.target.value;

        this.setState({
            [field]: value,
            hasChanged: true,
        }, () => {
            this.checkValidity();
            appState.blockNavigation();
        });
    };

    /**
     * Checks the validity of the message/subject
     *
     * @return {void}
     */
    checkValidity = () => {
        this.setState((prevState) => {
            const subjectValid = (prevState.subject.length > 0);
            const messageValid = (prevState.message.length > 0);

            return {
                error: {
                    isValid: (subjectValid && messageValid),
                    invalidInputs: {
                        subject: (!subjectValid) ? "Subject is required" : null,
                        message: (!messageValid) ? "Message is required" : null,
                    },
                },
            };
        });
    };

    /**
     * Event handler for the form submission to send the message
     *
     * @param {event} event The form onSubmit event
     *
     * @return {void}
     */
    handleSubmit = (event) => {
        event.preventDefault();
        appState.unblockNavigation();

        const { subject, message, hasChanged, error } = this.state;

        if (!hasChanged || !error.isValid) {
            return;
        }

        const submitMessage = () => {
            api.post('/legacy_message', {
                subject,
                message,
            })
                .then(() => {
                    appState.addNotification({
                        text: "Message sent successfully.",
                        type: "success",
                        duration: 5,
                    });

                    this.props.history.push('/messages');
                })
                .catch((apiError) => {
                    createErrorToasts(apiError.response.data);
                });
        };

        this.setState({
            submitting: true,
        }, submitMessage);
    };

    /**
     * Renders the subject line input
     *
     * @return {ReactElement} The subject input
     */
    renderSubjectInput = () => {
        const { subject, error } = this.state;
        const classList = classNames({
            [styles.error]: (error.invalidInputs.subject),
        });

        return (
            <TextInputRow
                name="subject"
                label="Subject"
                type="text"
                onChange={(event) => this.handleInputChange(event, 'subject')}
                value={subject}
                ref={this.subjectTextInput}
                className={classList}
                errorText={error.invalidInputs.subject}
            />
        );
    };

    /**
     * Renders the message text area
     *
     * @return {ReactElement} The message text area
     */
    renderMessageArea = () => {
        const { message, error } = this.state;
        const classList = classNames(styles.textArea, {
            [styles.error]: (error.invalidInputs.message),
        });

        return (
            <TextAreaRow
                name="message"
                label="Message"
                onChange={(event) => this.handleInputChange(event, 'message')}
                value={message}
                inputClassName={classList}
                errorText={error.invalidInputs.message}
            />
        );
    };

    /**
     * Renders the submit and cancel buttons
     *
     * @return {ReactElement} The buttons
     */
    renderButtons = () => {
        const { hasChanged, error, submitting } = this.state;
        const canSubmit = (hasChanged && error.isValid);
        const { accentColour } = this.props.appState;
        const buttonStyles = {
            primary: {
                backgroundColor: accentColour,
                color: getMostContrastingColour(accentColour, contrastingColourList),
            },
        };

        return (
            <div className={styles.buttonContainer}>
                <div className={styles.primaryButtonContainer}>
                    <PrimaryButton
                        type="submit"
                        style={(canSubmit && !submitting) ? buttonStyles.primary : null}
                        disabled={!canSubmit || submitting}
                        text="Send"
                    />
                </div>
                <div className={styles.secondaryButtonContainer}>
                    <SecondaryButton
                        onClick={() => this.props.history.push('/messages')}
                        text="Cancel"
                    />
                </div>
            </div>
        );
    };

    /**
     * Renders the component
     *
     * @return {ReactElement} The component
     */
    render () {
        return (
            <AnimationContainer
                appearTimeout={200}
                enterTimeout={1000}
                exitTimeout={100}
                animationStyle={"animationContainer"}
            >
                <PageLayout message={true}>
                    <PageHeader title="New Message" inBoxedLayout />
                    <DefaultForm onSubmit={this.handleSubmit}>
                        {this.renderSubjectInput()}
                        {this.renderMessageArea()}
                        {this.renderButtons()}
                    </DefaultForm>
                </PageLayout>
            </AnimationContainer>
        );
    }

}

export default withRouter(appState.attachState(MessageCompose));
