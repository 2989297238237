import React from "react";
import PropTypes from "prop-types";
import appState from "state/App";
import { withRouter } from "react-router-dom";
import classNames from 'classnames';
import _ from "lodash";
import { attachStates } from "utils/ReduxUtils";

import Document from "lib/downloaders/Document";
import { DocumentCard } from "@dataplan/react-components/dist/components/ui/cards/document_card";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';

import styles from "./LatestDocument.module.scss";

class LatestDocument extends React.Component {

    static propTypes = {
        newLabelNotice: PropTypes.string,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        headerClass: PropTypes.string,
        documentClass: PropTypes.string,
        actionsClass: PropTypes.string,
    };

    static defaultProps = {
        newLabelNotice: 'new',
        headerClass: '',
        documentClass: '',
        actionsClass: '',
    };

    /**
     * Helper function to get the latest document. Will return undefined if
     * there are no documents
     *
     * @param {array} documents An array of documents
     * @return {object|undefined} The latest document
     */
    getLatestDocument (documents) {
        return _.chain(documents)
            .reduce((result, value) => {
                return [...result, ...value];
            }, [])
            .maxBy((document) => document.id)
            .value();
    }

    /**
     * Helper function to determine the label details for the document
     * card
     *
     * @param {Document} documentHandler The document handler class
     * @return {object} An object of the primary and secondary label
     */
    getLabels (documentHandler) {
        const { newLabelNotice } = this.props;
        const { accentColour } = this.props.appState;

        let primaryLabel = {
            text: documentHandler.getDateTime,
        };
        let secondaryLabel = {
            text: newLabelNotice,
            backgroundColor: accentColour,
            color: getMostContrastingColour(accentColour, contrastingColourList),
        };

        return {
            primaryLabel,
            secondaryLabel,
        };
    }

    /**
     * Helper function to get and render the latest document
     *
     * @return {ReactElement} The document card to render
     */
    renderLatestDoc () {
        const { accentColour, documents } = this.props.appState;
        const latestDocument = this.getLatestDocument(documents);

        if (!latestDocument) {
            return null;
        }

        let { description, filename } = latestDocument;
        let documentHandler = new Document(latestDocument);

        const { primaryLabel } = this.getLabels(documentHandler);

        let actions = [
            {
                action: () => documentHandler.download(),
                label: 'View',
                backgroundColor: accentColour,
            },
        ];

        return (
            <DocumentCard
                key={latestDocument.id}
                primaryLabel={primaryLabel}
                actions={actions}
                actionClasses={styles.cardActions}
                headerTitle={description}
                subHeading={filename}
            />
        );
    }

    /**
     * Renders the latest document card/details
     *
     * @return {ReactElement} The latest document
     */
    render () {
        const documentClasses = classNames(styles.latestDoc, this.props.documentClass);

        return (
            <>
                <h2 className={this.props.headerClass}>
                    Latest Doc
                </h2>
                <div className={documentClasses}>
                    {this.renderLatestDoc()}
                </div>
                <div className={this.props.actionsClass} />
            </>
        );
    }

}

export default withRouter(attachStates([appState], LatestDocument));
