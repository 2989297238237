import React from 'react';

/**
 * Renders the underscore icon
 *
 * @param {object} props The component props
 *
 * @return {ReactElement} The component
 */
const UnderscoreIcon = (props) => (
    <svg viewBox="0 0 48 48" {...props}>
        <path
            // eslint-disable-next-line max-len
            d="M13.45 41.5q-.6 0-1.025-.425Q12 40.65 12 40q0-.65.425-1.075.425-.425 1.075-.425h21.1q.6 0 1.025.425.425.425.425 1.075 0 .65-.425 1.075-.425.425-1.075.425Z"
        />
    </svg>
);

export default UnderscoreIcon;
