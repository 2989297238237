import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getMostContrastingColour } from "@dataplan/react-components/dist/lib";
import { contrastingColourList } from 'lib/';

import styles from "./NewCount.module.scss";

const newCounts = {
    payslips: ({ payslips }) => (
        payslips.filter((payslip) => !payslip.marked_as_read).length
    ),
    messages: ({ messages }) => (
        messages.filter((message) => message.recipient === "employee" && !message.opened).length
    ),
    notifications: ({ announcements }) => (
        announcements.filter((notification) => !notification.notice_read).length
    ),
};

/**
 * Renders the new count icon
 *
 * @param {object} props The component props
 *
 * @return {React.Component} The new count icon
 */
const NewCount = ({ className, type, ...props }) => {
    const appState = useSelector(({ appState: state }) => state);
    const { accentColour: backgroundColor } = appState;
    const newCount = newCounts[type]?.(appState);

    if (!newCount) {
        return null;
    }

    const containerStyles = classNames(styles.newCount, className);

    const newCountStyles = {
        backgroundColor,
        color: getMostContrastingColour(backgroundColor, contrastingColourList),
    };

    return (
        <div
            className={containerStyles}
            style={newCountStyles}
            {...props}
        >
            {newCount}
        </div>
    );
};

NewCount.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
};

NewCount.defaultProps = {
    className: null,
};

export default NewCount;
